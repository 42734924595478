import React, { useState, useEffect } from 'react';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, CheckCircleIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid';

function JourneyItemDrawer({ isOpen, onClose, item, type, onItemUpdate, onItemDelete }) {
  const [currentItem, setCurrentItem] = useState(null);
  
  useEffect(() => {
    if (item) {
      setCurrentItem({ ...item });
    }
  }, [item]);

  if (!isOpen || !currentItem) return null;
  
  // Helper function to parse the content from JSON if needed
  const getReadableContent = (content) => {
    if (typeof content === 'string' && (content.startsWith('{') || content.startsWith('['))) {
      try {
        const parsed = JSON.parse(content);
        if (parsed.blocks) {
          return parsed.blocks
            .map(block => {
              if (block.type === 'paragraph') return block.data.text;
              if (block.type === 'header') return block.data.text;
              if (block.type === 'list') return block.data.items.join(', ');
              return '';
            })
            .filter(Boolean)
            .join(' ');
        }
        return JSON.stringify(parsed);
      } catch (e) {
        return content;
      }
    }
    return content;
  };

  // Get display content based on item type
  const getDisplayContent = () => {
    if (type === 'painpoint' && currentItem.single_painpoint) {
      return getReadableContent(currentItem.single_painpoint);
    } else if (type === 'gain' && currentItem.single_gain) {
      return getReadableContent(currentItem.single_gain);
    } else if (type === 'job_to_be_done' && currentItem.single_job_to_be_done) {
      return getReadableContent(currentItem.single_job_to_be_done);
    } else if (currentItem.insight_data?.description) {
      return getReadableContent(currentItem.insight_data.description);
    }
    return 'No description available';
  };

  // Get the type styles and icon
  const getTypeInfo = () => {
    switch(type) {
      case 'painpoint':
        return {
          label: 'Pain Point',
          badge: 'bg-red-50 text-red-700',
          icon: <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
        };
      case 'gain':
        return {
          label: 'Gain',
          badge: 'bg-green-50 text-green-700',
          icon: <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
        };
      case 'job_to_be_done':
        return {
          label: 'Job To Be Done',
          badge: 'bg-purple-50 text-purple-700',
          icon: <ChatBubbleLeftRightIcon className="h-5 w-5 text-purple-500 mr-2" />
        };
      default:
        return {
          label: 'Item',
          badge: 'bg-gray-50 text-gray-700',
          icon: null
        };
    }
  };

  const typeInfo = getTypeInfo();
  const content = getDisplayContent();

  return (
    <>
      <div 
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50"
        onClick={onClose}
      />
      
      <div className="fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform transition-transform ease-in-out duration-300 z-[51] flex flex-col">
        {/* Header */}
        <div className="px-4 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-medium truncate">{content?.substring(0, 25)}{content?.length > 25 ? '...' : ''}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        
        {/* Content */}
        <div className="flex-1 overflow-auto px-4 pb-4">
          <div className="mt-4">
            <p className="text-sm text-gray-500 mb-2">Description</p>
            <p className="text-sm">{content}</p>
          </div>
          
          {/* Details Section */}
          <section className="w-full my-6">
            <div>
              <div className="space-y-3 mb-4">
                {/* Type */}
                <div className="flex items-start">
                  <div className="flex items-center mr-3">
                    <svg className="h-4 w-4 mr-2.5 ml-0.5 text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="3" y="3" width="7" height="7" />
                      <rect x="14" y="3" width="7" height="7" />
                      <rect x="14" y="14" width="7" height="7" />
                      <rect x="3" y="14" width="7" height="7" />
                    </svg>
                    <span className="text-sm">Type</span>
                  </div>
                  <div className="flex items-center">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium ${typeInfo.badge}`}>
                      {typeInfo.label}
                    </span>
                  </div>
                </div>
                
                {/* Priority if available */}
                {currentItem.piority && (
                  <div className="flex items-start">
                    <div className="flex items-center mr-3">
                      <svg className="h-4 w-4 mr-2.5 ml-0.5 text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 19l9 2-9-18-9 18 9-2z" />
                      </svg>
                      <span className="text-sm">Priority</span>
                    </div>
                    <div className="flex items-center">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium 
                        ${currentItem.piority === 'high' ? 'bg-red-50 text-red-700' : 
                        currentItem.piority === 'medium' ? 'bg-yellow-50 text-yellow-700' : 
                        'bg-green-50 text-green-700'}`}
                      >
                        {currentItem.piority.charAt(0).toUpperCase() + currentItem.piority.slice(1)}
                      </span>
                    </div>
                  </div>
                )}
                
                {/* Add Property Button */}
                <button className="flex items-center text-sm text-gray-600 hover:text-gray-900 mt-3">
                  <PlusIcon className="h-3 w-3 mr-1" />
                  Add property
                </button>
              </div>
              
              <p className="text-xs text-gray-500 mt-6">
                Created from Insight
              </p>
            </div>
          </section>
        </div>
        
        {/* Tab Navigation */}
        <div className="border-t border-gray-200 px-4 py-3">
          <div className="flex space-x-2 overflow-x-auto pb-2">
            <button className="px-3 py-1.5 text-sm font-medium border-b-2 border-primary-500 text-primary-700">
              Details
            </button>
            <button className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900">
              Evidence <span className="text-gray-400">(0)</span>
            </button>
            <button className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900">
              Comments <span className="text-gray-400">(0)</span>
            </button>
          </div>
        </div>
        
        {/* Footer with Actions */}
        <div className="border-t border-gray-200 px-4 py-3 flex justify-between">
          <button
            onClick={() => onItemDelete && onItemDelete()}
            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Remove
          </button>
          
          <button
            onClick={() => onItemUpdate && onItemUpdate(currentItem)}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default JourneyItemDrawer;