import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT, DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT, DIRECTUS_INSTANCE } from '../../api';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';

function CreateUserJourney() {
  const navigate = useNavigate();
  const [journeyName, setJourneyName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const userID = localStorage.getItem('user_id');
  const token = localStorage.getItem('directus_token');
  const [personas, setPersonas] = useState([]);
  const [selectedPersonaIds, setSelectedPersonaIds] = useState([]);
  const [activeTab, setActiveTab] = useState('personas');
  const [userStory, setUserStory] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userID }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setError('Failed to fetch projects');
      }
    };

    fetchProjects();
  }, [userID, token]);

  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await axios.get(DIRECTUS_PERSONAS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userID },
            fields: ['id', 'name', 'avatar.id', 'type', 'occupation']
          }
        });
        setPersonas(response.data.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
        setError('Failed to fetch personas');
      }
    };

    fetchPersonas();
  }, [userID, token]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects/create-project');
    } else {
      setSelectedProjectId(value);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      // Create journey with 4 default phases including emotional curve values
      const journeyData = {
        user_journey_name: journeyName,
        owner: userID,
        description: description,
        linked_project: selectedProjectId,
        phase: [
          {
            phase_name: "Phase 1",
            phase_order: 1,
            score: 0,
            emotional_state: [],
            emotional_curve: 0,
            touchpoints: []
          },
          {
            phase_name: "Phase 2",
            phase_order: 2,
            score: 0,
            emotional_state: [],
            emotional_curve: 0,
            touchpoints: []
          },
          {
            phase_name: "Phase 3",
            phase_order: 3,
            score: 0,
            emotional_state: [],
            emotional_curve: 0,
            touchpoints: []
          },
          {
            phase_name: "Phase 4",
            phase_order: 4,
            score: 0,
            emotional_state: [],
            emotional_curve: 0,
            touchpoints: []
          }
        ]
      };
      
      console.log("Creating journey with data:", journeyData);
      
      const response = await axios.post(DIRECTUS_USER_JOURNEYS_ENDPOINT, journeyData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      
      if (!response.data || !response.data.data || !response.data.data.id) {
        throw new Error('Invalid response data when creating journey');
      }
      
      const newJourneyId = response.data.data.id;
      console.log("New journey created with ID:", newJourneyId);
      
      // Add persona relations if any are selected
      if (selectedPersonaIds.length > 0) {
        console.log("Adding relations to personas:", selectedPersonaIds);
        
        // For each persona, add a separate relation
        const relationsPromises = selectedPersonaIds.map(personaId => {
          return axios.post(`${DIRECTUS_INSTANCE}/items/userjourney_personas`, {
            userjourney_id: newJourneyId,
            personas_id: personaId
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          });
        });
        
        await Promise.all(relationsPromises);
        console.log("Successfully added all persona relations");
      }
      
      // Process user story or file if needed (placeholder for future implementation)
      if (activeTab === 'story' && userStory.trim()) {
        console.log("Processing user story for journey:", userStory);
        // This would be implemented to handle the user story text
      }
      
      if (activeTab === 'import' && selectedFile) {
        console.log("Processing uploaded file for journey:", selectedFile.name);
        // This would be implemented to handle the file upload
      }
      
      // Navigate to the new journey page
      navigate(`/user-journeys/${newJourneyId}`);
      
    } catch (error) {
      console.error('Error creating journey:', error);
      console.log("Error response:", error.response?.data);
      setError(error.response?.data?.errors?.[0]?.message || error.message || 'Failed to create journey');
      setLoading(false);
    }
  };

  return (
    <div className="py-16">
      <div className="max-w-5xl mx-auto bg-white px-10 pb-12 border border-neutral-200 rounded-lg pt-10">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="mb-8">
              <h1 className="text-3xl py-4 leading-7 text-gray-900">
                Create New User Journey
              </h1>
              <p className="mt-6 text-lg text-gray-600">
                Start by giving your journey a name and description. You can add phases, painpoints, and gains later.
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="pt-8">
                <h2 className="text-xl font-medium text-gray-900 mb-6">Journey Details</h2>
                
                <div className="space-y-6">
                  <div>
                    <label htmlFor="project" className="block text-sm font-medium text-gray-700 mb-2">
                      Project <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="project"
                        value={selectedProjectId}
                        onChange={handleProjectChange}
                        required
                        className="block w-full rounded-md border-gray-300 px-4 py-4 mb-6 focus:border-primary-500 focus:ring-primary-500"
                      >
                        <option value="">Select Project</option>
                        {projects.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.project_name}
                          </option>
                        ))}
                        <option value="new-project">Create New Project</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="journeyName" className="block text-sm font-medium text-gray-700 mb-2">
                      Journey Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="journeyName"
                        value={journeyName}
                        onChange={(e) => setJourneyName(e.target.value)}
                        required
                        placeholder="Enter journey name"
                        className="block w-full rounded-md border-gray-300 px-4 py-4 mb-6 focus:border-primary-500 focus:ring-primary-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-2">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="block w-full rounded-md border-gray-300 px-4 py-4 mb-6 focus:border-primary-500 focus:ring-primary-500"
                        placeholder="Describe your user journey"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-16">
                <h2 className="text-xl font-medium text-gray-900 mb-6">Optional Journey Building Methods</h2>
                
                <div className="border-b border-gray-200 mb-6">
                  <nav className="-mb-px flex space-x-8">
                    <button
                      type="button"
                      onClick={() => setActiveTab('personas')}
                      className={`${
                        activeTab === 'personas'
                          ? 'border-primary-500 text-primary-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      } whitespace-nowrap border-b-2 py-4 px-1 font-medium`}
                    >
                      Build from Personas
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab('import')}
                      className={`${
                        activeTab === 'import'
                          ? 'border-primary-500 text-primary-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      } whitespace-nowrap border-b-2 py-4 px-1 font-medium`}
                    >
                      Import from File
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab('story')}
                      className={`${
                        activeTab === 'story'
                          ? 'border-primary-500 text-primary-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      } whitespace-nowrap border-b-2 py-4 px-1 font-medium`}
                    >
                      Write User Story
                    </button>
                  </nav>
                </div>

                <div className="mt-8">
                  {activeTab === 'personas' && (
                    <div className="space-y-6">
                      <p className="text-base text-gray-600 italic mb-4">
                        Select personas to build a journey structure based on their characteristics.
                      </p>
                      <div>
                        <Menu as="div" className="relative">
                          <Menu.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-4 px-4 text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedPersonaIds.length > 0 
                                ? `${selectedPersonaIds.length} personas selected`
                                : 'Select personas'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Menu.Button>

                          <Menu.Items className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {personas.map((persona) => (
                              <Menu.Item key={persona.id}>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active ? 'bg-primary-50 text-primary-900' : 'text-gray-900'
                                    } relative cursor-pointer select-none py-3 pl-3 pr-9`}
                                    onClick={() => {
                                      setSelectedPersonaIds(prev => {
                                        const isSelected = prev.includes(persona.id);
                                        if (isSelected) {
                                          return prev.filter(id => id !== persona.id);
                                        } else {
                                          return [...prev, persona.id];
                                        }
                                      });
                                    }}
                                  >
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        checked={selectedPersonaIds.includes(persona.id)}
                                        className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                                        onChange={() => {}}
                                      />
                                      <span className="ml-3 block truncate">
                                        {persona.name} - {persona.type}
                                      </span>
                                    </div>
                                    {selectedPersonaIds.includes(persona.id) && (
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                        <div className="mt-4 flex flex-wrap gap-2">
                          {selectedPersonaIds.map(id => {
                            const persona = personas.find(p => p.id === id);
                            return persona ? (
                              <span
                                key={persona.id}
                                className="inline-flex items-center rounded-full bg-primary-50 px-3 py-1.5 text-sm font-medium text-primary-700"
                              >
                                {persona.name}
                                <button
                                  type="button"
                                  onClick={() => setSelectedPersonaIds(prev => prev.filter(pId => pId !== persona.id))}
                                  className="ml-2 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:bg-primary-500 focus:text-white focus:outline-none"
                                >
                                  <span className="sr-only">Remove {persona.name}</span>
                                  <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                  </svg>
                                </button>
                              </span>
                            ) : null;
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === 'import' && (
                    <div className="space-y-6">
                      <p className="text-base text-gray-600 italic mb-4">
                        Import a file with user interview transcripts or other user research data to generate journey insights.
                      </p>
                      <div className="flex items-center justify-center w-full">
                        <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-10 h-10 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                            </svg>
                            <p className="mb-2 text-base text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-sm text-gray-500">PDF, DOC, TXT, DOCX</p>
                          </div>
                          <input 
                            id="dropzone-file" 
                            type="file" 
                            className="hidden" 
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx,.txt"
                          />
                        </label>
                      </div>
                      {selectedFile && (
                        <p className="text-base text-primary-600 mt-4">
                          File selected: {selectedFile.name}
                        </p>
                      )}
                    </div>
                  )}

                  {activeTab === 'story' && (
                    <div className="space-y-6">
                      <p className="text-base text-gray-600 italic mb-4">
                        Write a user story or journey description in your own words and we'll structure it for you.
                      </p>
                      <div>
                        <textarea
                          rows={6}
                          value={userStory}
                          onChange={(e) => setUserStory(e.target.value)}
                          className="block w-full rounded-md border-gray-300 px-4 py-4 focus:border-primary-500 focus:ring-primary-500"
                          placeholder="Describe the user journey in your own words. For example: 'The user first visits our website looking for information about product pricing...'"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-6 w-6 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-red-800">Error</h3>
                      <div className="mt-2 text-base text-red-700">
                        <p>{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-4 mt-8">
                <button
                  type="button"
                  onClick={() => navigate('/user-journeys')}
                  className="rounded-full border border-gray-300 bg-white py-3 px-8 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading || !journeyName.trim() || !selectedProjectId}
                  className="rounded-full border border-transparent bg-primary-500 py-3 px-8 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Creating...' : 'Create Journey'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUserJourney;
