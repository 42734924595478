import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import Snackbar from '../Atoms/snackbar';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  LinkIcon,
  PlusIcon,
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowLeftIcon,
  CloudArrowUpIcon,
  UserPlusIcon,
  PresentationChartBarIcon,
  TrashIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    project_name: '',
    short_description: '',
    big_picture: '',
    product_service_details: '',
    identificator: '',
    goals: '',
    personas: [],
    industry_type: '',
    industry: '',
  });

  const [personasDetails, setPersonasDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPersonas, setLoadingPersonas] = useState(true);
  const [error, setError] = useState('');
  const token = localStorage.getItem('directus_token');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  const [heatmapsDetails, setHeatmapsDetails] = useState([]);
  const [loadingHeatmaps, setLoadingHeatmaps] = useState(true);

  const [insights, setInsights] = useState({
    lowPriority: 0,
    mediumPriority: 0,
    highPriority: 0,
    total: 0
  });

  const [editors, setEditors] = useState({
    short_description: null,
    big_picture: null,
    product_service_details: null
  });

  const editorRefs = useRef({
    short_description: null,
    big_picture: null,
    product_service_details: null
  });

  const autosaveTimeoutRef = useRef(null);

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProject(response.data.data);
        await fetchPersonas(response.data.data.personas);
        await fetchHeatmaps();
        await fetchInsights();
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch project details.');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id, token]);

  const fetchPersonas = async (personaIds) => {
    setLoadingPersonas(true);
    try {
      const responses = await Promise.all(personaIds.map((personaId) =>
        axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${personaId}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['id', 'name', 'type', 'occupation', 'avatar.*']
          }
        })
      ));
      setPersonasDetails(responses.map((response) => response.data.data));
      setLoadingPersonas(false);
    } catch (error) {
      setError('Failed to fetch personas details.');
      setLoadingPersonas(false);
    }
  };

  const fetchHeatmaps = async () => {
    setLoadingHeatmaps(true);
    try {
      const response = await axios.get('https://panel.reislo.com/items/heatmaps', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { project: { _eq: id } },
          fields: ['id', 'title', 'date_created', 'screenshot', 'screenshot.*', 'screenshot_mobile', 'screenshot_mobile.*']
        }
      });
      setHeatmapsDetails(response.data.data);
      setLoadingHeatmaps(false);
    } catch (error) {
      setError('Failed to fetch heatmaps details.');
      setLoadingHeatmaps(false);
    }
  };

  const fetchInsights = async () => {
    try {
      const response = await axios.get('https://panel.reislo.com/items/insights', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { project: { _eq: id } },
          fields: ['id', 'piority', 'description', 'project.*']
        }
      });
      
      const insightData = response.data.data;
      const priorities = {
        lowPriority: insightData.filter(i => i.piority === 'low').length,
        mediumPriority: insightData.filter(i => i.piority === 'medium').length,
        highPriority: insightData.filter(i => i.piority === 'high').length,
        total: insightData.length
      };
      setInsights(priorities);
    } catch (error) {
      console.error('Failed to fetch insights:', error);
      setError('Failed to fetch insights details.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prev) => ({ ...prev, [name]: value }));
  };

  const saveProject = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, project, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setSnackbarMessage('Project saved successfully');
        setActionName('Close');
        setSnackbarType('success');
        setShowSnackbar(true);
      } else {
        setSnackbarMessage('Failed to save project');
        setActionName('Retry');
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage('Failed to save project: ' + (error.message || 'Unknown error'));
      setActionName('Retry');
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePersonaClick = (personaId) => {
    navigate(`/personas/${personaId}`);
  };

  const handleHeatmapClick = (heatmapId) => {
    navigate(`/predictions/${heatmapId}`);
  };

  return (
    <div className="pb-40 lg:pl-24">
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          actionName={actionName}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}

      <div className="mx-auto max-w-75xl">
        <div className="flex gap-4 justify-between py-4 items-center mt-4">
          <div className="cursor-pointer" onClick={() => navigate('/projects')}>
            <div className="bg-white border border-secondary-100 hover:bg-gray-50 transition-colors duration-200 rounded-full p-3 flex items-center justify-center">
              <ArrowLeftIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
            </div>
          </div>
          <div className="flex gap-3 items-center">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-5 h-5 border-3 rounded-full text-primary-500" />
            ) : (
              <button
                onClick={saveProject}
                className="flex items-center gap-2 px-4 py-2 bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md transition-colors duration-200"
              >
                <CloudArrowUpIcon className="h-5 w-5" />
                <span>Save Project</span>
              </button>
            )}
            <span className="text-sm text-neutral-600">Last saved {new Date(project.date_updated).toLocaleDateString()}</span>
          </div>
        </div>
      </div>

      <div className="mx-auto pt-4 mt-0 bg-white rounded-lg">
        <div className="flex max-w-6xl mx-auto gap-8">
          {/* Main Content Area */}
          <div className="flex-1">
            {loading ? (
              <div className="max-w-6xl mx-auto">
                <ProjectDetailsSkeleton />
              </div>
            ) : error ? (
              <div className="max-w-6xl mx-auto text-center py-12">
                <div className="text-red-500 mb-4">
                  <ExclamationCircleIcon className="h-12 w-12 mx-auto" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Project</h3>
                <p className="text-gray-500">{error}</p>
                <button
                  onClick={() => window.location.reload()}
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
                >
                  Retry
                </button>
              </div>
            ) : (
              <>
                <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6 shadow-sm">
                  <div className="flex items-center gap-8">
                    <div className="flex flex-col text-left w-full">
                      <input
                        type="text"
                        name="project_name"
                        value={project.project_name}
                        onChange={handleInputChange}
                        className="text-2xl font-medium mb-2 w-full border-none focus:outline-none focus:ring-2 focus:ring-primary-200 rounded px-2 py-1"
                        placeholder="Project Name"
                      />
                      <div className="flex items-center space-x-4 text-sm text-gray-500">
                        <div className="flex items-center">
                          <BriefcaseIcon className="h-5 w-5 mr-2 text-gray-400" />
                          {project.industry_type || 'Industry Type'}
                        </div>
                        <div className="flex items-center">
                          <BuildingOfficeIcon className="h-5 w-5 mr-2 text-gray-400" />
                          {project.industry || 'Industry'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <textarea
                    name="short_description"
                    value={project.short_description}
                    onChange={handleInputChange}
                    className="w-full mt-6 personas-details-ghost-inputs p-2 border border-transparent focus:border-secondary-100 rounded"
                    placeholder="Project description..."
                  />
                </div>

                {/* Project Insights Section */}
                <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6 shadow-sm">
                  <h3 className="text-lg font-medium mb-3">Project Insights</h3>
                  <dl className="grid grid-cols-4 gap-4">
                    {[
                      { name: 'Total Insights', stat: insights.total, color: 'bg-blue-50 text-blue-700' },
                      { name: 'Low Priority', stat: insights.lowPriority, color: 'bg-green-50 text-green-700' },
                      { name: 'Medium Priority', stat: insights.mediumPriority, color: 'bg-yellow-50 text-yellow-700' },
                      { name: 'High Priority', stat: insights.highPriority, color: 'bg-red-50 text-red-700' }
                    ].map((item) => (
                      <div key={item.name} className={`px-4 py-3 rounded-lg ${item.color.split(' ')[0]}`}>
                        <dt className="text-sm font-normal text-gray-700">{item.name}</dt>
                        <dd className={`text-2xl font-semibold ${item.color.split(' ')[1]}`}>{item.stat}</dd>
                      </div>
                    ))}
                  </dl>
                </div>

                {/* Big Picture & Services Details Grid */}
                <div className="grid grid-cols-1 gap-6">
                  <div className="bg-white border border-secondary-100 rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-medium mb-4">Big Picture</h2>
                    <textarea
                      name="big_picture"
                      value={project.big_picture}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs p-2 border border-transparent focus:border-secondary-100 rounded"
                      rows="6"
                      placeholder="Describe the big picture of your project..."
                    />
                  </div>
                  <div className="bg-white border border-secondary-100 rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-medium mb-4">Services Details</h2>
                    <textarea
                      name="product_service_details"
                      value={project.product_service_details}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs p-2 border border-transparent focus:border-secondary-100 rounded"
                      rows="6"
                      placeholder="Describe your product or service details..."
                    />
                  </div>
                </div>

                {/* Goals & Competitors Grid */}
                <div className="grid grid-cols-1 gap-6 mt-6">
                  <div className="bg-white border border-secondary-100 rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-medium mb-4">Goals</h2>
                    <textarea
                      name="goals"
                      value={project.goals}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs p-2 border border-transparent focus:border-secondary-100 rounded"
                      rows="3"
                      placeholder="What are the main goals of this project?"
                    />
                  </div>
                  <div className="bg-white border border-secondary-100 rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-medium mb-4">Competitors</h2>
                    <textarea
                      name="competitors"
                      value={project.competitors}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs p-2 border border-transparent focus:border-secondary-100 rounded"
                      rows="3"
                      placeholder="Who are your main competitors?"
                    />
                  </div>
                </div>

                {/* Personas Section */}
                <div className="bg-white border border-secondary-100 rounded-lg p-6 mt-6 shadow-sm">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium">Personas</h2>
                    <button
                      onClick={() => navigate('/personas/create-persona')}
                      className="inline-flex items-center rounded-md bg-primary-500 hover:bg-primary-600 px-3 py-2 text-sm font-medium text-white transition-colors duration-200"
                    >
                      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" />
                      New Persona
                    </button>
                  </div>
                  {loadingPersonas ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <PersonaCardSkeleton key={index} />
                      ))}
                    </div>
                  ) : personasDetails.length > 0 ? (
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {personasDetails.map((persona) => (
                        <li
                          key={persona.id}
                          onClick={() => handlePersonaClick(persona.id)}
                          className="cursor-pointer bg-white hover:shadow-lg transition-shadow duration-200 text-lg p-4 rounded-lg border border-gray-200"
                        >
                          <div className="flex items-center space-x-4">
                            <img
                              className="h-12 w-12 flex-shrink-0 rounded-full"
                              src={persona.avatar?.id 
                                ? `https://panel.reislo.com/assets/${persona.avatar.id}`
                                : null
                              }
                              alt={persona.name}
                              onError={(e) => {
                                e.target.style.display = 'none';
                              }}
                            />
                            <div className="flex-1">
                              <div className="flex justify-between">
                                <div className="font-medium">{persona.name}</div>
                                <div className="text-sm">{persona.type}</div>
                              </div>
                              <div className="text-sm text-gray-600">{persona.occupation}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center p-6 bg-gray-50 rounded-lg border border-gray-100">
                      <UserPlusIcon className="h-12 w-12 mx-auto text-gray-400 mb-3" />
                      <p className="text-gray-600 mb-4">No personas assigned to this project yet.</p>
                      <button
                        onClick={() => navigate('/personas/create-persona')}
                        className="inline-flex items-center rounded-md bg-primary-500 hover:bg-primary-600 px-4 py-2 text-sm font-medium text-white transition-colors duration-200"
                      >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" />
                        Create a Persona
                      </button>
                    </div>
                  )}
                </div>

                {/* Heatmaps Section */}
                <div className="bg-white border border-secondary-100 rounded-lg p-6 mt-6 shadow-sm">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium">Heatmaps</h2>
                    <button
                      onClick={() => navigate('/predictions/create-heatmap')}
                      className="inline-flex items-center rounded-md bg-primary-500 hover:bg-primary-600 px-3 py-2 text-sm font-medium text-white transition-colors duration-200"
                    >
                      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" />
                      New Heatmap
                    </button>
                  </div>
                  {loadingHeatmaps ? (
                    <div className="mt-4">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                              Title
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Type
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <HeatmapTableSkeleton key={index} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : heatmapsDetails.length > 0 ? (
                    <div className="mt-4 overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                              Title
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Type
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {heatmapsDetails.map((heatmap) => (
                            <tr
                              key={heatmap.id}
                              onClick={() => handleHeatmapClick(heatmap.id)}
                              className="cursor-pointer hover:bg-gray-50"
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                <div className="flex items-center">
                                  <div className="font-medium text-gray-900">{heatmap.title}</div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {heatmap.type || 'N/A'}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(heatmap.date_created).toLocaleDateString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center p-6 bg-gray-50 rounded-lg border border-gray-100">
                      <PresentationChartBarIcon className="h-12 w-12 mx-auto text-gray-400 mb-3" />
                      <p className="text-gray-600 mb-4">No heatmaps associated with this project yet.</p>
                      <button
                        onClick={() => navigate('/predictions/create-heatmap')}
                        className="inline-flex items-center rounded-md bg-primary-500 hover:bg-primary-600 px-4 py-2 text-sm font-medium text-white transition-colors duration-200"
                      >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" />
                        Create a Heatmap
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {/* Right Sidebar */}
          <div className="w-80 space-y-6">
            <div className="bg-white border border-secondary-100 rounded-lg p-4 shadow-sm">
              <h3 className="text-lg font-medium mb-3">Actions</h3>
              <div className="space-y-2">
                <button 
                  onClick={() => navigate('/personas/create-persona')} 
                  className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 transition-colors duration-200"
                >
                  <UserPlusIcon className="h-5 w-5 text-primary-600" />
                  Add New Persona
                </button>
                <button 
                  onClick={() => navigate('/predictions/create-heatmap')} 
                  className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 transition-colors duration-200"
                >
                  <PresentationChartBarIcon className="h-5 w-5 text-primary-600" />
                  Create Heatmap
                </button>
                <button className="w-full text-left px-3 py-2 text-sm rounded-md hover:bg-red-50 text-red-600 flex items-center gap-2 transition-colors duration-200">
                  <TrashIcon className="h-5 w-5" />
                  Delete Project
                </button>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-4 shadow-sm">
              <h3 className="text-lg font-medium mb-3">Project Stats</h3>
              <div className="space-y-2">
                <div className="px-3 py-2 text-sm bg-gray-50 rounded-md">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Personas</span>
                    <span className="font-medium text-gray-900">{personasDetails.length || 0}</span>
                  </div>
                </div>
                <div className="px-3 py-2 text-sm bg-gray-50 rounded-md">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Heatmaps</span>
                    <span className="font-medium text-gray-900">{heatmapsDetails.length || 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectDetailsSkeleton = () => (
  <div className="animate-pulse space-y-6">
    <div className="h-8 bg-gray-200 rounded w-1/3"></div>
    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    <div className="space-y-3">
      <div className="h-4 bg-gray-200 rounded"></div>
      <div className="h-4 bg-gray-200 rounded"></div>
      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
    </div>
  </div>
);

const PersonaCardSkeleton = () => (
  <div className="bg-white p-4 rounded-lg shadow animate-pulse">
    <div className="flex items-center space-x-4">
      <div className="h-12 w-12 bg-gray-200 rounded-full"></div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
    </div>
  </div>
);

const HeatmapTableSkeleton = () => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
    </td>
  </tr>
);

export { ProjectDetailsSkeleton, PersonaCardSkeleton, HeatmapTableSkeleton };
export default ProjectDetails;