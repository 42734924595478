import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

const EmptyState = ({
  title,
  description,
  icon: Icon,
  imageSrc,
  onPrimaryClick,
  primaryAction = 'Create New',
  iconClassName = 'text-gray-400'
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center pt-0 pb-24">
      {imageSrc ? (
        <div className="mb-4">
          <img src={imageSrc} alt={title} className="mx-auto" />
        </div>
      ) : Icon && (
        <div className="mb-4">
          <Icon className={`mx-auto h-12 w-12 ${iconClassName}`} aria-hidden="true" />
        </div>
      )}
      <div className="max-w-2xl">
        <h3 className="text-2xl font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-base text-gray-500">{description}</p>
      </div>
      {onPrimaryClick && (
        <div className="mt-6">
          <button
            type="button"
            onClick={onPrimaryClick}
            className="inline-flex items-center rounded-full bg-primary-500 pl-4 pr-6 py-2 text-sm font-regular text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            {primaryAction}
          </button>
        </div>
      )}
    </div>
  );
};

export default EmptyState; 