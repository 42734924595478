import { ASSISTANT_INSTRUCTIONS } from './assistantConfig';
import {
  createThread,
  addMessageToThread,
  runAssistant,
  checkRunStatus,
  listMessages
} from '../../utils/assistantApi';

export const getOrCreateThread = async (existingThreadId) => {
  if (existingThreadId) {
    console.log("Using existing thread ID:", existingThreadId);
    return existingThreadId;
  }
  
  try {
    console.log("Creating new thread...");
    const newThreadId = await createThread();
    console.log("New thread created:", newThreadId);
    return newThreadId;
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

export const sendMessageAndGetResponse = async (threadId, assistantId, vectorStoreId, userMessage) => {
  try {
    if (!threadId) {
      throw new Error('Thread ID is undefined');
    }

    console.log(`Thread ID: ${threadId}, Assistant ID: ${assistantId}`);

    // Add user message to thread
    await addMessageToThread(threadId, {
      role: 'user',
      content: userMessage,
    });

    // Run assistant on the thread
    const runParameters = {};
    
    // Dodaj file_search tylko jeśli vectorStoreId istnieje
    if (vectorStoreId) {
      runParameters.file_search = { 
        vector_store_ids: [vectorStoreId] 
      };
    }
    
    console.log("Running assistant with parameters:", runParameters);
    
    const run = await runAssistant(threadId, assistantId, runParameters);
    
    // Poll for the run to complete
    const MAX_RETRIES = 60;
    const RETRY_INTERVAL = 1000; // 1 second
    
    let runStatus;
    let currentRetry = 0;
    
    do {
      // Wait for the next check
      await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
      
      // Check run status
      runStatus = await checkRunStatus(threadId, run.id);
      currentRetry++;
      
      console.log(`Run status check ${currentRetry}/${MAX_RETRIES}: ${runStatus.status}`);
    } while (
      runStatus.status !== 'completed' && 
      runStatus.status !== 'failed' && 
      runStatus.status !== 'cancelled' && 
      currentRetry < MAX_RETRIES
    );
    
    if (runStatus.status !== 'completed') {
      console.error(`Run did not complete. Final status: ${runStatus.status}`);
      throw new Error(`Assistant run ${runStatus.status}`);
    }
    
    // Retrieve messages
    const messagesRes = await listMessages(threadId);
    
    // Find assistant message
    const assistantMessages = messagesRes.data
      .filter(msg => msg.role === 'assistant')
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
    const assistantMessage = assistantMessages[0];

    if (!assistantMessage?.content?.[0]?.text?.value) {
      console.error('No valid assistant message found', assistantMessages);
      return null;
    }

    return assistantMessage.content[0].text.value;
  } catch (error) {
    console.error('Error in sendMessageAndGetResponse:', error);
    throw error;
  }
};

export const setupAssistantPrompt = (assistantId, instructions) => {
  return instructions || ASSISTANT_INSTRUCTIONS;
}; 