import React from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const Loader = ({ isLoading, submitStatus }) => (
  <div className="loader">
    {isLoading && <div className="spinner" />}
    {
      !isLoading &&
        submitStatus === 'success' ?
        <div className="text-center">
          <CheckCircleIcon className="mx-auto h-16 w-16 text-green-500" />
          <h1 className="text-4xl font-semibold text-gray-900 mt-4">Persona Created Successfully</h1>
          <p className="mt-2 text-lg text-gray-600">Your new persona has been created. You can now view it or create another one.</p>
          <div className="flex gap-8 justify-center mt-8">
            <a className="bg-primary-500 hover:bg-primary-700 text-white py-2 px-4 rounded-md shadow" href="/personas">Open Personas</a>
            <a className="bg-white border border-primary-500 hover:bg-primary-50 text-primary-500 py-2 px-4 rounded-md shadow" href="/create-persona">Create Another One</a>
          </div>
        </div> :
        submitStatus === 'error' &&
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto h-16 w-16 text-red-500" />
          <h1 className="text-4xl font-semibold text-gray-900 mt-4">Persona Creation Failed</h1>
          <p className="mt-2 text-lg text-gray-600">There was an error creating the persona. Please check your inputs and try again.</p>
        </div>
    }
  </div>
);

export default Loader;





