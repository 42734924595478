import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { UserIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getOrCreateThread, sendMessageAndGetResponse } from './utilities';
import { 
  getAssistantId, 
  getVectorStoreId, 
  saveChatMessage, 
  getChatMessages,
  createChatSession
} from '../../utils/assistantApi';
import { DIRECTUS_INSTANCE } from '../../api';
import { SYSTEM_MESSAGES } from './assistantConfig';
import axios from 'axios';
import Waiter from './waiter';
import SourceDrawer from './SourceDrawer';
import LogoSymbol from '../Atoms/logoSymbol';
import { getMockResponse } from '../../utils/mockResponse';
import { v4 as uuidv4 } from 'uuid';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { 
  DocumentDuplicateIcon, 
  HandThumbUpIcon, 
  HandThumbDownIcon,
  UserGroupIcon,
  MapIcon,
  LightBulbIcon,
  ChartBarIcon,
  ArchiveBoxIcon
} from '@heroicons/react/24/outline';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { saveAs } from 'file-saver';
import GraphvizRenderer from './GraphvizRenderer';

const SingleChat = ({ sessionId: propSessionId }) => {
  const { sessionId: paramSessionId } = useParams();
  const sessionId = propSessionId || paramSessionId;
  const isNewChat = sessionId === 'new';
  
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [chatTitle, setChatTitle] = useState('');
  const [chatSession, setChatSession] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedCitation, setSelectedCitation] = useState('');
  const [exportedMessages, setExportedMessages] = useState([]);
  const [showExportSidebar, setShowExportSidebar] = useState(false);
  
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  useEffect(() => {
    const initializeChat = async () => {
      try {
        if (isNewChat) {
          console.log("Creating new chat session...");
          // Creating a new thread for a new chat
          const newThreadId = await getOrCreateThread();
          console.log("Created new thread:", newThreadId);
          setThreadId(newThreadId);
          
          // Create new chat session in Directus
          const newSession = await createChatSession(
            'New Conversation', 
            newThreadId
          );
          console.log("Created new session:", newSession);
          
          setCurrentSessionId(newSession.id);
          setChatSession(newSession);
          navigate(`/chat/${newSession.id}`, { replace: true });
          
          // Add welcome message to UI only (don't save to database yet)
          setMessages([{
            id: 'welcome',
            role: 'assistant',
            content: SYSTEM_MESSAGES.welcome,
            timestamp: new Date()
          }]);
        } else {
          // Load existing chat session
          console.log("Loading existing chat session:", sessionId);
          
          // Fetch chat session data
          const token = localStorage.getItem('directus_token');
          const response = await axios.get(`${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const session = response.data.data;
          setChatSession(session);
          setChatTitle(session.title);
          
          if (session.thread_id) {
            setThreadId(session.thread_id);
          } else {
            console.warn("No thread_id found in session, creating new thread");
            const newThreadId = await getOrCreateThread();
            setThreadId(newThreadId);
            
            // Update session with new thread_id
            await axios.patch(`${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionId}`, 
              { thread_id: newThreadId },
              { headers: { Authorization: `Bearer ${token}` }}
            );
          }
          
          // Load chat messages
          const messagesData = await getChatMessages(sessionId);
          console.log("Loaded messages:", messagesData);
          
          if (messagesData.length > 0) {
            setMessages(messagesData.map(msg => ({
              id: msg.id,
              role: msg.role,
              content: msg.content,
              timestamp: new Date(msg.date_created)
            })));
          } else {
            // Add welcome message if no messages exist
            setMessages([{
              id: 'welcome',
              role: 'assistant',
              content: SYSTEM_MESSAGES.welcome,
              timestamp: new Date()
            }]);
          }
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };
    
    initializeChat();
  }, [sessionId, isNewChat, navigate]);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;
    
    const userMessage = {
      id: uuidv4(),
      role: 'user',
      content: inputValue,
      timestamp: new Date().toISOString()
    };
    
    // Add user message to UI
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setIsLoading(true);
    
    try {
      const sessionToUse = currentSessionId || sessionId;
      
      // Check if this is the first user message (messages only has welcome message)
      const isFirstMessage = messages.length === 1 && messages[0].id === 'welcome';
      
      // If this is the first message, save the welcome message first
      if (isFirstMessage) {
        await saveChatMessage(sessionToUse, {
          role: 'assistant',
          content: messages[0].content
        });
      }
      
      // Save user message to Directus
      await saveChatMessage(sessionToUse, {
        role: 'user',
        content: userMessage.content
      });
      
      // Get assistant ID and vector store ID
      const assistantId = await getAssistantId();
      const vectorStoreId = await getVectorStoreId();
      
      console.log(`Sending message with threadId: ${threadId}, assistantId: ${assistantId}`);
      
      // Send message to OpenAI and get response
      const response = await sendMessageAndGetResponse(
        threadId,
        assistantId,
        vectorStoreId,
        userMessage.content
      );
      
      if (response) {
        // Add assistant response to UI
        const assistantMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: response,
          timestamp: new Date().toISOString()
        };
        
        setMessages(prev => [...prev, assistantMessage]);
        
        // Save assistant message to Directus
        await saveChatMessage(sessionToUse, {
          role: 'assistant',
          content: assistantMessage.content
        });
        
        // Update chat title if it's the first message
        if (messages.length <= 1) {
          const newTitle = userMessage.content.length > 30 
            ? `${userMessage.content.substring(0, 30)}...` 
            : userMessage.content;
          
          setChatTitle(newTitle);
          
          // Update chat session title in Directus
          const token = localStorage.getItem('directus_token');
          await axios.patch(
            `${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionToUse}`,
            { title: newTitle },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Obsługa błędu API - używamy mocka dla testów
      try {
        const mockResponse = await getMockResponse(userMessage.content);
        
        const assistantMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: mockResponse,
          timestamp: new Date().toISOString()
        };
        
        // Dodaj wiadomość asystenta do UI
        setMessages(prev => [...prev, assistantMessage]);
        
        // Zapisz wiadomość w bazie danych
        await saveChatMessage(
          currentSessionId,
          assistantMessage.role, 
          assistantMessage.content
        );
        
        console.log("Used mock response due to API error");
      } catch (mockError) {
        // Jeśli nawet mock nie działa, po prostu pokazujemy błąd
        const errorMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: SYSTEM_MESSAGES.error,
          timestamp: new Date().toISOString()
        };
        
        setMessages(prev => [...prev, errorMessage]);
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  const handleBackClick = () => {
    navigate('/assistant');
  };

  const handleSourceClick = (source, citation) => {
    setSelectedSource(source);
    setSelectedCitation(citation);
    setDrawerOpen(true);
  };
  
  // Extract sources from markdown content like [Source: filename.pdf]
  const extractSources = (content) => {
    const regex = /\[Source:\s*([^\]]+)\]/g;
    let match;
    const sources = [];
    
    while ((match = regex.exec(content)) !== null) {
      sources.push(match[1].trim());
    }
    
    return sources;
  };
  
  // Add a helper function to detect and extract DOT code
  const extractGraphvizCode = (content) => {
    const graphvizRegex = /```(?:graphviz|dot)\s*([\s\S]*?)```/gi;
    const match = graphvizRegex.exec(content);
    return match ? match[1].trim() : null;
  };
  
  // Update the parseMessage function
  const parseMessage = (content) => {
    // Check if the message contains Graphviz code
    const graphvizCode = extractGraphvizCode(content);
    
    // If it contains Graphviz code, render it with the special component
    if (graphvizCode) {
      const contentWithoutGraphviz = content.replace(/```(?:graphviz|dot)\s*[\s\S]*?```/gi, '');
      
      return (
        <div className="max-w-2xl prose-headings:mt-4 prose-headings:mb-2 prose-p:my-2 prose-ul:my-2 prose-li:my-1">
          {contentWithoutGraphviz && (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1 {...props} className="text-xl font-bold mt-6 mb-3" />
                ),
                h2: ({ node, ...props }) => (
                  <h2 {...props} className="text-lg font-semibold mt-5 mb-3" />
                ),
                h3: ({ node, ...props }) => (
                  <h3 {...props} className="text-md font-semibold mt-4 mb-2" />
                ),
                h4: ({ node, ...props }) => (
                  <h4 {...props} className="font-medium mt-3 mb-2" />
                ),
                p: ({ node, ...props }) => (
                  <p {...props} className="my-2 leading-relaxed" />
                ),
                ol: ({ node, ...props }) => (
                  <ol {...props} className="pl-5 my-3 space-y-1 list-decimal" />
                ),
                ul: ({ node, ...props }) => (
                  <ul {...props} className="pl-5 my-3 space-y-1 list-disc" />
                ),
                li: ({ node, ...props }) => (
                  <li {...props} className="my-1 pl-1" />
                ),
                code: ({ node, inline, className, children, ...props }) => {
                  return !inline ? (
                    <pre className="bg-gray-100 rounded p-3 overflow-x-auto my-3">
                      <code className={className} {...props}>
                        {children}
                      </code>
                    </pre>
                  ) : (
                    <code className="bg-gray-100 px-1.5 py-0.5 rounded text-sm" {...props}>
                      {children}
                    </code>
                  );
                },
                a: ({ node, ...props }) => (
                  <a {...props} target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline font-medium" />
                ),
                strong: ({ node, ...props }) => (
                  <strong {...props} className="font-semibold" />
                ),
                table: ({ node, ...props }) => (
                  <div className="overflow-x-auto my-4">
                    <table {...props} className="min-w-full divide-y divide-gray-200 border border-gray-300" />
                  </div>
                ),
                thead: ({ node, ...props }) => (
                  <thead {...props} className="bg-gray-50" />
                ),
                tbody: ({ node, ...props }) => (
                  <tbody {...props} className="bg-white divide-y divide-gray-200" />
                ),
                tr: ({ node, isHeader, ...props }) => (
                  <tr {...props} className={isHeader ? "bg-gray-50" : "hover:bg-gray-50"} />
                ),
                th: ({ node, ...props }) => (
                  <th {...props} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" />
                ),
                td: ({ node, ...props }) => (
                  <td {...props} className="px-4 py-3 text-sm text-gray-500 whitespace-normal" />
                ),
              }}
            >
              {contentWithoutGraphviz}
            </ReactMarkdown>
          )}
          <GraphvizRenderer dotSource={graphvizCode} />
        </div>
      );
    }
    
    // Otherwise, render normally
    return (
      <div className="max-w-2xl prose-headings:mt-4 prose-headings:mb-2 prose-p:my-2 prose-ul:my-2 prose-li:my-1">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({ node, ...props }) => (
              <h1 {...props} className="text-xl font-bold mt-6 mb-3" />
            ),
            h2: ({ node, ...props }) => (
              <h2 {...props} className="text-lg font-semibold mt-5 mb-3" />
            ),
            h3: ({ node, ...props }) => (
              <h3 {...props} className="text-md font-semibold mt-4 mb-2" />
            ),
            h4: ({ node, ...props }) => (
              <h4 {...props} className="font-medium mt-3 mb-2" />
            ),
            p: ({ node, ...props }) => (
              <p {...props} className="my-2 leading-relaxed" />
            ),
            ol: ({ node, ...props }) => (
              <ol {...props} className="pl-5 my-3 space-y-1 list-decimal" />
            ),
            ul: ({ node, ...props }) => (
              <ul {...props} className="pl-5 my-3 space-y-1 list-disc" />
            ),
            li: ({ node, ...props }) => (
              <li {...props} className="my-1 pl-1" />
            ),
            code: ({ node, inline, className, children, ...props }) => {
              return !inline ? (
                <pre className="bg-gray-100 rounded p-3 overflow-x-auto my-3">
                  <code className={className} {...props}>
                    {children}
                  </code>
                </pre>
              ) : (
                <code className="bg-gray-100 px-1.5 py-0.5 rounded text-sm" {...props}>
                  {children}
                </code>
              );
            },
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline font-medium" />
            ),
            strong: ({ node, ...props }) => (
              <strong {...props} className="font-semibold" />
            ),
            table: ({ node, ...props }) => (
              <div className="overflow-x-auto my-4">
                <table {...props} className="min-w-full divide-y divide-gray-200 border border-gray-300" />
              </div>
            ),
            thead: ({ node, ...props }) => (
              <thead {...props} className="bg-gray-50" />
            ),
            tbody: ({ node, ...props }) => (
              <tbody {...props} className="bg-white divide-y divide-gray-200" />
            ),
            tr: ({ node, isHeader, ...props }) => (
              <tr {...props} className={isHeader ? "bg-gray-50" : "hover:bg-gray-50"} />
            ),
            th: ({ node, ...props }) => (
              <th {...props} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" />
            ),
            td: ({ node, ...props }) => (
              <td {...props} className="px-4 py-3 text-sm text-gray-500 whitespace-normal" />
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    );
  };
  
  const MessageBubble = ({ message, isLoading }) => {
    const isAssistant = message.role === 'assistant';
    
    const handleCopyMessage = () => {
      navigator.clipboard.writeText(message.content)
        .then(() => {
          // Could add a toast notification here
          console.log('Message copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy message:', err);
        });
    };
    
    return (
      <div className={`flex gap-4 w-full ${!isAssistant ? 'justify-end' : ''}`}>
        {isAssistant && (
          <div className="size-10 flex items-center rounded-full justify-center shrink-0  bg-white shadow-sm">
            <div className="translate-y-px">
              <SparklesIcon className="w-3.5 h-3.5" />
            </div>
          </div>
        )}
        
        <div className="flex flex-col gap-4 max-w-2xl">
          <div className="flex flex-row gap-2 items-start">
            <div className={`flex flex-col gap-4 px-4 py-2.5 rounded-xl text-sm leading-6 ${
              isAssistant 
                ? 'bg-white shadow-sm' 
                : 'bg-gray-900 text-white'
            }`}>
              {parseMessage(message.content)}
            </div>
          </div>
          
          {isAssistant && (
            <div className="flex flex-row gap-2">
              <button 
                onClick={handleCopyMessage}
                className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background hover:bg-accent hover:text-accent-foreground py-1 px-2 h-fit text-muted-foreground">
                <DocumentDuplicateIcon className="w-4 h-4" />
              </button>
              <button 
                onClick={() => handleSaveMessage(message)}
                className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background hover:bg-accent hover:text-accent-foreground py-1 px-2 h-fit text-muted-foreground">
                <span>Save</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  const handleSaveMessage = (message) => {
    // Add message to exported list if not already there
    if (!exportedMessages.some(m => m.id === message.id)) {
      setExportedMessages(prev => [...prev, message]);
      
      // Optional: Show a brief notification or open the sidebar
      if (exportedMessages.length === 0) {
        setShowExportSidebar(true); // Show sidebar on first save
      }
    }
  };
  
  const handleDownloadMessage = (message) => {
    // Create a Blob with the message content
    const blob = new Blob([message.content], {type: "text/plain;charset=utf-8"});
    
    // Generate filename based on content type or message ID
    let filename = `message-${message.id.substring(0, 8)}`;
    
    // Try to detect content type for better naming
    if (message.content.includes('digraph') && 
        (message.content.includes('```graphviz') || message.content.includes('```dot'))) {
      filename = "diagram.dot";
    } else if (message.content.includes('|') && message.content.includes('-') && 
        (message.content.toLowerCase().includes('faza') || 
         message.content.toLowerCase().includes('phase'))) {
      filename = "user-journey-table.md";
    } else if (message.content.includes('|') && message.content.includes('-')) {
      filename = "table-export.md";
    } else if (message.content.includes('```')) {
      filename = "code-snippet.txt";
    } else if (message.content.length > 500) {
      filename = "long-response.md";
    }
    
    // Save the file
    saveAs(blob, filename);
  };
  
  const handleCreateUserJourney = () => {
    navigate('/user-journeys/create', { 
      state: { 
        fromChat: true, 
        chatId: currentSessionId,
        content: messages.map(m => m.content).join('\n\n')
      } 
    });
  };

  const handleCreatePersona = () => {
    navigate('/personas/create-persona', { 
      state: { 
        fromChat: true, 
        chatId: currentSessionId,
        content: messages.map(m => m.content).join('\n\n')
      } 
    });
  };

  const handleCreateInsight = () => {
    navigate('/insights/create', { 
      state: { 
        fromChat: true, 
        chatId: currentSessionId,
        content: messages.map(m => m.content).join('\n\n')
      } 
    });
  };

  const handleCreateUXAnalysis = () => {
    navigate('/predictions/create-heatmap', { 
      state: { 
        fromChat: true, 
        chatId: currentSessionId,
        content: messages.map(m => m.content).join('\n\n')
      } 
    });
  };

  const handleAddToDatabase = () => {
    navigate('/files/upload', { 
      state: { 
        fromChat: true, 
        chatId: currentSessionId,
        content: messages.map(m => m.content).join('\n\n')
      } 
    });
  };
  
  const ActionButton = ({ icon, label, onClick }) => {
    return (
      <button
        onClick={onClick}
        className="inline-flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        {icon && <span className="w-4 h-4">{icon}</span>}
        <span>{label}</span>
      </button>
    );
  };
  
  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Header */}
      <div className="flex items-center p-4">
        <button
          onClick={handleBackClick}
          className="p-2 rounded-lg hover:bg-gray-50 shadow-sm bg-white"
        >
          <ArrowLeftIcon className="h-5 w-5 text-gray-600" />
        </button>
      </div>
      
      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-8  px-80">
        {messages.map((message, index) => (
          <MessageBubble key={index} message={message} />
        ))}
        
        {isLoading && (
          <div className="flex gap-4 w-full">
            <div className="size-8 flex items-center rounded-full justify-center  shrink-0  bg-background">
              <div className="translate-y-px">
                <SparklesIcon className="w-3.5 h-3.5" />
              </div>
            </div>
            <div className="flex flex-col gap-4 max-w-2xl">
              <div className="flex flex-row gap-2 items-start">
                <div className="bg-white shadow-sm px-3 py-2 rounded-xl">
                  <Waiter />
                </div>
              </div>
            </div>
          </div>
        )}
        
        <div ref={messagesEndRef} />
      </div>
      
      {/* Input */}
      <div className="p-4">
        {/* Action Buttons */}
        <div className="max-w-5xl mx-auto mb-4">
          <div className="flex flex-wrap gap-2 justify-center">
            <ActionButton 
              icon={<UserGroupIcon />} 
              label="Create Personas" 
              onClick={handleCreatePersona} 
            />
            <ActionButton 
              icon={<MapIcon />} 
              label="Create User Journey" 
              onClick={handleCreateUserJourney} 
            />
            <ActionButton 
              icon={<LightBulbIcon />} 
              label="Create Insight" 
              onClick={handleCreateInsight} 
            />
            <ActionButton 
              icon={<ChartBarIcon />} 
              label="Create UX Analysis" 
              onClick={handleCreateUXAnalysis} 
            />
            <ActionButton 
              icon={<ArchiveBoxIcon />} 
              label="Add to Database" 
              onClick={handleAddToDatabase} 
            />
          </div>
        </div>
        
        <div className="relative max-w-5xl mx-auto">
          <textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Send a message..."
            className="flex w-full border border-input px-3 py-2 text-base 
              ring-offset-background placeholder:text-muted-foreground 
              focus-visible:outline-none focus-visible:ring-2 
              focus-visible:ring-primary-500 focus-visible:ring-offset-2 
              disabled:cursor-not-allowed disabled:opacity-50 
              md:text-sm min-h-[24px] max-h-[calc(75dvh)] 
              overflow-hidden resize-none rounded-2xl 
              bg-muted pb-10 dark:border-zinc-700"
            rows="2"
            autoFocus
            style={{
              minHeight: '44px',
              maxHeight: '200px'
            }}
          />
          <div className="absolute bottom-0 right-0 p-2 w-fit flex flex-row justify-end">
            <button
              onClick={handleSendMessage}
              disabled={!inputValue.trim() || isLoading}
              className="inline-flex items-center justify-center gap-2 
                whitespace-nowrap text-sm font-medium ring-offset-background 
                transition-colors focus-visible:outline-none focus-visible:ring-2 
                focus-visible:ring-ring focus-visible:ring-offset-2 
                disabled:pointer-events-none disabled:opacity-50 
                [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 
                bg-primary text-primary-foreground hover:bg-primary/90 
                rounded-full p-1.5 h-fit border dark:border-zinc-600"
            >
              <PaperAirplaneIcon className="h-3.5 w-3.5" />
            </button>
          </div>
        </div>
      </div>
      
      <SourceDrawer 
        open={drawerOpen}
        setOpen={setDrawerOpen}
        source={selectedSource}
        citation={selectedCitation}
      />
      
      {/* Export Sidebar */}
      <div className={`fixed top-0 right-0 h-full bg-white w-80 shadow-xl transform transition-transform duration-300 ${showExportSidebar ? 'translate-x-0' : 'translate-x-full'} overflow-auto`}>
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="font-medium">Saved Content</h3>
          <button 
            onClick={() => setShowExportSidebar(false)}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        
        <div className="p-4 space-y-4">
          {exportedMessages.length === 0 ? (
            <p className="text-gray-500 text-sm">No saved content yet. Click the "Save" button on any message to add it here.</p>
          ) : (
            exportedMessages.map((msg, idx) => (
              <div key={idx} className="p-3 bg-gray-50 rounded-lg text-sm">
                <div className="mb-2 flex justify-between">
                  <span className="text-xs text-gray-500">
                    {new Date(msg.timestamp).toLocaleString()}
                  </span>
                  <button 
                    onClick={() => handleDownloadMessage(msg)}
                    className="text-xs text-blue-600 hover:underline"
                  >
                    Download
                  </button>
                </div>
                <div className="line-clamp-3">
                  {msg.content.substring(0, 150)}...
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      
      {/* Floating button to show sidebar */}
      {exportedMessages.length > 0 && !showExportSidebar && (
        <button
          onClick={() => setShowExportSidebar(true)}
          className="fixed bottom-20 right-5 bg-white shadow-lg rounded-full p-3"
        >
          <span className="sr-only">Show saved content</span>
          <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {exportedMessages.length}
          </span>
          <DocumentDuplicateIcon className="h-5 w-5 text-gray-600" />
        </button>
      )}
    </div>
  );
};

export default SingleChat; 