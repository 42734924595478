import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DIRECTUS_DATASETS_ENDPOINT, DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_FILES_ENDPOINT, DIRECTUS_INSTANCE, getVectorStoreId } from '../../api';
import { CloudArrowUpIcon, PlusIcon } from '@heroicons/react/24/outline';

const CreateDataSet = () => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetType, setDatasetType] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = "Create Dataset";
  const description = "";

  const ALLOWED_FILE_TYPES = [
    'text/plain',                                         // .txt files
    'text/csv',                                          // .csv files
    'application/pdf',                                   // .pdf files
    'application/msword',                                // .doc files
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx files
  ];

  useEffect(() => {
    const validateToken = async () => {
      try {
        await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
      }
    };
    validateToken();
  }, [token]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const userId = localStorage.getItem('user_id');
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: { owner: userId }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        setError('Failed to fetch projects. ' + (error.response?.data?.message || error.message));
      }
    };

    fetchProjects();
  }, [token]);

  const handleDrop = (acceptedFiles) => {
    const invalidFiles = acceptedFiles.filter(file => !ALLOWED_FILE_TYPES.includes(file.type));
    
    if (invalidFiles.length > 0) {
      setError('Only text files (TXT, CSV, PDF, DOC, DOCX) are allowed.');
      return;
    }
    
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setError(''); // Clear any previous errors
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop: handleDrop,
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: true
  });

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
    if (event.target.value === 'new-project') {
      navigate('/projects/create-project');
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!datasetName) {
      errors.datasetName = "Dataset name is required";
    }
    if (!selectedProjectId || selectedProjectId === "Select Project") {
      errors.selectedProjectId = "A related project must be selected";
    }
    if (files.length === 0) {
      errors.files = "At least one file must be uploaded";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError('');

    try {
      const fileIds = [];
      const vectorStoreFileIds = [];
      const vectorStoreId = await getVectorStoreId();

      // Step 1: Upload all files to Directus and OpenAI
      for (const file of files) {
        // Upload to Directus
        const fileFormData = new FormData();
        fileFormData.append('file', file);
        fileFormData.append('folder', 'a449f44e-926b-4940-9f46-0f5017223089');

        const fileResponse = await axios.post(DIRECTUS_FILES_ENDPOINT, fileFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        const directusFileId = fileResponse.data.data.id;
        fileIds.push(directusFileId);

        // Upload to OpenAI
        const openAIFormData = new FormData();
        openAIFormData.append('file', file);
        openAIFormData.append('purpose', 'assistants');

        const openAIFileResponse = await axios.post(
          'https://api.openai.com/v1/files',
          openAIFormData,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'multipart/form-data',
            }
          }
        );

        // Collect OpenAI file IDs for batch processing
        vectorStoreFileIds.push(openAIFileResponse.data.id);
      }

      // Step 2: Create batch in vector store
      const batchResponse = await axios.post(
        `https://api.openai.com/v1/vector_stores/${vectorStoreId}/file_batches`,
        {
          file_ids: vectorStoreFileIds,
          chunking_strategy: {
            type: 'static',
            static: {
              max_chunk_size_tokens: 800,
              chunk_overlap_tokens: 400
            }
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      // Step 3: Create the dataset with multiple files
      const datasetData = {
        name: datasetName,
        type: datasetType,
        project: selectedProjectId,
        owner: userId,
        file: fileIds.length > 0 ? {
          create: fileIds.map(id => ({ directus_files_id: id })),
          delete: []
        } : undefined,
        vector_store_batch_id: batchResponse.data.id
      };

      const datasetResponse = await axios.post(DIRECTUS_DATASETS_ENDPOINT, datasetData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      navigate('/datasets');
    } catch (error) {
      console.error('Error:', error.response?.data || error);
      setError('Failed to create dataset: ' + (error.response?.data?.errors?.[0]?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 lg:pl-72">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900 mt-8">Create Dataset</h1>
          <p className="mt-2 text-sm text-gray-700">
            Create a new dataset by uploading files and associating with a project.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className="block px-3 py-2 text-center text-sm font-semibold text-white bg-primary-500 rounded-md shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            {isLoading ? 'Creating...' : 'Create Dataset'}
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="dataset-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Dataset Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="dataset-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                      placeholder="i.e. My Amazing Dataset"
                      id="dataset-name"
                      value={datasetName}
                      onChange={(e) => setDatasetName(e.target.value)}
                      required
                    />
                  </div>
                  {formErrors.datasetName && <span className="text-red-500 text-sm">{formErrors.datasetName}</span>}
                </div>

                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Data Type</label>
                  <select
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    id="dataset-type"
                    value={datasetType}
                    onChange={(e) => setDatasetType(e.target.value)}
                  >
                    <option value="user_interview_transcript">User Interview Transcript</option>
                    <option value="product_brochure">Product Brochure</option>
                    <option value="blog_post_article">Article or Blogpost Sample</option>
                    <option value="database">Database</option>
                  </select>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="project-select" className="block text-sm font-medium leading-6 text-gray-900">
                    Related Project
                  </label>
                  <div className="mt-2">
                    <select 
                      required 
                      name="project" 
                      id="project-select" 
                      onChange={handleProjectChange} 
                      value={selectedProjectId}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    >
                      <option>Select Project</option>
                      {projects.map((project) => (
                        <option key={project.id} value={project.id}>{project.project_name}</option>
                      ))}
                      <option value="new-project">Create New Project</option>
                    </select>
                  </div>
                  {formErrors.selectedProjectId && <span className="text-red-500 text-sm">{formErrors.selectedProjectId}</span>}
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="files" className="block text-sm font-medium leading-6 text-gray-900">
                    Drag & Drop Files
                  </label>
                  <div
                    {...getRootProps()}
                    className={`mt-2 border-dashed border-2 border-gray-300 rounded-lg p-6 text-center cursor-pointer ${
                      isDragActive ? 'bg-gray-50' : ''
                    }`}
                  >
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600 justify-center">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500"
                        >
                          <span>Upload a file</span>
                          <input {...getInputProps()} />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">Upload multiple TXT, CSV, PDF, DOC, or DOCX files up to 10MB each</p>
                    </div>
                  </div>
                  <div className="mt-2">
                    {files.length > 0 && (
                      <div>
                        <h4 className="text-sm font-medium text-gray-900">Selected Files</h4>
                        <ul className="mt-1 divide-y divide-gray-100 rounded-md border border-gray-200">
                          {files.map((file, index) => (
                            <li key={index} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                              <div className="flex w-0 flex-1 items-center">
                                <span className="ml-2 w-0 flex-1 truncate">{file.name}</span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <button
                                  type="button"
                                  className="font-medium text-primary-600 hover:text-primary-500"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  Remove
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {formErrors.files && <span className="text-red-500 text-sm">{formErrors.files}</span>}
                  </div>
                </div>
              </div>

              {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDataSet;