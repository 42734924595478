import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DIRECTUS_INSTANCE } from '../../api';
import axios from 'axios';
import gradientBg from '../../img/gradient.png';
import {
  DocumentPlusIcon, UsersIcon, MapIcon, ChartBarIcon,
  DocumentIcon, UserIcon, MapPinIcon, ChartPieIcon,
  ChatBubbleLeftRightIcon, FolderPlusIcon
} from '@heroicons/react/24/outline';

const Dashboard = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(() => localStorage.getItem('username') || '');
  const [recentItems, setRecentItems] = useState([]);

  // Fetch user profile data
  const { data: userProfile } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('directus_token')}`,
        },
      });
      return response.data.data;
    },
    onSuccess: (data) => {
      setName(data.first_name);
      localStorage.setItem('username', data.first_name);
    }
  });

  // Fetch recent items from multiple collections
  const { data: recentItemsData, isLoading: isLoadingRecent } = useQuery({
    queryKey: ['recentItems'],
    queryFn: async () => {
      const token = localStorage.getItem('directus_token');
      const userId = localStorage.getItem('user_id');

      if (!token || !userId) {
        console.log('Missing token or userId');
        return [];
      }

      try {
        console.log('Fetching items for user:', userId);

        // Create a function to safely fetch from each endpoint
        const safeFetch = async (endpoint, label) => {
          try {
            const response = await axios.get(`${DIRECTUS_INSTANCE}/items/${endpoint}`, {
              headers: { Authorization: `Bearer ${token}` },
              params: {
                sort: '-date_created',
                limit: 5,
                filter: {
                  user_created: { _eq: userId }
                }
              }
            });
            console.log(`Successfully fetched ${label}:`, response.data.data.length);
            return response.data.data || [];
          } catch (error) {
            console.log(`Error fetching ${label}:`, error.message);
            return [];
          }
        };

        // Fetch all collections in parallel
        const [projects, personas, journeys, heatmaps, insights] = await Promise.all([
          safeFetch('projects', 'projects'),
          safeFetch('personas', 'personas'),
          safeFetch('user_journeys', 'journeys'),
          safeFetch('heatmaps', 'heatmaps'),
          safeFetch('insights', 'insights')
        ]);

        // Process all items with simple mapping
        const processedProjects = projects.map(item => ({
          id: item.id,
          title: item.project_name || 'Untitled Project',
          desc: item.description || item.short_description || 'Project',
          type: 'project',
          path: `/projects/${item.id}`,
          lastEdited: formatTimeAgo(item.date_updated || item.date_created),
          date: new Date(item.date_created),
          thumbnail: null
        }));

        const processedPersonas = personas.map(item => ({
          id: item.id,
          title: item.name || 'Unnamed Persona',
          desc: item.occupation || 'Persona',
          type: 'persona',
          path: `/personas/${item.id}`,
          lastEdited: formatTimeAgo(item.date_updated || item.date_created),
          date: new Date(item.date_created),
          thumbnail: item.avatar?.id || null
        }));

        const processedJourneys = journeys.map(item => ({
          id: item.id,
          title: item.user_journey_name || 'Untitled Journey',
          desc: item.description || 'User Journey Map',
          type: 'journey',
          path: `/user-journeys/${item.id}`,
          lastEdited: formatTimeAgo(item.date_updated || item.date_created),
          date: new Date(item.date_created),
          thumbnail: null
        }));

        const processedHeatmaps = heatmaps.map(item => ({
          id: item.id,
          title: item.title || 'Untitled Heatmap',
          desc: 'UX Analysis',
          type: 'heatmap',
          path: `/predictions/${item.id}`,
          lastEdited: formatTimeAgo(item.date_updated || item.date_created),
          date: new Date(item.date_created),
          thumbnail: item.screenshot?.id || null
        }));

        const processedInsights = insights.map(item => ({
          id: item.id,
          title: item.title || `${item.insight_type === 'pain' ? 'Pain Point' : 'Success Point'}`,
          desc: item.description || 'Insight',
          type: 'insight',
          path: `/insights/${item.id}`,
          lastEdited: formatTimeAgo(item.date_updated || item.date_created),
          date: new Date(item.date_created),
          thumbnail: null
        }));

        // Combine all items
        const allItems = [
          ...processedProjects,
          ...processedPersonas,
          ...processedJourneys,
          ...processedHeatmaps,
          ...processedInsights
        ];

        // Sort by date and get the 4 most recent items from all types combined
        const sortedItems = allItems
          .filter(item => item.date instanceof Date && !isNaN(item.date))
          .sort((a, b) => b.date - a.date)
          .slice(0, 4);

        console.log('Items by type count:', {
          projects: processedProjects.length,
          personas: processedPersonas.length,
          journeys: processedJourneys.length,
          heatmaps: processedHeatmaps.length,
          insights: processedInsights.length
        });
        console.log('Final mixed items count:', sortedItems.length);
        console.log('Mixed items types:', sortedItems.map(item => item.type));

        return sortedItems;
      } catch (error) {
        console.error('Error in main fetch function:', error);
        return [];
      }
    },
  });

  // Use effect to update the state when the query data changes
  useEffect(() => {
    if (recentItemsData) {
      console.log('Setting recent items from effect:', recentItemsData);
      setRecentItems(recentItemsData);
    }
  }, [recentItemsData]);

  // Move formatTimeAgo function outside of the query function
  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return 'Never edited';

    const date = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  const quickActions = [
    {
      title: 'Create New Project Canvas',
      desc: 'Start a new research project',
      path: '/projects/create-project',
      icon: DocumentPlusIcon
    },
    {
      title: 'Create Personas',
      desc: 'Define your target users',
      path: '/personas/create-persona',
      icon: UsersIcon
    },
    {
      title: 'User Journey Map',
      desc: 'Map out user experiences',
      path: '/user-journeys/create',
      icon: MapIcon
    },
    {
      title: 'UX Analysis',
      desc: 'Analyze user behavior',
      path: '/predictions/create-heatmap',
      icon: ChartBarIcon
    },
    {
      title: 'Talk with UX AI Assistant',
      desc: 'Get help with your UX research',
      path: '/assistant',
      icon: ChatBubbleLeftRightIcon
    },
    {
      title: 'Add Files to database',
      desc: 'Upload and manage research files',
      path: '/files/upload',
      icon: FolderPlusIcon
    }
  ];

  const dailyQuotes = [
    "Design is not just what it looks like and feels like. Design is how it works. - Steve Jobs",
    "If I had asked people what they wanted, they would have said faster horses. - Henry Ford",
    "Make it simple, but significant. - Don Draper",
    "Good design is obvious. Great design is transparent. - Joe Sparano",
    "Technology is nothing. What's important is that you have faith in people. - Steve Jobs",
    "The details are not the details. They make the design. - Charles Eames",
    "Design is intelligence made visible. - Alina Wheeler",
    "Innovation distinguishes between a leader and a follower. - Steve Jobs",
    "Simplicity is the ultimate sophistication. - Leonardo da Vinci",
    "Design is not for philosophy, it's for life. - Issey Miyake",
    "The best way to predict the future is to create it. - Peter Drucker",
    "Good design is good business. - Thomas Watson Jr., IBM",
    "Everything is designed. Few things are designed well. - Brian Reed",
    "Design creates culture. Culture shapes values. Values determine the future. - Robert L. Peters",
    "The user experience design of a product essentially lies between art and science. - Alok Nath",
    "A user interface is like a joke. If you have to explain it, it's not that good. - Martin LeBlanc",
    "Design is where science and art break even. - Robin Mathew",
    "The best ideas come as jokes. Make your thinking as funny as possible. - David Ogilvy",
    "Innovation is saying no to 1,000 things. - Steve Jobs",
    "Design is thinking made visual. - Saul Bass"
  ];

  const getTodayQuote = () => {
    const day = new Date().getDate();
    return dailyQuotes[day % dailyQuotes.length];
  };

  const getIconForType = (type) => {
    switch (type) {
      case 'project':
        return DocumentIcon;
      case 'persona':
        return UserIcon;
      case 'journey':
        return MapPinIcon;
      case 'heatmap':
        return ChartPieIcon;
      case 'insight':
        return ChartBarIcon;
      default:
        return DocumentIcon;
    }
  };

  // Add this before the return statement to check what's in recentItems
  useEffect(() => {
    console.log('Recent items for rendering:', recentItems);
  }, [recentItems]);

  return (
    <div className="pt-0 bg-white ">
      <div className=" flex flex-col gap-0.5 ">
        {/* Welcome Section */}
        <div className="p-11" style={{ backgroundImage: `url(${gradientBg})` }}>
          <div className="max-w-7xl mx-auto pl-8">
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl text-gray-900 leading-9">Welcome {userProfile?.first_name || name}</h1>
              <p className="text-base text-gray-900">{getTodayQuote()}</p>
            </div>
          </div>
        </div>
        <div>

          {/* Quick Actions Section */}
          <div className="p-8 bg-white py-16 max-w-7xl mx-auto pl-8">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <h2 className="text-2xl text-gray-900 leading-loose">What would you like do today?</h2>
                <p className="text-base  text-gray-600">Design a study tailored to your research goals</p>
              </div>

              <div className="grid grid-cols-3 gap-4">
                {quickActions.map((item) => (
                  <div
                    key={item.title}
                    className="p-4 bg-white rounded-lg border border-neutral-200 cursor-pointer hover:border-primary-500 transition-colors"
                    onClick={() => navigate(item.path)}
                  >
                    <div className="flex items-center gap-4">
                      <div className="w-10 h-10 flex items-center justify-center">
                        <item.icon className="w-6 h-6 text-gray-600" />
                      </div>
                      <div className="flex flex-col gap-px">
                        <div className="text-base text-gray-900">{item.title}</div>
                        <div className="text-sm text-gray-600">{item.desc}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Latest Created Section */}
          <div className="p-8 pb-32 max-w-7xl mx-auto pl-8">
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-2xl text-gray-900 leading-loose">Latest Created</h2>
                  <p className="text-base text-gray-600">Your most recent research activities</p>
                </div>
                <div
                  className="flex flex-col cursor-pointer"
                  onClick={() => navigate('/projects')}
                >
      
                  <div className="h-0.5 bg-primary-500"></div>
                </div>
              </div>

              <div className="flex gap-3.5">
                {isLoadingRecent ? (
                  <div className="w-full text-center py-8">Loading recent activities...</div>
                ) : recentItems.length > 0 ? (
                  recentItems.map((item) => {
                    const ItemIcon = getIconForType(item.type);
                    return (
                      <div
                        key={`${item.type}-${item.id}`}
                        className="flex-1 p-6 rounded-xl border border-neutral-200 cursor-pointer hover:border-primary-500 transition-colors"
                        onClick={() => navigate(item.path)}
                      >
                        <div className="flex flex-col gap-3">
                          <div className={`bg-gray-100 p-5 rounded overflow-hidden h-24 flex items-center justify-center relative`}>
                            {item.thumbnail ? (
                              <img
                                src={`${DIRECTUS_INSTANCE}/assets/${item.thumbnail}`}
                                alt={item.title}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <ItemIcon className="w-10 h-10 text-gray-400" />
                            )}
                            <span className="absolute top-2 right-2 bg-white px-2 py-1 rounded text-xs capitalize">
                              {item.type}
                            </span>
                          </div>
                          <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2">
                              <h3 className="text-base text-gray-900">{item.title}</h3>
                              <p className="text-xs text-gray-900 line-clamp-2">{item.desc}</p>
                            </div>
                            <span className="text-sm text-gray-900">Last edited {item.lastEdited}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="w-full text-center py-8 text-gray-500">
                    No recent activities found. Start creating new projects!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
