import React, { Fragment, useState } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import Confetti from 'react-confetti';

const NotificationBell = () => {
  const [notifications] = useState([
    {
      id: 1,
      title: 'New feature available',
      description: 'Check out our latest updates',
      time: '5m ago',
      unread: true,
    },
  ]);

  const [onboardingTasks, setOnboardingTasks] = useState([
    { id: 1, title: 'Create New Project', completed: false },
    { id: 2, title: 'Create at least 1 personas', completed: false },
    { id: 3, title: 'Define User Journey Map', completed: false },
    { id: 4, title: 'Create UX Analysis', completed: false },
    { id: 5, title: 'Collect Insights', completed: false },
    { id: 6, title: 'Improve your product that people love ❤️', completed: false },
  ]);

  const toggleTask = (taskId, e) => {
    // Prevent the Menu from closing
    e.preventDefault();
    e.stopPropagation();
    
    setOnboardingTasks(tasks => 
      tasks.map(task => 
        task.id === taskId ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const allTasksCompleted = onboardingTasks.every(task => task.completed);

  return (
    <>
      {allTasksCompleted && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <Menu as="div" className="relative">
        <Menu.Button className="pt-4 text-gray-400 hover:text-gray-500">
          <span className="sr-only">View notifications</span>
          <div className="relative">
            <BellIcon className="h-6 w-6" aria-hidden="true" />
            {(notifications.some(n => n.unread) || onboardingTasks.some(t => !t.completed)) && (
              <div className="absolute top-0 right-0 -mt-1 -mr-1 h-2 w-2 rounded-full bg-red-500" />
            )}
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="border-b border-gray-100">
              <div className="px-4 py-2">
                <h3 className="text-sm font-medium text-gray-900">Notifications & Tasks</h3>
              </div>
            </div>

            <div className="max-h-96 overflow-y-auto">
              {[
                ...notifications.map(notification => ({
                  ...notification,
                  type: 'notification'
                })),
                ...onboardingTasks.map(task => ({
                  ...task,
                  type: 'task'
                }))
              ].map((item) => (
                <Menu.Item key={`${item.type}-${item.id}`}>
                  {({ active }) => (
                    item.type === 'notification' ? (
                      <div
                        className={`${
                          active ? 'bg-gray-50' : ''
                        } px-4 py-3 cursor-pointer`}
                      >
                        <div className="flex items-start">
                          <div className="flex-1 min-w-0">
                            <p className={`text-sm font-medium text-gray-900 ${
                              item.unread ? 'font-semibold' : ''
                            }`}>
                              {item.title}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p>
                            <p className="mt-1 text-xs text-gray-400">
                              {item.time}
                            </p>
                          </div>
                          {item.unread && (
                            <div className="ml-3 flex-shrink-0">
                              <div className="h-2 w-2 rounded-full bg-blue-600" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${
                          active ? 'bg-gray-50' : ''
                        } px-4 py-3 cursor-pointer flex items-center`}
                      >
                        <div 
                          className="flex-shrink-0 mr-3"
                          onClick={(e) => toggleTask(item.id, e)}
                        >
                          {item.completed ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-green-500">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-gray-400">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          )}
                        </div>
                        <span className={`text-sm ${item.completed ? 'text-gray-500 line-through' : 'text-gray-900'}`}>
                          {item.title}
                        </span>
                      </div>
                    )
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default NotificationBell;
