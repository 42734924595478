import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

const ContentHeader = ({ 
  title, 
  description, 
  primaryAction, 
  onPrimaryAction,
  primaryIcon,
  showWhenEmpty = true 
}) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-3xl font-medium leading-6 text-gray-900 mt-0">{title}</h1>
        {description && (
          <p className="mt-4 text-base text-gray-600">
            {description}
          </p>
        )}
      </div>
      {primaryAction && (
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={onPrimaryAction}
            className="flex rounded-full bg-primary-500 pl-4 pr-6 pt-2 pb-2 text-center text-sm font-regular text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            {primaryIcon || <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
            {primaryAction}
          </button>
        </div>
      )}
    </div>
  );
};

export default ContentHeader;