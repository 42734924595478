// Konfiguracja asystenta OpenAI
// Poniższe ustawienia bazują na projekcie ai_writer

// Podstawowe ID dla asystenta
const DEFAULT_ASSISTANT_ID = process.env.REACT_APP_SEO_ASSISTANT_ID || 'asst_2zUbTUJRL3F1iuSPmxjKPwxn';

// Model używany przez asystenta
const DEFAULT_MODEL = 'gpt-4-turbo';

// Instrukcje dla asystenta
const ASSISTANT_INSTRUCTIONS = `
Jesteś AI Assistant, dostępny w aplikacji do tworzenia contentu. Twoim zadaniem jest pomaganie użytkownikom w tworzeniu,
optymalizowaniu i planowaniu treści marketingowych i SEO.

Podczas tworzenia treści zawsze:
1. Odpowiadaj konkretnie i na temat
2. Używaj jasnego, profesjonalnego języka
3. Zwracaj uwagę na kontekst i intencję pytania 
4. Proponuj praktyczne rozwiązania
5. Dostosuj ton odpowiedzi do kontekstu - formalny dla biznesowych zapytań, bardziej konwersacyjny dla ogólnych rozmów

Możesz pomagać w:
- Tworzeniu zarysów artykułów
- Sugerowaniu słów kluczowych
- Optymalizacji treści pod SEO
- Poprawianiu stylu i gramatyki
- Generowaniu pomysłów na treści

Jeśli pytanie wykracza poza obszar tworzenia treści marketingowych, SEO lub strategii contentowej, 
grzecznie wyjaśnij swoje ograniczenia i zaproponuj, jak możesz pomóc w ramach swoich kompetencji.
`;

// Narzędzia dostępne dla asystenta
const ASSISTANT_TOOLS = [
  {
    type: "code_interpreter"
  },
  {
    type: "file_search"
  },
  {
    type: "function",
    function: {
      name: "generate_seo_recommendations",
      description: "Generate SEO recommendations for a given content",
      parameters: {
        type: "object",
        properties: {
          content: {
            type: "string",
            description: "The content to analyze"
          },
          target_keywords: {
            type: "array",
            items: {
              type: "string"
            },
            description: "Target keywords to optimize for"
          }
        },
        required: ["content"]
      }
    }
  },
  {
    type: "function",
    function: {
      name: "analyze_readability",
      description: "Analyze the readability of content",
      parameters: {
        type: "object",
        properties: {
          content: {
            type: "string",
            description: "The content to analyze"
          }
        },
        required: ["content"]
      }
    }
  }
];

// Ustawienia wiadomości domyślnych/systemowych
const SYSTEM_MESSAGES = {
  welcome: "Hello! I'm your AI assistant. How can I help you today?",
  error: "Sorry, an error occurred. Please try again in a moment.",
  timeout: "The response is taking longer than usual. Please be patient..."
};

// Limity i parametry
const SETTINGS = {
  max_tokens_per_message: 4000,
  max_messages_in_thread: 100,
  default_temperature: 0.7,
  response_timeout_seconds: 60,
  vector_store_id: process.env.REACT_APP_PINECONE_INDEX || "reislo-1536"
};

// Konfiguracja numerowania wiadomości
const MESSAGE_ORDERING = {
  enable_numbering: true,  // Czy nadawać numer każdej wiadomości
  start_from: 1,         // Numeracja od 1
  sort_by_timestamp: true  // Sortowanie wiadomości według czasu
};

// Eksportujemy całą konfigurację
export {
  DEFAULT_ASSISTANT_ID,
  DEFAULT_MODEL,
  ASSISTANT_INSTRUCTIONS,
  ASSISTANT_TOOLS,
  SYSTEM_MESSAGES,
  SETTINGS,
  MESSAGE_ORDERING
}; 