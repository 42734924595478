import React, { useState } from 'react';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import reisloLogo from '../../img/logo-flat-color-1.svg';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import { UserIcon, EnvelopeIcon, KeyIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // State for first name
  const [lastName, setLastName] = useState(''); // State for last name
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // NEW: Loading state
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility


  const handleRegistration = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    // Debug logging for development environment
    const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const debugLog = (message, data = null) => {
      if (isDevelopment) {
        console.log(`🔍 [Registration Debug] ${message}`, data || '');
      }
    };

    debugLog('Registration attempt started', {
      email,
      firstName,
      lastName,
      // Don't log password for security
    });

    try {
      const registrationEndpoint = `${DIRECTUS_INSTANCE}/users`;
      debugLog('Sending request to endpoint:', registrationEndpoint);

      const registrationData = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        role: '795c4d4b-4258-4f76-a116-70a03cb46d1a',
        status: 'active',
        onboarding_completed: false
      };
      debugLog('Registration payload:', { ...registrationData, password: '[REDACTED]' });

      const response = await axios.post(registrationEndpoint, registrationData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      debugLog('Registration response received:', response);

      if (response.status === 200 && response.data.data) {
        debugLog('Registration successful, attempting login');

        const loginResponse = await axios.post(`${DIRECTUS_INSTANCE}/auth/login`, {
          email,
          password
        });

        debugLog('Login response received:', {
          status: loginResponse.status,
          hasData: !!loginResponse.data
        });

        if (loginResponse.data.data) {
          const token = loginResponse.data.data.access_token;
          const userId = response.data.data.id;
          
          debugLog('Login successful, storing data');
          
          // Store necessary data
          localStorage.setItem('directus_token', token);
          localStorage.setItem('user_id', userId);
          localStorage.setItem('username', `${firstName} ${lastName}`);

          debugLog('Redirecting to onboarding');
          window.location.href = '/onboarding';
        }
      } else {
        debugLog('Registration failed - unexpected response:', response);
        setError('Registration failed. Please try again.');
      }
    } catch (error) {
      debugLog('Registration error occurred:', {
        message: error.message,
        response: error.response,
        stack: error.stack
      });

      const errorMessage = error.response?.data?.errors?.[0]?.message || 'Unknown error occurred.';
      setError(`Registration failed. ${errorMessage}`);
    } finally {
      setLoading(false);
      debugLog('Registration process completed');
    }
  };


  return (
    <div className="flex h-dvh flex-grow">
      <div className="flex flex-grow flex-col">
        {/* Logo */}
        <img
          className="h-8 absolute top-8 left-16 z-50"
          src={reisloLogo}
          alt="Reislo logo"
        />
        
        <div className="flex flex-grow flex-row">
          <div className="flex flex-grow flex-row">
            <div className="relative flex w-full justify-center p-10">
              <div className="relative flex max-w-3xl flex-col place-content-between">
                {/* Main Content */}
                <div className="space-y-6 pt-40">
                  {/* Header */}
                  <div className="space-y-1 pb-1">
                    <h1 className="text-2xl tracking-tight text-gray-900">
                      Welcome to Reislo!
                    </h1>
                    <p className="text-sm font-normal text-gray-500">
                      Sign up to start building personalized UX insights in minutes
                    </p>
                  </div>

                  {/* Google Sign Up Button */}
                  <div className="relative group">
                    <button className="flex w-full items-center justify-center gap-2 rounded border border-gray-300 bg-white px-[20px] py-[10px] text-sm font-semibold text-gray-900 hover:bg-gray-50 cursor-not-allowed opacity-80">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 48 48">
                        <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                        <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                        <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                        <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                      </svg>
                      <span>Sign up with Google</span>
                    </button>
                    <div className="absolute left-1/2 -translate-x-1/2 -bottom-8 hidden group-hover:block bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      Coming soon
                    </div>
                  </div>

                  {/* Microsoft Sign Up Button */}
                  <div className="relative group">
                    <button className="flex w-full items-center justify-center gap-2 rounded border border-gray-300 bg-white px-[20px] py-[10px] text-sm font-semibold text-gray-900 hover:bg-gray-50 cursor-not-allowed opacity-80">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 23 23">
                        <path fill="#f35325" d="M1 1h10v10H1z"/>
                        <path fill="#81bc06" d="M12 1h10v10H12z"/>
                        <path fill="#05a6f0" d="M1 12h10v10H1z"/>
                        <path fill="#ffba08" d="M12 12h10v10H12z"/>
                      </svg>
                      <span>Sign up with Microsoft</span>
                    </button>
                    <div className="absolute left-1/2 -translate-x-1/2 -bottom-8 hidden group-hover:block bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      Coming soon
                    </div>
                  </div>

                  {/* Divider */}
                  <div className="flex items-center gap-4">
                    <div className="h-px flex-1 bg-gray-200"></div>
                    <p className="text-xs text-gray-500">OR</p>
                    <div className="h-px flex-1 bg-gray-200"></div>
                  </div>

                  {/* Form */}
                  <form className="space-y-4" onSubmit={handleRegistration}>
                    <div className="space-y-3">
                      {/* Name Inputs */}
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="First name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-3 px-3 text-sm text-gray-900 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>
                      
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-3 px-3 text-sm text-gray-900 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>

                      {/* Email Input */}
                      <div className="relative">
                        <input
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-3 px-3 text-sm text-gray-900 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                      </div>

                      {/* Password Input */}
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="block w-full rounded-md border border-gray-300 bg-white py-3 px-3 text-sm text-gray-900 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                        >
                          {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>

                    {/* Error and Success Messages */}
                    {error && <p className="text-sm text-red-600">{error}</p>}
                    {success && <p className="text-sm text-green-600">{success}</p>}

                    {/* Submit Button */}
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full rounded-md bg-primary-500 py-2 text-sm font-semibold text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50"
                    >
                      {loading ? 'Registering...' : 'Continue'}
                    </button>

                    {/* Login Link */}
                    <p className="text-xs font-medium text-gray-500">
                      Already have an account?{" "}
                      <a href="/login" className="text-primary-500 hover:text-primary-600">
                        Log in
                      </a>
                    </p>
                  </form>
                </div>

                {/* Terms and Privacy */}
                <div>
                  <p className="text-xs font-medium text-gray-500">
                    By signing up for a Reislo account, you agree to our{" "}
                    <a href="/privacy" className="text-gray-900 hover:text-gray-700">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="/terms" className="text-gray-900 hover:text-gray-700">
                      Terms of Service
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side Banner */}
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover object-right"
              src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt="Sign In Banner"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
