import React, { useState, Fragment, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EllipsisVerticalIcon, InformationCircleIcon, XMarkIcon, PlusIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { Menu, Dialog, Transition } from '@headlessui/react';
import PostItEditor from './PostItEditor';

// Function to parse citations
const parseCitations = (text) => {
  return text.replace(/【(\d+):(\d+)†source】/g, (match, source, number) => {
    return `[${number}]`;
  });
};

// Function to extract citations
const extractCitations = (text) => {
  const citations = [];
  const regex = /【(\d+):(\d+)†source】/g;
  let match;

  while ((match = regex.exec(text)) !== null) {
    citations.push({
      source: match[1],
      number: match[2],
      fullMatch: match[0]
    });
  }

  return citations;
};

const InsightDrawer = ({ isOpen, onClose, insight, color }) => {
  const citations = extractCitations(insight?.text || '');
  
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                    <div className="px-4 py-6 sm:px-6 border-b border-gray-200">
                      <div className="flex items-start justify-between">
                        <h2 className="text-lg font-medium text-gray-900">
                          Insight Details
                        </h2>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                      <div className="space-y-6">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">Content</h3>
                          <div 
                            className="mt-2 p-4"
                            style={{
                              background: color === '#fbbf24' ? '#fef3c7' : color === '#86efac' ? '#dcfce7' : '#fee2e2',
                            }}
                          >
                            <p className="text-sm text-gray-900 whitespace-pre-wrap">
                              {parseCitations(insight?.text || '')}
                            </p>
                          </div>
                        </div>

                        {citations.length > 0 && (
                          <div>
                            <h3 className="text-sm font-medium text-gray-900 mb-2">Sources</h3>
                            <p className="text-sm text-gray-500 mb-4">Below is a list of research sources referenced in this insight:</p>
                            <div className="space-y-3">
                              {citations.map((citation, index) => (
                                <div 
                                  key={index}
                                  className="flex items-start space-x-3 p-3 bg-gray-50 rounded-sm"
                                >
                                  <span className="flex-shrink-0 text-sm font-medium text-gray-500">
                                    [{citation.number}]
                                  </span>
                                  <div className="flex-1">
                                    <p className="text-sm text-gray-900">
                                      Source {citation.source}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

function SortableInsight({ id, text, color, onOpenDrawer, onEdit, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ 
    id,
    animateLayoutChanges: () => true,
    disabled: isEditing // Disable drag when editing
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || 'transform 200ms cubic-bezier(0.25, 1, 0.5, 1)',
    opacity: isDragging ? 0.5 : undefined,
    zIndex: isDragging ? 2 : 1,
  };

  const handleSave = (newText) => {
    onEdit(id, newText);
    setIsEditing(false);
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="group relative"
      {...(!isEditing ? attributes : {})} // Only apply drag attributes when not editing
      {...(!isEditing ? listeners : {})} // Only apply drag listeners when not editing
    >
      <PostItEditor
        initialText={text}
        color={color}
        isEditing={isEditing}
        onSave={handleSave}
        onDelete={() => onDelete(id)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div className="absolute bottom-2 right-2 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <button 
          className="p-1.5 hover:bg-black hover:bg-opacity-5 rounded"
          onClick={(e) => {
            e.stopPropagation();
            onOpenDrawer({ id, text });
          }}
        >
          <InformationCircleIcon className="h-5 w-5 text-gray-500" />
        </button>
        <Menu as="div" className="relative">
          {({ open }) => (
            <>
              <Menu.Button className={`p-1.5 hover:bg-black hover:bg-opacity-5 rounded ${open ? 'bg-black bg-opacity-5' : ''}`}>
                <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
              </Menu.Button>
              <Menu.Items 
                className="absolute right-0 bottom-full mb-1 w-36 bg-white shadow-lg py-1 z-[60]"
                style={{
                  filter: 'drop-shadow(0 0 4px rgba(0,0,0,0.1))'
                }}
              >
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm text-gray-700 w-full text-left`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-red-50' : ''
                      } block px-4 py-2 text-sm text-red-600 w-full text-left`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(id);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

const InsightGrid = ({ title, insights, onInsightsChange, color }) => {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Initialize items when insights change
  useEffect(() => {
    if (insights?.blocks) {
      const insightItems = insights.blocks
        .filter(block => block.type === 'paragraph' && block.data.text.trim() !== '')
        .map((block, index) => ({
          id: `${title.toLowerCase()}-${index}`,
          text: block.data.text
        }));
      setItems(insightItems);
    }
  }, [insights, title]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);

      // Update the parent component
      if (onInsightsChange) {
        const updatedBlocks = newItems.map(item => ({
          type: 'paragraph',
          data: { text: item.text }
        }));
        onInsightsChange(updatedBlocks);
      }
    }
  };

  const handleOpenDrawer = (insight) => {
    setSelectedInsight(insight);
    setIsDrawerOpen(true);
  };

  const handleEdit = (id, newText) => {
    const updatedItems = items.map(item => 
      item.id === id ? { ...item, text: newText } : item
    );
    setItems(updatedItems);

    // Update the parent component
    if (onInsightsChange) {
      const updatedBlocks = updatedItems.map(item => ({
        type: 'paragraph',
        data: { text: item.text }
      }));
      onInsightsChange(updatedBlocks);
    }
  };

  const handleDelete = (id) => {
    const updatedItems = items.filter(item => item.id !== id);
    setItems(updatedItems);

    // Update the parent component
    if (onInsightsChange) {
      const updatedBlocks = updatedItems.map(item => ({
        type: 'paragraph',
        data: { text: item.text }
      }));
      onInsightsChange(updatedBlocks);
    }
  };

  return (
    <div className="bg-white border border-secondary-100 p-6 mb-6">
      <h2 className="text-2xl font-medium mb-6">{title}</h2>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className="grid grid-cols-4 gap-4">
          <SortableContext 
            items={items.map(item => item.id)} 
            strategy={rectSortingStrategy}
          >
            {items.map((item) => (
              <SortableInsight
                key={item.id}
                id={item.id}
                text={item.text}
                color={color}
                onOpenDrawer={handleOpenDrawer}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </SortableContext>
        </div>

        <DragOverlay>
          {activeId ? (
            <div 
              className="p-4 shadow-sm opacity-80"
              style={{
                transform: 'scale(1.05)',
                transition: 'transform 200ms cubic-bezier(0.25, 1, 0.5, 1)',
                background: color === '#fbbf24' ? '#fef3c7' : color === '#86efac' ? '#dcfce7' : '#fee2e2',
                height: '200px',
                width: '300px',
              }}
            >
              <div className="h-full flex flex-col">
                <div className="flex-1 overflow-hidden">
                  <p className="text-gray-800 text-sm leading-relaxed line-clamp-7">
                    {items.find(item => item.id === activeId)?.text}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>

      <InsightDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedInsight(null);
        }}
        insight={selectedInsight}
        color={color}
      />
    </div>
  );
};

export default InsightGrid; 