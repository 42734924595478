import React, { useState, useEffect, useCallback } from 'react';
import { listOpenAIFiles, getVectorStoreId } from '../../api';
import { format } from 'date-fns';
import ContentHeader from '../Atoms/contentHeader';
import EmptyState from '../Atoms/emptyState';
import { DocumentIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import datasetsPlaceholder from '../../img/placeholders/files.png';

const title = 'My Files';
const description = 'List of all files uploaded to prose captain that can be used with Assistants and Content Creator.';
const primaryAction = 'Add File';

const SkeletonRow = React.memo(() => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
      <div className="h-8 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/2"></div>
    </td>
  </tr>
));

function DatasetsTable() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({
    vectorStoreId: null,
    assistantId: null,
    vectorStoreFiles: []
  });
  const navigate = useNavigate();
  const userId = localStorage.getItem('user_id');
  const isLocalhost = window.location.hostname === 'localhost';

  const formatFileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const formatDate = (timestamp) => {
    return format(new Date(timestamp * 1000), 'PPP');
  };

  const fetchFiles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await listOpenAIFiles();
      setFiles(response.data);

      // Debug info
      if (isLocalhost) {
        try {
          const vectorStoreId = await getVectorStoreId();
          setDebugInfo(prev => ({ ...prev, vectorStoreId }));

          // Get vector store files
          const vectorFilesResponse = await axios.get(
            `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files`,
            {
              headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                'OpenAI-Beta': 'assistants=v2'
              }
            }
          );

          setDebugInfo(prev => ({ ...prev, vectorStoreFiles: vectorFilesResponse.data.data }));
        } catch (error) {
          console.error("Error fetching debug info:", error);
        }
      }
    } catch (err) {
      setError('Failed to load files');
      console.error('Error fetching files:', err);
    } finally {
      setLoading(false);
    }
  }, [isLocalhost]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const renderDebugInfo = () => {
    if (!isLocalhost) return null;

    return (
      <div className="mt-8 p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900">Debug Information</h3>
        <div className="mt-2 space-y-2">
          <p><strong>Vector Store ID:</strong> {debugInfo.vectorStoreId}</p>
          <div>
            <strong>Vector Store Files:</strong>
            {debugInfo.vectorStoreFiles.length > 0 ? (
              <ul className="ml-4 list-disc">
                {debugInfo.vectorStoreFiles.map((file, index) => (
                  <li key={index}>{file.id} - {file.object}</li>
                ))}
              </ul>
            ) : (
              <p className="ml-4">No vector store files found</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-16">
      <div className="mx-auto max-w-7xl">
        {(files.length > 0 || loading) && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={() => navigate('/datasets/create-dataset')}
          />
        )}

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Filename
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Purpose
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Size
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Created At
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {[...Array(5)].map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : files.length === 0 ? (
                <EmptyState
                  title="No files uploaded yet"
                  description="Start by uploading files to use with Reislo"
                  imageSrc={datasetsPlaceholder}
                  onPrimaryClick={() => navigate('/datasets/create-dataset')}
                  primaryAction="Upload File"
                />
              ) : (
                <div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Filename
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Purpose
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Size
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Created At
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {files.map((file) => (
                        <tr key={file.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <button
                              onClick={() => navigate(`/datasets/${file.id}`)}
                              className="text-primary-500 hover:text-primary-700"
                            >
                              {file.filename}
                            </button>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {file.purpose}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {formatFileSize(file.bytes)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {formatDate(file.created_at)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${file.status === 'processed'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-yellow-100 text-yellow-800'
                              }`}>
                              {file.status}
                            </span>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button
                              onClick={() => navigate(`/datasets/${file.id}`)}
                              className="text-primary-500 hover:text-primary-700 font-medium"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {renderDebugInfo()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatasetsTable;