import React, { useState } from 'react';
import ReactDOM from 'react-dom';

/**
 * Citation Tool for EditorJS
 * Allows adding numbered citation references that can be clicked to view source details
 */
class CitationTool {
  static get isInline() {
    return true;
  }

  static get title() {
    return 'Citation';
  }

  static get sanitize() {
    return {
      sup: true,
    };
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  constructor({ api }) {
    this.api = api;
    this._state = false;
    this.button = null;
    this.tag = 'SUP';
    this.class = 'citation-reference';
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.textContent = '[#]';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    const selectedText = range.extractContents();
    const citation = document.createElement(this.tag);
    
    citation.classList.add(this.class);
    citation.appendChild(selectedText);
    
    // Generate next citation number based on existing citations in the document
    const allCitations = document.querySelectorAll(`.${this.class}`);
    const citationNumber = allCitations.length + 1;
    
    citation.textContent = `[${citationNumber}]`;
    citation.dataset.citation = citationNumber;
    citation.dataset.source = "source-filename.md"; // Default source, should be customizable
    
    citation.style.cursor = 'pointer';
    citation.style.color = '#5b74f1';
    citation.style.fontWeight = 'bold';
    
    range.insertNode(citation);
    
    // Add click event listener to open the drawer
    citation.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      const event = new CustomEvent('openCitationDrawer', {
        detail: {
          citationNumber: citationNumber,
          source: citation.dataset.source
        }
      });
      
      document.dispatchEvent(event);
    });
    
    this.api.selection.expandToTag(citation);
  }

  unwrap(range) {
    const citation = this.api.selection.findParentTag(this.tag, this.class);
    const text = range.extractContents();
    
    citation.remove();
    range.insertNode(text);
  }

  checkState() {
    const citation = this.api.selection.findParentTag(this.tag, this.class);
    this.state = !!citation;
  }
}

export default CitationTool; 