import axios from 'axios';
import axiosRetry from 'axios-retry';





// API Endpoints

export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';
export const DIRECTUS_USER_JOURNEY_PHASES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/user_journey_phases`;
export const DIRECTUS_JOURNEY_PHASE_PAINPOINTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/journey_phase_painpoints`;
export const DIRECTUS_JOURNEY_PHASE_GAINS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/journey_phase_gains`;
export const DIRECTUS_AUTH_KEY = '';
export const OPENAI_COMPLETIONS_INSTANCE = 'https://api.openai.com/v1/chat/completions';
export const DIRECTUS_PERSONAS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/personas`;
export const DIRECTUS_PROJECTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/projects`;
export const DIRECTUS_ARTICLES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/articles`;
export const DIRECTUS_DATASETS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/data_sets`;
export const DIRECTUS_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/heatmaps`;
export const DIRECTUS_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/userjourney`;
export const DIRECTUS_ONBOARDING_ENDPOINT = `${DIRECTUS_INSTANCE}/items/onboarding_profiling`;
export const DIRECTUS_INSIGHTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/insights`;

export const DIRECTUS_FILES_ENDPOINT = `${DIRECTUS_INSTANCE}/files`;


export const REACT_APP_EMBEDINGS_API_KEY = 'sk-proj-JXJLhrn7T8vmFqaZHueoT3BlbkFJRFcioX86ARE5CpeSdQW4';
export const REACT_APP_GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;
export const SEO_ASSISTANT_ID = process.env.REACT_APP_SEO_ASSISTANT_ID;
export const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;


export const PINECONE_ENVIRONMENT = 'aped-4627-b74a';
export const PINECONE_INDEX_NAME = 'reislo-1536';
export const OPENAI_API_KEY = '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';

const API_URL = 'http://localhost:5001/api';


export const PINECONE_API_KEY = process.env.REACT_APP_PINECONE_API_KEY;
export const queryPinecone = async (vector, topK = 5) => {
  const response = await fetch(`${API_URL}/query`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ vector, topK }),
  });

  if (!response.ok) {
    throw new Error('Failed to query Pinecone');
  }

  return response.json();
};



// Create an Axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry to automatically retry requests
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

// Intercept requests to add Authorization header
directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

// Generic function to handle errors from API requests
const handleApiError = (error) => {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
};

// Function to post data to OpenAI API
export const postCompletion = async (postData) => {
  return await axios.post(OPENAI_COMPLETIONS_INSTANCE, postData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`
    }
  });
};




export const savePersonaData = async (dataToSave) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    console.error("Token not found");
    throw new Error("Authentication token not found. Please log in.");
  }

  try {
    const response = await axios.post(DIRECTUS_PERSONAS_ENDPOINT, dataToSave, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('Persona data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error during saving persona data:', error);
    throw error; // Rethrow after logging to handle it in the calling component
  }
};

// Function to save data to the Directus 'projects' endpoint
export const saveProjectData = async (dataToSave, token) => {
  try {
    const response = await axios.post(DIRECTUS_PROJECTS_ENDPOINT, dataToSave, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('Project data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error; // Rethrow after logging to handle it in the calling component
  }
};


// Handle API errors globally
directusAPI.interceptors.response.use(response => response, handleApiError);

export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await directusAPI.post('/auth/refresh', {
      refresh_token: refreshToken
    });

    if (response.data.access_token) {
      localStorage.setItem('directus_token', response.data.access_token);
      if (response.data.refresh_token) {
        localStorage.setItem('refresh_token', response.data.refresh_token);
      }
    }

    return response.data;
  } catch (error) {
    console.error('Token refresh failed:', error);
    // Clear tokens if refresh fails
    localStorage.removeItem('directus_token');
    localStorage.removeItem('refresh_token');
    throw error;
  }
};

// Adding the OpenAI Vector Store and Files API functions

/**
 * Gets the ID of the OpenAI Vector Store
 * @returns {Promise<string>} The Vector Store ID
 */
export const getVectorStoreId = async () => {
  // For now, we'll use an environment variable or a default value
  return process.env.REACT_APP_VECTOR_STORE_ID || "vs_abc123"; // Replace with actual store ID if needed
};

/**
 * Lists all OpenAI files uploaded by the user
 * @param {string} userId - Optional user ID to filter files by owner
 * @returns {Promise<Object>} Response containing the list of files
 */
export const listOpenAIFiles = async (userId) => {
  const response = await fetch('https://api.openai.com/v1/files', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': 'assistants=v2'
    }
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Failed to fetch OpenAI files');
  }

  const data = await response.json();
  return { data: data.data };
};

/**
 * Gets details for a specific OpenAI file
 * @param {string} fileId - The ID of the file to fetch
 * @returns {Promise<Object>} The file details
 */
export const getOpenAIFile = async (fileId) => {
  const response = await fetch(`https://api.openai.com/v1/files/${fileId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': 'assistants=v2'
    }
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Failed to fetch OpenAI file details');
  }

  return response.json();
};

/**
 * Deletes an OpenAI file
 * @param {string} fileId - The ID of the file to delete
 * @returns {Promise<Object>} The deletion response
 */
export const deleteOpenAIFile = async (fileId) => {
  const response = await fetch(`https://api.openai.com/v1/files/${fileId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': 'assistants=v2'
    }
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Failed to delete OpenAI file');
  }

  return response.json();
};

/**
 * Deletes a file from the Vector Store
 * @param {string} fileId - The ID of the file to delete
 * @returns {Promise<Object>} The deletion response
 */
export const deleteVectorStoreFile = async (fileId) => {
  const vectorStoreId = await getVectorStoreId();
  
  const response = await fetch(`https://api.openai.com/v1/vector_stores/${vectorStoreId}/files/${fileId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      'Content-Type': 'application/json',
      'OpenAI-Beta': 'assistants=v2'
    }
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error?.message || 'Failed to delete file from vector store');
  }

  return response.json();
};