import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';

// Custom hook for EditorJS - simpler, cleaner approach
const useEditor = (holderId, initialData = { blocks: [] }) => {
  const editorRef = useRef(null);
  
  useEffect(() => {
    // Destroy any existing editor first
    if (editorRef.current && typeof editorRef.current.destroy === 'function') {
      editorRef.current.destroy();
      editorRef.current = null;
    }
    
    // Create new editor with delay to ensure DOM is ready
    const timer = setTimeout(() => {
      if (document.getElementById(holderId)) {
        editorRef.current = new EditorJS({
          holder: holderId,
          tools: {
            header: Header,
            list: List,
            paragraph: Paragraph
          },
          data: initialData,
          minHeight: 50,
          placeholder: holderId === 'description-editor' 
            ? 'Describe your insight here...' 
            : 'Add your recommendations here...'
        });
        
        // Fix editor padding
        setTimeout(() => {
          const editorElements = document.querySelectorAll('.codex-editor__redactor');
          editorElements.forEach(el => {
            el.style.paddingBottom = '0px';
          });
        }, 100);
      }
    }, 100);
    
    return () => {
      clearTimeout(timer);
      if (editorRef.current && typeof editorRef.current.destroy === 'function') {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [holderId]);
  
  return editorRef;
};

const ProjectSelector = ({ currentProjectId, onChange }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://panel.reislo.com/items/projects', {
          params: {
            sort: '-date_created',
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div className="animate-pulse h-10 w-48 bg-gray-200 rounded"></div>;
  }

  return (
    <select
      value={currentProjectId || ''}
      onChange={(e) => onChange(e.target.value)}
      className=""
    >
      <option value="">Select a project</option>
      {projects.map((project) => (
        <option key={project.id} value={project.id}>
          {project.project_name}
        </option>
      ))}
    </select>
  );
};

// Add the UserAvatar component at the top level after imports
const UserAvatar = ({ user, size = 'h-8 w-8' }) => {
  if (!user) return (
    <div className={`${size} rounded-full bg-gray-200 flex items-center justify-center text-gray-600`}>
      U
    </div>
  );

  return user.avatar ? (
    <img 
      src={`${import.meta.env.VITE_DIRECTUS_URL}/assets/${user.avatar}?width=40&height=40&fit=cover`}
      alt={`${user.first_name || ''} ${user.last_name || ''}`}
      className={`${size} rounded-full object-cover`}
    />
  ) : (
    <div className={`${size} rounded-full bg-gray-200 flex items-center justify-center text-gray-600`}>
      {user.first_name ? user.first_name.charAt(0).toUpperCase() : 'U'}
    </div>
  );
};

function CreateInsight() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [insightType, setInsightType] = useState('behavioral');
  const [priority, setPriority] = useState('medium');
  const [businessValue, setBusinessValue] = useState(50);
  const [status, setStatus] = useState('published');
  const [source, setSource] = useState([]);
  const [sourceInput, setSourceInput] = useState('');
  const [insightCategory, setInsightCategory] = useState('');
  const [project, setProject] = useState('');
  const [references, setReferences] = useState('');
  
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  
  const descriptionEditorRef = useEditor('description-editor');
  const recommendationEditorRef = useEditor('recommendation-editor');
  
  const token = localStorage.getItem('directus_token');
  const navigate = useNavigate();

  // Then in the function component, add state for the current user
  const [currentUser, setCurrentUser] = useState(null);

  // Add useEffect to fetch current user details
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const userId = localStorage.getItem('user_id');
      const token = localStorage.getItem('directus_token');
      
      if (userId && token) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_DIRECTUS_URL}/users/me`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setCurrentUser(response.data.data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };
    
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      setProjectsLoading(true);
      try {
        const response = await axios.get('https://panel.reislo.com/items/projects', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['id', 'project_name'],
            sort: 'project_name',
            filter: {
              status: { _eq: 'published' }
            }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setProjectsLoading(false);
      }
    };

    fetchProjects();
  }, [token]);

  const getInsightTypeStyle = (type) => {
    switch (type) {
      case 'behavioral':
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
      case 'pain':
        return 'bg-red-50 text-red-700 ring-red-600/20';
      case 'gain':
        return 'bg-green-50 text-green-700 ring-green-600/20';
      default:
        return 'bg-gray-50 text-gray-700 ring-gray-600/20';
    }
  };

  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'high':
        return 'bg-red-100 text-red-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'low':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Validate required fields
      if (!title.trim()) {
        throw new Error('Title is required');
      }
      
      // Check if editors are available
      if (!descriptionEditorRef.current || !recommendationEditorRef.current) {
        throw new Error('Editors not initialized properly');
      }
      
      let descriptionData;
      let recommendationData;
      
      try {
        // Get data from editors
        descriptionData = await descriptionEditorRef.current.save();
        recommendationData = await recommendationEditorRef.current.save();
      } catch (editorError) {
        console.error('Editor error:', editorError);
        // Provide fallback values if editor save fails
        descriptionData = { blocks: [] };
        recommendationData = { blocks: [] };
      }
      
      // Create insight object
      const newInsight = {
        title: title.trim(),
        insight_type: insightType.toLowerCase(),
        piority: priority.toLowerCase(), // Make sure this is "piority" not "priority"
        score_value: parseInt(businessValue) || 0,
        description: JSON.stringify(descriptionData),
        recommendation: JSON.stringify(recommendationData),
        status: status,
        source: source.length > 0 ? source.join(', ') : null,
        insight_category: insightCategory || null,
        project: project || null,
        references: references || null
      };
      
      console.log('Sending insight with timestamp:', new Date().toISOString());
      console.log('Insight data being sent:', JSON.stringify(newInsight, null, 2));
      
      // Send to API
      const response = await axios.post(DIRECTUS_INSIGHTS_ENDPOINT, newInsight, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Navigate to the newly created insight
      navigate(`/insights/${response.data.data.id}`);

      console.log('Created insight with ID:', response.data.data.id);
      console.log('Server response:', response.data);
    } catch (err) {
      console.error('Error creating insight:', err);
      if (err.response?.data) {
        console.error('API Error details:', err.response.data);
      }
      setError(err.message || 'Error creating insight');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/insights');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">Create New Insight</h1>
            <button
              onClick={handleCancel}
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1" />
              Back to Insights
            </button>
          </div>
          <p className="mt-1 text-sm text-gray-500">Add a new insight to your collection.</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pb-16">
          {/* Main content - 2 cols */}
          <div className="md:col-span-2">
            <div className="space-y-6">
              {/* Title */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter insight title"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  />
                </div>
              </div>
              
              {/* Description and Recommendation */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 space-y-6">
                {/* Description Section */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <div id="description-editor" className="min-h-[120px]"></div>
                </div>
                
                {/* Recommendation Section */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Recommendation</label>
                  <div id="recommendation-editor" className="min-h-[120px]"></div>
                </div>
              </div>
              
              {/* Error message */}
              {error && (
                <div className="text-red-500 p-3 bg-red-50 rounded-md border border-red-200">
                  {error}
                </div>
              )}
              
              {/* Submit Buttons */}
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                >
                  {loading ? 'Creating...' : 'Create Insight'}
                </button>
              </div>
            </div>
          </div>
          
          {/* Right Column - Settings */}
          <div className="mt-5 md:mt-0">
            <div className="space-y-6">
              {/* Insight Type */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Insight Type</h3>
                <select
                  value={insightType}
                  onChange={(e) => setInsightType(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm mb-4"
                >
                  <option value="behavioral">Behavioral</option>
                  <option value="pain">Pain</option>
                  <option value="gain">Gain</option>
                </select>
                <div className="mt-2">
                  <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${getInsightTypeStyle(insightType)}`}>
                    {insightType}
                  </span>
                </div>
              </div>

              {/* Priority */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Priority</h3>
                <select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm mb-4"
                >
                  <option value="high">High</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
                <div className="mt-2">
                  <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getPriorityStyle(priority)}`}>
                    {priority}
                  </span>
                </div>
              </div>

              {/* Business Value */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Business Value</h3>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={businessValue}
                  onChange={(e) => setBusinessValue(parseInt(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                />
                <div className="flex justify-between mt-2">
                  <span className="text-xs text-gray-500">0</span>
                  <span className="text-sm font-medium text-gray-700">{businessValue}%</span>
                  <span className="text-xs text-gray-500">100</span>
                </div>
                <div className="mt-3">
                  <div className="relative flex-grow h-[6px] bg-gray-100 rounded">
                    <div 
                      className="absolute h-full bg-primary-500 rounded transition-all duration-500"
                      style={{ width: `${businessValue}%` }}
                    />
                  </div>
                </div>
              </div>

              {/* Status */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Status</h3>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <input
                      id="status-published"
                      name="status"
                      type="radio"
                      value="published"
                      checked={status === 'published'}
                      onChange={() => setStatus('published')}
                      className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                    />
                    <label htmlFor="status-published" className="ml-3 block text-sm font-medium text-gray-700">
                      Published
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="status-draft"
                      name="status"
                      type="radio"
                      value="draft"
                      checked={status === 'draft'}
                      onChange={() => setStatus('draft')}
                      className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                    />
                    <label htmlFor="status-draft" className="ml-3 block text-sm font-medium text-gray-700">
                      Draft
                    </label>
                  </div>
                </div>
              </div>

              {/* Category */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Category</h3>
                <input
                  type="text"
                  value={insightCategory}
                  onChange={(e) => setInsightCategory(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  placeholder="Enter category"
                />
              </div>

              {/* Project */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Project</h3>
                {projectsLoading ? (
                  <div className="animate-pulse h-10 w-full bg-gray-200 rounded"></div>
                ) : (
                  <select
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  >
                    <option value="">Select a project</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              {/* Source (as Tags) */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">Source</h3>
                <div className="flex flex-wrap gap-2 mb-3">
                  {source.map((tag, index) => (
                    <span 
                      key={index} 
                      className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700"
                    >
                      {tag}
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-600"
                        onClick={() => setSource(source.filter((_, i) => i !== index))}
                      >
                        <span className="sr-only">Remove {tag}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex">
                  <input
                    type="text"
                    value={sourceInput}
                    onChange={(e) => setSourceInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && sourceInput.trim()) {
                        e.preventDefault();
                        setSource([...source, sourceInput.trim()]);
                        setSourceInput('');
                      }
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    placeholder="Type source and press Enter"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (sourceInput.trim()) {
                        setSource([...source, sourceInput.trim()]);
                        setSourceInput('');
                      }
                    }}
                    className="ml-2 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    Add
                  </button>
                </div>
              </div>

              {/* References */}
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <h3 className="text-sm font-medium text-gray-900 mb-4">References</h3>
                <textarea
                  rows="3"
                  value={references}
                  onChange={(e) => setReferences(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                  placeholder="Add references or links"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateInsight; 