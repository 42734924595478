import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, UserIcon, PlusIcon, BriefcaseIcon, ClockIcon, MapIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';
import EmptyState from '../Atoms/emptyState';
import personasPlaceholder from '../../img/placeholders/personas.png';

const title = 'Personas';
const description = 'Navigate through the list to view specific information or to make updates to existing personas.';
const primaryAction = 'Create New Persona';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pages = [];
    
    if (totalPages <= 5) {
      // Show all pages if total is 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            aria-current={currentPage === i ? 'page' : undefined}
            onClick={() => onPageChange(i)}
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
              currentPage === i ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' : 'text-gray-500 hover:bg-gray-50'
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Always show first two pages
      for (let i = 1; i <= 2; i++) {
        pages.push(
          <button
            key={i}
            aria-current={currentPage === i ? 'page' : undefined}
            onClick={() => onPageChange(i)}
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
              currentPage === i ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' : 'text-gray-500 hover:bg-gray-50'
            }`}
          >
            {i}
          </button>
        );
      }

      // Add ellipsis
      pages.push(
        <span key="ellipsis" className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
          ...
        </span>
      );

      // Add last two pages
      for (let i = totalPages - 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            aria-current={currentPage === i ? 'page' : undefined}
            onClick={() => onPageChange(i)}
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
              currentPage === i ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' : 'text-gray-500 hover:bg-gray-50'
            }`}
          >
            {i}
          </button>
        );
      }
    }

    return pages;
  };

  return (
    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </button>
      {renderPageNumbers()}
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </button>
    </nav>
  );
};

function PersonasTable() {
  const [people, setPeople] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const ITEMS_PER_PAGE = 24;
  const [personas, setPersonas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log('Fetching personas for userId:', userId);


        const avatarUrl = personas && personas.avatar
          ? `https://panel.reislo.com/assets/${personas.avatar}?width=300&height=300&fit=cover&format=webp`
          : "https://thispersondoesnotexist.com/";


        const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: { _eq: userId } }, // Ensure correct filtering
            limit: ITEMS_PER_PAGE,
            page: currentPage,
            sort: '-date_created',
            fields: '*.*,project.project_name', // Include project name
            meta: 'total_count,filter_count'
          }
        });
        console.log('API Response:', response.data);

        setPeople(response.data.data.map(person => ({
          id: person.id,
          name: person.name,
          project: person.project?.project_name,
          gender: person.gender,
          status: person.status,
          date: formatDate(person.date_created),
          lastUpdated: person.date_updated ? formatDate(person.date_updated) : null,
          linked_user_journeys: person.linked_user_journeys || [],
          image: person.avatar?.id 
            ? `https://panel.reislo.com/assets/${person.avatar.id}?width=256&height=256&fit=cover&format=webp` 
            : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        })));

        const totalCount = response.data.meta.filter_count; // Correctly use filter_count for filtered results
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching personas:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, token, userId]);

  const SkeletonRow = () => (
    <div className="w-80 h-80 bg-secondary-100 rounded-xl  animate-pulse"></div>
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleClick = (id, status) => {
    if (status === 'complete') {
      navigate(`/personas/${id}`);
    }
  };

  return (
    <div className="py-16">
      <div className="mx-auto max-w-7xl">
        {(people.length > 0 || loading) && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={() => navigate('/personas/create-persona')}
          />
        )}
        
        <section className={`mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ${people.length > 0 || loading ? 'mt-8' : 'mt-0'}`}>
          {loading ? (
            Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
              <SkeletonRow key={index} />
            ))
          ) : people.length === 0 ? (
            <div className="col-span-full h-full flex items-center justify-center">
              <EmptyState
                title="Create First Personas"
                description="Get started by creating a new persona. Here soon will be cool marketing material."
                imageSrc={personasPlaceholder} 
                onPrimaryClick={() => navigate('/personas/create-persona')}
                primaryAction="New Persona"
              />
            </div>
          ) : (
            people.map((person) => (
              <div
                key={person.id}
                onClick={() => handleClick(person.id, person.status)}
                className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center border border-secondary-100 ${person.status === 'ongoing' ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:shadow-md'
                  }`}
              >
                <div className="flex flex-1 flex-col p-8">
                  <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={person.image} alt="" />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">{person.name}</h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
                      <BriefcaseIcon className="h-4 w-4 text-gray-400" />
                      {person.project || 'No Project'}
                    </div>
                    
                    <div className="flex items-center justify-center gap-2 mt-2 text-sm text-gray-500">
                      <ClockIcon className="h-4 w-4 text-gray-400" />
                      {person.lastUpdated || person.date}
                    </div>

                    {person.linked_user_journeys && person.linked_user_journeys.length > 0 ? (
                      <div className="mt-3 flex flex-wrap justify-center gap-2">
                        {person.linked_user_journeys.map(journey => (
                          <span
                            key={journey.id}
                            className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700"
                          >
                            <MapIcon className="h-3 w-3 mr-1" />
                            {journey.user_journey_name}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <div className="hidden flex items-center justify-center gap-2 mt-2 text-sm text-gray-400">
                        <MapIcon className="h-4 w-4" />
                        No User Journeys
                      </div>
                    )}

                    {person.status === 'ongoing' && (
                      <dd className="mt-3">
                        <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                          Ongoing
                        </span>
                      </dd>
                    )}
                  </dl>
                </div>
              </div>
            ))
          )}
        </section>
        {!loading && people.length > 0 && (
          <nav className="px-8 py-3 flex items-center justify-between sm:px-6 mt-8">
            <div className="flex-1 flex justify-between sm:hidden">
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                Previous
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, people.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default PersonasTable;
