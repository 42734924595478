import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, LightBulbIcon, PlusIcon, ChevronUpIcon, ChevronDownIcon, FunnelIcon, XMarkIcon, ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';
import EmptyState from '../Atoms/emptyState';
import { Bar } from 'react-chartjs-2';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import placeholderImage from '../../img/placeholders/insights.png';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const title = 'Insights';
const description = 'Navigate through the list to view specific information or to make updates to existing insights.';
const primaryAction = 'Create New Insight';

const EmptyStateWithFilter = ({ onReset, onNavigate }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-8">
    <img 
      src={placeholderImage} 
      alt="No insights found" 
      className="mx-auto "
    />
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Collect and Prioritize Insights</h3>
    <p className="mt-1 text-sm text-gray-500">
      Start by creating your first insight and prioritize it to be in the circle of product discovery.
    </p>
    <div className="mt-6">
      <button
        type="button"
        onClick={() => onNavigate('/insights/create')}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white hover:bg-primary-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Insight
      </button>
    </div>
  </div>
);

const ValueBar = ({ value }) => {
  const normalizedValue = Math.min(Math.max(value, 0), 100);
  
  // Helper function to determine the color based on value
  const getBackgroundColor = (value) => {
    if (value <= 20) return '#ef4444'; // red
    if (value <= 50) return '#f87171'; // lighter red
    if (value <= 70) return '#fbbf24'; // yellow
    if (value <= 90) return '#34d399'; // green
    return '#22c55e'; // very green
  };

  return (
    <div className="flex items-center gap-2 w-[254px]">
      <div className="relative flex-grow h-[6px] bg-gray-100 rounded overflow-hidden">
        <div 
          className="absolute h-full rounded transition-all duration-500"
          style={{ 
            width: `${normalizedValue}%`,
            backgroundColor: getBackgroundColor(normalizedValue)
          }}
        />
      </div>
      <span className="text-xs text-gray-600 whitespace-nowrap">
        {normalizedValue}%
      </span>
    </div>
  );
};

function InsightsTable() {
  const [insights, setInsights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [allInsights, setAllInsights] = useState([]);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [projectFilter, setProjectFilter] = useState([]);
  const [businessValueRange, setBusinessValueRange] = useState([0, 100]);
  const [projects, setProjects] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'date_created', direction: 'desc' });
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [commentLoading, setCommentLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [comments, setComments] = useState([]);

  const fetchPersonasData = useCallback(() => {
    // This is a placeholder function to avoid the undefined error
    // Implement actual persona fetching logic here if needed
    console.log('Fetching personas data');
  }, []);

  const fetchData = async (start = dateRange[0].startDate, end = dateRange[0].endDate) => {
    // Only show loading state on initial load, not during filtering
    const isInitialLoad = insights.length === 0 && !loading;
    if (isInitialLoad) {
    setLoading(true);
    }
    
    try {
      const today = new Date();
      // Don't reset hours to start of day - include all of today's data
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      // Make sure nextMonthEnd is after today
      const nextMonthEnd = new Date(today.getFullYear(), today.getMonth() + 2, 0, 23, 59, 59);

      const allDataResponse = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            _and: [
              { user_created: { _eq: userId } },
              { date_created: { _gte: lastMonthStart.toISOString() } },
              { date_created: { _lte: today.toISOString() } }
            ]
          },
          limit: -1,
          sort: '-date_created',
          fields: ['date_created']
        }
      });

      const dates = [];
      const currentDate = new Date(lastMonthStart);
      
      while (currentDate <= nextMonthEnd) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const dailyCount = {};
      dates.forEach(date => {
        const dateKey = date.toISOString().split('T')[0];
        dailyCount[dateKey] = 0;
      });

      allDataResponse.data.data.forEach(insight => {
        const dateKey = new Date(insight.date_created).toISOString().split('T')[0];
        if (dailyCount[dateKey] !== undefined) {
          dailyCount[dateKey] += 1;
        }
      });

      // Calculate average from current month data up to today
      const currentMonthDays = Object.keys(dailyCount).filter(date => 
        date >= currentMonthStart.toISOString().split('T')[0] &&
        date <= today.toISOString().split('T')[0]
      );

      const currentMonthTotal = currentMonthDays.reduce((sum, date) => sum + dailyCount[date], 0);
      let averagePerDay = currentMonthTotal / currentMonthDays.length;
      
      // Calculate growing prediction starting from today
      const growthRate = 1.05; // 5% growth rate per day
      let currentAverage = averagePerDay;
      
      // Reset future dates
      const futureDates = Object.keys(dailyCount).filter(date => 
        new Date(date) > today
      );
      
      futureDates.forEach((date, index) => {
        currentAverage *= growthRate;
        dailyCount[date] = Math.round(currentAverage * 10) / 10; // Round to 1 decimal
      });

      const sortedDates = Object.keys(dailyCount).sort();

      setChartData({
        labels: sortedDates.map(dateStr => {
          const date = new Date(dateStr);
          return date.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric' 
          });
        }),
        datasets: [
          {
            label: 'Last Month',
            data: sortedDates.map(date => {
              if (date >= lastMonthStart.toISOString().split('T')[0] && 
                  date < currentMonthStart.toISOString().split('T')[0]) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'rgba(156, 163, 175, 0.6)',
            borderColor: 'rgba(156, 163, 175, 1)',
            borderWidth: 1,
            stack: 'stack',
            type: 'bar'
          },
          {
            label: 'Current Month',
            data: sortedDates.map(date => {
              if (date >= currentMonthStart.toISOString().split('T')[0] && 
                  date <= today.toISOString().split('T')[0]) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'rgba(59, 130, 246, 0.6)',
            borderWidth: 0,
            stack: 'stack',
            type: 'bar'
          },
          {
            label: 'Prediction',
            data: sortedDates.map(date => {
              if (new Date(date) > today) {
                return dailyCount[date];
              }
              return null;
            }),
            backgroundColor: 'transparent',
            borderColor: 'rgba(251, 191, 36, 1)', // yellow
            borderWidth: 2,
            borderDash: [5, 5],
            tension: 0.4,
            type: 'line',
            stack: undefined,
            fill: false,
            pointRadius: 2,
            pointBackgroundColor: 'rgba(251, 191, 36, 1)',
            pointBorderColor: 'rgba(251, 191, 36, 1)',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
          }
        ]
      });

      // Format the date strings properly to ensure consistent ISO format
      const startISO = new Date(start).toISOString();
      const endISO = new Date(end).toISOString();
      
      // Build filter object with proper date filtering
      const filterObject = {
        _and: [
          { user_created: { _eq: userId } }
        ]
      };
      
      // Only add date filters if they are valid dates
      if (startISO && endISO) {
        filterObject._and.push({ date_created: { _gte: startISO } });
        filterObject._and.push({ date_created: { _lte: endISO } });
      }
      
      // Add priority filter if selected (fix the field name if needed)
      if (priorityFilter.length > 0) {
        filterObject._and.push({ piority: { _in: priorityFilter } });
      }
      
      // Add project filter if selected
      if (projectFilter.length > 0) {
        filterObject._and.push({ project: { _in: projectFilter } });
      }
      
      console.log('Filter object:', JSON.stringify(filterObject, null, 2));

      const paginatedResponse = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: JSON.stringify(filterObject),
          fields: [
            '*',
            'project.project_name',
            'userjourney.user_journey_name',
            'userjourney.id',
            'userjourney.phase.phase_name',
            'userjourney.phase.painpoints.insight_id',
            'userjourney.phase.gains.insight_id',
            'user_created.first_name',
            'user_created.last_name',
            'user_created.avatar',
            'user_created'
          ],
          meta: 'filter_count',
          limit: itemsPerPage,
          page: currentPage,
          sort: sortConfig.direction === 'asc' ? sortConfig.key : `-${sortConfig.key}`
        }
      });

      // Filter by business value client-side
      let filteredInsights = paginatedResponse.data.data;
      if (businessValueRange[0] > 0 || businessValueRange[1] < 100) {
        filteredInsights = filteredInsights.filter(insight => {
          const value = insight.score_value || 0;
          return value >= businessValueRange[0] && value <= businessValueRange[1];
        });
      }

      setInsights(filteredInsights);
      const totalCount = paginatedResponse.data.meta.filter_count;
      setTotalPages(Math.ceil(totalCount / itemsPerPage));

      // Always set loading to false
      setLoading(false);

      console.log('API response data:', paginatedResponse.data);
      console.log('Filtered insights for display:', filteredInsights);
    } catch (error) {
      console.error('Error fetching insights:', error);
      setLoading(false);
      setInsights([]);
    }
  };

  useEffect(() => {
      fetchData(dateRange[0].startDate, dateRange[0].endDate);
    fetchProjects();
    if (fetchPersonasData) {
      fetchPersonasData();
    }
  }, [currentPage, token, userId, itemsPerPage, sortConfig, priorityFilter, projectFilter, businessValueRange, fetchPersonasData]);

  const resetFilters = () => {
    setDateRange([{
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
      key: 'selection'
    }]);
    setCurrentPage(1);
    fetchData();
  };

  const fetchProjects = async () => {
    try {
      // First fetch all insights to determine which projects have insights
      const insightsResponse = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          fields: ['project'],
          filter: {
            user_created: { _eq: userId }
          }
        }
      });
      
      // Extract unique project IDs from insights
      const projectsWithInsights = new Set();
      insightsResponse.data.data.forEach(insight => {
        if (insight.project) {
          projectsWithInsights.add(insight.project);
        }
      });
      
      // Now fetch the actual project details
      const response = await axios.get('https://panel.reislo.com/items/projects', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          fields: ['id', 'project_name'],
          sort: 'project_name',
          filter: {
            status: { _eq: 'published' }
          }
        }
      });
      
      // Filter to only include projects that have insights
      const filteredProjects = response.data.data.filter(project => 
        projectsWithInsights.has(project.id)
      );
      
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      if (prevConfig.key === key) {
        return { 
          key, 
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' 
        };
      }
      return { key, direction: 'desc' };
    });
  };

  const resetAllFilters = () => {
    setPriorityFilter([]);
    setProjectFilter([]);
    setBusinessValueRange([0, 100]);
    resetFilters(); // This resets the date range
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ChevronUpIcon className="h-4 w-4 text-gray-400" />;
    }
    
    return sortConfig.direction === 'asc' 
      ? <ChevronUpIcon className="h-4 w-4 text-primary-500" />
      : <ChevronDownIcon className="h-4 w-4 text-primary-500" />;
  };

  const SkeletonRow = () => (
    <tr>
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
        <div className="h-5 w-5 bg-gray-200 rounded animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-40 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-16 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
      </td>
      <td className="whitespace-nowrap px-3 py-3 text-sm">
        <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
      </td>
    </tr>
  );

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getInsightTypeStyle = (type) => {
    switch (type) {
      case 'pain':
        return 'bg-red-50 text-red-700 ring-red-600/20';
      case 'success':
        return 'bg-green-50 text-green-700 ring-green-600/20';
      case 'job':
        return 'bg-purple-50 text-purple-700 ring-purple-600/20';
      case 'behavioral':
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
      case 'attitudinal':
        return 'bg-yellow-50 text-yellow-700 ring-yellow-600/20';
      default:
        return 'bg-gray-50 text-gray-700 ring-gray-600/20';
    }
  };

  const getReadableInsightType = (type) => {
    switch (type) {
      case 'pain':
        return 'Pain Point';
      case 'success':
        return 'Gain';
      case 'job':
        return 'Job To Be Done';
      case 'behavioral':
        return 'Behavioral';
      case 'attitudinal':
        return 'Attitudinal';
      default:
        return type;
    }
  };

  const getPriorityStyle = (priority) => {
    const styles = {
      high: 'text-red-700 bg-red-50',
      medium: 'text-yellow-700 bg-yellow-50',
      low: 'text-green-700 bg-green-50',
    };
    return styles[priority] || 'text-gray-700 bg-gray-50';
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (newSize) => {
    setItemsPerPage(Number(newSize));
    setCurrentPage(1);
  };

  const addComment = async (insightId, commentText) => {
    if (!commentText.trim()) return;
    
    setCommentLoading(true);
    try {
      // API call would go here to save a comment for a specific insight
      
      const comment = {
        id: comments.length + 1,
        author: 'Current User',
        content: commentText,
        date: new Date().toISOString()
      };
      
      setComments([...comments, comment]);
      setNewComment('');
    } catch (err) {
      console.error('Failed to add comment:', err);
      // Show error message
    } finally {
      setCommentLoading(false);
    }
  };

  const ErrorMessage = ({ message, onDismiss }) => (
    <div className="rounded-md bg-red-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-800">{message}</p>
        </div>
        {onDismiss && (
          <div className="ml-auto pl-3">
            <button
              type="button"
              onClick={onDismiss}
              className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        {/* Only show ContentHeader if we have insights or are loading */}
        {(insights.length > 0 || loading) && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={() => navigate('/insights/create')}
          />
        )}

        {/* Controls bar - only show when there are insights */}
        {!loading && insights.length > 0 && (
          <div className="mb-6">
            {/* Horizontal filters row - Zalando style */}
            <div className="flex flex-wrap gap-2 mb-4 mt-4">
              {/* Date Range Filter */}
              <div className="relative">
                <button
                  type="button"
                  onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                  className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium"
                >
                  <span className="mr-2">Data</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </button>

                {isDatePickerVisible && (
                  <div className="absolute z-50 mt-2 left-0">
                    <div className="relative bg-white shadow-lg rounded-md border border-gray-200">
                      <DateRangePicker
                        onChange={item => {
                          setDateRange([item.selection]);
                          setIsDatePickerVisible(false);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dateRange}
                        direction="horizontal"
                        className="border-none"
                      />
                      <div className="flex justify-between p-4 border-t">
                        <button
                          onClick={() => {
                            resetFilters();
                            setIsDatePickerVisible(false);
                          }}
                          className="text-sm font-medium text-gray-600 hover:text-gray-900"
                        >
                          Reset
                        </button>
                        <button
                          onClick={() => {
                            fetchData(dateRange[0].startDate, dateRange[0].endDate);
                            setIsDatePickerVisible(false);
                          }}
                          className="ml-4 px-3 py-1 bg-primary-600 text-white text-sm font-medium rounded-md hover:bg-primary-700"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Priority Filter */}
              <div className="relative">
                <Menu as="div" className="inline-block text-left">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium">
                        <span className="mr-2">Priority</span>
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                      </Menu.Button>
                      
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3">
                            <div className="flex flex-col gap-2">
                              {['high', 'medium', 'low'].map(priority => (
                                <div key={priority} className="flex items-center">
                                  <input
                                    id={`priority-${priority}`}
                                    type="checkbox"
                                    checked={priorityFilter.includes(priority)}
                                    onChange={() => {
                                      setPriorityFilter(prev => 
                                        prev.includes(priority) 
                                          ? prev.filter(p => p !== priority)
                                          : [...prev, priority]
                                      );
                                    }}
                                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                                  />
                                  <label htmlFor={`priority-${priority}`} className="ml-2 block text-sm text-gray-900 capitalize">
                                    {priority}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div className="mt-4 flex justify-between border-t pt-3">
                              <button
                                onClick={() => setPriorityFilter([])}
                                className="text-sm font-medium text-gray-600 hover:text-gray-900"
                              >
                                Reset
                              </button>
                              <Menu.Button as="button"
                                className="ml-4 px-3 py-1 bg-primary-600 text-white text-sm font-medium rounded-md hover:bg-primary-700"
                              >
                                Apply
                              </Menu.Button>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              {/* Project Filter */}
              <div className="relative">
                <Menu as="div" className="inline-block text-left">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium">
                        <span className="mr-2">Project</span>
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                      </Menu.Button>
                      
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3">
                            <div className="max-h-48 overflow-y-auto">
                              <div className="flex flex-col gap-2">
                                {projects.map(project => (
                                  <div key={project.id} className="flex items-center">
                                    <input
                                      id={`project-${project.id}`}
                                      type="checkbox"
                                      checked={projectFilter.includes(project.id)}
                                      onChange={() => {
                                        setProjectFilter(prev => 
                                          prev.includes(project.id) 
                                            ? prev.filter(p => p !== project.id)
                                            : [...prev, project.id]
                                          );
                                      }}
                                      className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor={`project-${project.id}`} className="ml-2 block text-sm text-gray-900">
                                      {project.project_name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="mt-4 flex justify-between border-t pt-3">
                              <button
                                onClick={() => setProjectFilter([])}
                                className="text-sm font-medium text-gray-600 hover:text-gray-900"
                              >
                                Reset
                              </button>
                              <Menu.Button as="button"
                                className="ml-4 px-3 py-1 bg-primary-600 text-white text-sm font-medium rounded-md hover:bg-primary-700"
                              >
                                Apply
                              </Menu.Button>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              {/* Business Value Filter */}
              <div className="relative">
                <Menu as="div" className="inline-block text-left">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium">
                        <span className="mr-2">Business Value</span>
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                      </Menu.Button>
                      
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="p-3">
                            <div className="px-2 py-4">
                              <div className="mb-2 text-sm text-gray-700">
                                {businessValueRange[0]}% - {businessValueRange[1]}%
                              </div>
                              <Slider
                                range
                                min={0}
                                max={100}
                                value={businessValueRange}
                                onChange={setBusinessValueRange}
                                railStyle={{ backgroundColor: '#E5E7EB' }}
                                trackStyle={[{ backgroundColor: '#4F46E5' }]}
                                handleStyle={[
                                  { borderColor: '#4F46E5', backgroundColor: '#4F46E5' },
                                  { borderColor: '#4F46E5', backgroundColor: '#4F46E5' }
                                ]}
                              />
                            </div>
                            <div className="mt-4 flex justify-between border-t pt-3">
                              <button
                                onClick={() => setBusinessValueRange([0, 100])}
                                className="text-sm font-medium text-gray-600 hover:text-gray-900"
                              >
                                Reset
                              </button>
                              <Menu.Button as="button"
                                className="ml-4 px-3 py-1 bg-primary-600 text-white text-sm font-medium rounded-md hover:bg-primary-700"
                              >
                                Apply
                              </Menu.Button>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              {/* Reset All Filters button */}
              {(priorityFilter.length > 0 || projectFilter.length > 0 || businessValueRange[0] > 0 || businessValueRange[1] < 100 || 
                dateRange[0].startDate.getTime() !== new Date(new Date().setMonth(new Date().getMonth() - 3)).getTime()) && (
                <button
                  type="button"
                  onClick={resetAllFilters}
                  className="flex items-center gap-1 px-4 py-2 text-sm font-medium text-gray-700"
                >
                  <XMarkIcon className="h-4 w-4" />
                  <span>Reset all filters</span>
                </button>
              )}
            </div>

            {/* Active Filter Pills */}
            <div className="flex flex-wrap items-center gap-2 mb-4">
              {priorityFilter.length > 0 && (
                <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  Priority: {priorityFilter.join(', ')}
                  <button
                    type="button"
                    className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={() => setPriorityFilter([])}
                  >
                    <span className="sr-only">Remove priority filter</span>
                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                  </button>
                </span>
              )}
              
              {projectFilter.length > 0 && (
                <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  Projects: {projectFilter.map(id => projects.find(p => p.id === id)?.project_name).filter(Boolean).join(', ')}
                  <button
                    type="button"
                    className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={() => setProjectFilter([])}
                  >
                    <span className="sr-only">Remove project filter</span>
                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                  </button>
                </span>
              )}
              
              {(businessValueRange[0] > 0 || businessValueRange[1] < 100) && (
                <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                  Business Value: {businessValueRange[0]}% - {businessValueRange[1]}%
                  <button
                    type="button"
                    className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={() => setBusinessValueRange([0, 100])}
                  >
                    <span className="sr-only">Remove business value filter</span>
                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                  </button>
                </span>
              )}
            </div>

            {/* Chart Section - retain in a card */}
            <div className="bg-white rounded-lg shadow">
              <div className="p-4">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Insight Trends</h3>
                <div className="h-64">
                  <Bar
                    data={chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: false,
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-8 flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.from({ length: itemsPerPage }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : (
                insights.length === 0 ? (
                  // Check if filters are applied
                  priorityFilter.length > 0 || projectFilter.length > 0 || businessValueRange[0] > 0 || businessValueRange[1] < 100 || 
                  dateRange[0].startDate.getTime() !== new Date(new Date().setMonth(new Date().getMonth() - 3)).getTime() ? (
                    <EmptyStateWithFilter onReset={resetFilters} onNavigate={navigate} />
                  ) : (
                    <EmptyState
                      title="No Insights Yet"
                      description="Start capturing insights from your research and user data. Create your first insight to begin organizing your findings."
                      icon={LightBulbIcon}
                      onPrimaryClick={() => navigate('/insights/create')}
                      primaryAction="New Insight"
                    />
                  )
                ) : (
                  <div className="bg-white shadow rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th 
                            scope="col" 
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 max-w-4 cursor-pointer"
                            onClick={() => handleSort('insight_type')}
                          >
                            <div className="flex items-center">
                              Type
                              <span className="ml-2">{renderSortIcon('insight_type')}</span>
                            </div>
                          </th>
                          <th 
                            scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                            onClick={() => handleSort('description')}
                          >
                            <div className="flex items-center">
                              Description
                              <span className="ml-2">{renderSortIcon('description')}</span>
                            </div>
                          </th>
                          <th 
                            scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                            onClick={() => handleSort('piority')}
                          >
                            <div className="flex items-center">
                              Priority
                              <span className="ml-2">{renderSortIcon('piority')}</span>
                            </div>
                          </th>
                          <th 
                            scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                            onClick={() => handleSort('score_value')}
                          >
                            <div className="flex items-center">
                              Business Value
                              <span className="ml-2">{renderSortIcon('score_value')}</span>
                            </div>
                          </th>
                          <th 
                            scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                            onClick={() => handleSort('project')}
                          >
                            <div className="flex items-center">
                              Project
                              <span className="ml-2">{renderSortIcon('project')}</span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            User Journey
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Author
                          </th>
                          <th 
                            scope="col" 
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                            onClick={() => handleSort('date_created')}
                          >
                            <div className="flex items-center">
                              Created
                              <span className="ml-2">{renderSortIcon('date_created')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {insights.map((insight) => (
                          <tr
                            key={insight.id}
                            onClick={() => navigate(`/insights/${insight.id}`)}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm max-w-4">
                              {insight.insight_type === 'pain' ? (
                                <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" />
                                </svg>
                              ) : insight.insight_type === 'success' ? (
                                <svg className="h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" />
                                </svg>
                              ) : insight.insight_type === 'critical' ? (
                                <svg className="h-5 w-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                </svg>
                              ) : (
                                <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" />
                                </svg>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm text-gray-900">
                              {insight.title || 'No title'}
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm">
                              <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getPriorityStyle(insight.piority)}`}>
                                {insight.piority}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-3 text-sm">
                              <ValueBar value={insight.score_value || 0} />
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                              {insight.project?.project_name || 'No Project'}
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                              {insight.userjourney ? (
                                <div className="flex flex-col gap-1">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/user-journeys/${insight.userjourney.id}`);
                                    }}
                                    className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-blue-50 text-blue-700 hover:bg-blue-100 transition-colors"
                                  >
                                    {insight.userjourney.user_journey_name}
                                  </button>
                                  {insight.userjourney.phase?.map((phase, index) => {
                                    const isInPainpoints = phase.painpoints?.some(p => p.insight_id === insight.id);
                                    const isInGains = phase.gains?.some(g => g.insight_id === insight.id);
                                    if (isInPainpoints || isInGains) {
                                      return (
                                        <span key={index} className="text-xs text-gray-500 pl-2">
                                          • {phase.phase_name} ({isInPainpoints ? 'Pain' : 'Gain'})
                                        </span>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              ) : (
                                <span className="text-gray-400">No Journey</span>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                              <div className="flex items-center">
                                {insight.user_created && (
                                  insight.user_created.avatar ? (
                                    <img 
                                      src={`${import.meta.env.VITE_DIRECTUS_URL}/assets/${insight.user_created.avatar}?width=40&height=40&fit=cover`}
                                      alt="User avatar" 
                                      className="h-8 w-8 rounded-full object-cover"
                                    />
                                  ) : (
                                    <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-600">
                                      {insight.user_created.first_name ? 
                                        insight.user_created.first_name.charAt(0).toUpperCase() : 
                                        'U'}
                                    </div>
                                  )
                                )}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                              {formatDate(insight.date_created)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )
              )}

            </div>
          </div>
        </div>

        {/* Only show pagination when there are 10 or more items */}
        {!loading && insights.length >= 10 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative ml-3 inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="flex items-center gap-4">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(currentPage * itemsPerPage, insights.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * itemsPerPage}</span> results
                </p>
                <select
                  value={itemsPerPage}
                  onChange={(e) => handlePageSizeChange(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                >
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  <option value="500">500 per page</option>
                </select>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md -space-x-px" aria-label="Pagination">
                  <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {totalPages <= 5 ? (
                    Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i}
                        aria-current={currentPage === i + 1 ? 'page' : undefined}
                        onClick={() => handlePageChange(i + 1)}
                        className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                          currentPage === i + 1 
                            ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                            : 'text-gray-500 hover:bg-gray-50'
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {[1, 2].map((pageNum) => (
                        <button
                          key={pageNum}
                          aria-current={currentPage === pageNum ? 'page' : undefined}
                          onClick={() => handlePageChange(pageNum)}
                          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                            currentPage === pageNum 
                              ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                              : 'text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {pageNum}
                        </button>
                      ))}
                      <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                      {[totalPages - 1, totalPages].map((pageNum) => (
                        <button
                          key={pageNum}
                          aria-current={currentPage === pageNum ? 'page' : undefined}
                          onClick={() => handlePageChange(pageNum)}
                          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                            currentPage === pageNum 
                              ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' 
                              : 'text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {pageNum}
                        </button>
                      ))}
                    </>
                  )}
                  <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default InsightsTable;
