import React, { useState, useRef, useEffect } from 'react';
import { 
  TrashIcon
} from '@heroicons/react/24/outline';

const PostItEditor = ({ 
  initialText = '', 
  onSave, 
  color = '#fbbf24',
  onDelete,
  onFocus,
  onBlur
}) => {
  const [text, setText] = useState(initialText);
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  // Update text when initialText changes
  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  // Handle focus and cursor position
  useEffect(() => {
    if (textareaRef.current && isEditing) {
      textareaRef.current.focus();
      // Place cursor at the end
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isEditing) {
          setIsEditing(false);
          onSave(text);
          onBlur?.();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, text, onSave, onBlur]);

  const getBackgroundColor = () => {
    switch (color) {
      case '#fbbf24': return '#fef3c7'; // Yellow
      case '#86efac': return '#dcfce7'; // Green
      case '#fecaca': return '#fee2e2'; // Red
      default: return '#fef3c7';
    }
  };

  const handleFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
    onFocus?.();
  };

  const handleBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(false);
    onSave(text);
    onBlur?.();
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setText(e.target.value);
  };

  return (
    <div 
      ref={containerRef}
      className="relative p-4 shadow-sm rounded-lg transition-all duration-200 group"
      style={{ background: getBackgroundColor() }}
    >
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <button
          className="p-1 hover:bg-black hover:bg-opacity-5 rounded"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <TrashIcon className="h-5 w-5 text-gray-400" />
        </button>
      </div>

      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleFocus}
        className="w-full min-h-[150px] outline-none text-sm leading-relaxed cursor-text bg-transparent resize-none border-0 focus:ring-0 focus:border-0"
        style={{ 
          fontFamily: 'inherit',
          boxShadow: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden'
        }}
      />
    </div>
  );
};

export default PostItEditor; 