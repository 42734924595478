import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatSessionsTable from './chatSessions';
import ContentHeader from '../Atoms/contentHeader';
import EmptyState from '../Atoms/emptyState';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';

const title = 'Content Assistant Sessions';
const description = 'This table provides a quick snapshot of each chat session, allowing you to easily manage and review your conversations.';
const primaryAction = 'Start New Chat';

const AssistantDashboard = () => {
  const navigate = useNavigate();
  const [hasSessions, setHasSessions] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to ensure loading state is reset even if callback doesn't work
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    
    return () => clearTimeout(timer);
  }, []);

  const handlePrimaryAction = () => {
    navigate('/chat/new');
  };

  const handleSessionSelect = (sessionId) => {
    navigate(`/chat/${sessionId}`);
  };

  // Function to check if there are any sessions
  const checkForSessions = (sessions) => {
    setHasSessions(sessions && sessions.length > 0);
    setLoading(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-6 mt-10">
      <ContentHeader
        title={title}
        description={description}
        primaryAction={primaryAction}
        onPrimaryAction={handlePrimaryAction}
      />
      
      <ChatSessionsTable 
        onSessionSelect={handleSessionSelect} 
        onSessionsLoaded={checkForSessions}
        emptyState={
          <EmptyState
            title="No chat sessions found"
            description="Get started by creating a new chat session with the AI assistant."
            icon={ChatBubbleLeftRightIcon}
            onPrimaryClick={handlePrimaryAction}
            primaryAction="Start New Chat"
          />
        }
      />
    </div>
  );
};

export default AssistantDashboard; 