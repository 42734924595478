import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { XMarkIcon } from '@heroicons/react/24/outline';

export function InsightSelector({ isOpen, onClose, onSelect, type, currentInsights = [] }) {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);

  useEffect(() => {
    if (isOpen) {
      console.log("InsightSelector opened with type:", type);
      setLoading(true);
      setError(null);
      fetchInsights();
    }
  }, [isOpen, type]);

  const fetchInsights = async () => {
    try {
      const token = localStorage.getItem('directus_token');
      
      // Dokładnie logujemy co jest wysyłane do API
      const insightType = type === 'Painpoint' 
        ? 'pain' 
        : type === 'JobToBeDone' 
          ? 'job'
          : 'success';
          
      console.log("Fetching insights with exact type:", insightType);
      
      // Get array of IDs or objects depending on what's passed
      const currentInsightIds = Array.isArray(currentInsights) 
        ? currentInsights.map(item => typeof item === 'object' ? (item.insight_id || item.id) : item)
        : [];
      
      // Utwórz params do API z lepszym debugowaniem
      const requestParams = {
        filter: {
          insight_type: { _eq: insightType },
          id: { _nin: currentInsightIds }
        },
        sort: ['-date_created'],
        fields: ['*', 'project.project_name', 'userjourney.user_journey_name']
      };
      
      console.log("API Request params:", JSON.stringify(requestParams, null, 2));
      
      const response = await axios.get(DIRECTUS_INSIGHTS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: requestParams
      });
      
      console.log(`Received ${response.data.data.length} insights from API`);
      setDebugInfo({
        type: type,
        insightType: insightType,
        responseCount: response.data.data.length,
        firstInsight: response.data.data[0] || null
      });
      
      setInsights(response.data.data);
    } catch (error) {
      console.error('Failed to fetch insights:', error);
      setError(`Failed to load insights: ${error.message}`);
      setDebugInfo({
        error: error.message,
        type: type,
        endpoint: DIRECTUS_INSIGHTS_ENDPOINT
      });
    } finally {
      setLoading(false);
    }
  };

  const getTypeStyles = (insightType) => {
    switch (insightType) {
      case 'pain':
        return 'bg-red-50 text-red-700';
      case 'job':
      case 'jtbd':
        return 'bg-purple-50 text-purple-700';
      case 'success':
      default:
        return 'bg-green-50 text-green-700';
    }
  };

  const getTypeName = (insightType) => {
    switch (insightType) {
      case 'pain':
        return 'Pain';
      case 'job':
      case 'jtbd':
        return 'Job To Be Done';
      case 'success':
        return 'Gain';
      default:
        return 'Insight';
    }
  };

  // Dodaj funkcję do parsowania treści z EditorJS JSON
  const parseEditorContent = (content) => {
    if (!content) return '';
    
    try {
      // Sprawdź czy to JSON
      const parsed = typeof content === 'string' ? JSON.parse(content) : content;
      
      // Sprawdź czy ma format EditorJS z blokami
      if (parsed && parsed.blocks) {
        return parsed.blocks
          .map(block => {
            if (block.type === 'paragraph') {
              return block.data.text;
            }
            if (block.type === 'header') {
              return block.data.text;
            }
            return '';
          })
          .filter(text => text) // usuń puste teksty
          .join(' '); // połącz wszystkie bloki z odstępami
      }
      
      // Jeśli to zwykły string, po prostu go zwróć
      return typeof parsed === 'string' ? parsed : content;
    } catch (e) {
      // Jeśli nie udało się sparsować jako JSON, zwróć oryginalny tekst
      return content;
    }
  };

  return (
    <>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50"
          onClick={onClose}
        />
      )}
      
      <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-[51]`}>
        <div className="h-full flex flex-col">
          <div className="px-4 py-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium">Select {type}</h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : error ? (
              <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
                <p className="font-medium">Błąd ładowania insightów:</p>
                <p className="text-sm mt-1">{error}</p>
                <button 
                  onClick={fetchInsights} 
                  className="mt-3 px-3 py-1 bg-red-100 hover:bg-red-200 rounded-md text-sm font-medium"
                >
                  Spróbuj ponownie
                </button>
              </div>
            ) : insights.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-gray-500">Brak dostępnych insightów typu {type}.</p>
                <p className="text-sm text-gray-400 mt-2">
                  Szukałem insightów typu: {type === 'Painpoint' ? 'pain' : type === 'JobToBeDone' ? 'job' : 'success'}
                </p>
              </div>
            ) : (
              <div className="space-y-2">
                {insights.map(insight => (
                  <div
                    key={insight.id}
                    onClick={() => onSelect(insight)}
                    className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-all"
                  >
                    {/* Tytuł jako główny element */}
                    <h3 className="text-base font-medium text-gray-900">
                      {insight.title || 'Untitled Insight'}
                    </h3>
                    
                    {/* Opis jako dodatkowy element, sformatowany */}
                    <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                      {parseEditorContent(insight.description)}
                    </p>
                    
                    {/* Tag typu i priorytet */}
                    <div className="flex items-center gap-2 mt-2">
                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getTypeStyles(insight.insight_type)}`}>
                        {getTypeName(insight.insight_type)}
                      </span>
                      {insight.piority && (
                        <span className="text-xs text-gray-500">{insight.piority}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          {debugInfo && (
            <div className="mt-4 border-t pt-4 text-xs text-gray-500">
              <details>
                <summary className="cursor-pointer font-medium">Debug info</summary>
                <pre className="mt-2 bg-gray-50 p-2 rounded overflow-auto">
                  {JSON.stringify(debugInfo, null, 2)}
                </pre>
              </details>
            </div>
          )}
        </div>
      </div>
    </>
  );
} 