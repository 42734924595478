import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, ClockIcon } from '@heroicons/react/24/solid';
import { PlusIcon, UserIcon, CheckCircleIcon, UserGroupIcon, LinkIcon, DocumentTextIcon, ChartBarIcon, TagIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline';

export function InsightViewDrawer({ isOpen, onClose, insight, phaseId }) {
  const [shouldRender, setShouldRender] = useState(false);
  const [animationClass, setAnimationClass] = useState('translate-x-full');
  const isMountingRef = useRef(true);

  // Handle mounting/unmounting for animation
  useEffect(() => {
    if (isOpen) {
      // First render the component
      setShouldRender(true);
      
      // Wait a tiny bit for the browser to process the initial render with translate-x-full
      const timer = setTimeout(() => {
        setAnimationClass('translate-x-0');
      }, 10);
      return () => clearTimeout(timer);
    } else {
      // Start the closing animation
      setAnimationClass('translate-x-full');
      
      // Then remove from DOM after animation completes
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  if (!shouldRender && !isOpen) return null;

  // Handle empty insight case
  if (!insight) {
    return (
      <div className="fixed inset-0 overflow-hidden z-50">
        <div className="absolute inset-0 overflow-hidden">
          <div 
            className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out ${
              isOpen ? 'opacity-100' : 'opacity-0'
            }`} 
            onClick={handleClose} 
          />
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
            <div 
              className={`w-screen max-w-md pointer-events-auto transform transition-transform duration-300 ease-in-out ${animationClass}`}
            >
              <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                <div className="px-4 py-6 sm:px-6 flex justify-between items-center border-b border-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">
                    Insight Details
                  </h2>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={handleClose}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
                <div className="relative flex-1 px-4 sm:px-6 py-6 flex justify-center items-center">
                  <p className="text-gray-500">No insight selected</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const insightData = insight.insight_data || insight;
  
  // Function to parse EditorJS content
  const getReadableContent = (content) => {
    if (typeof content === 'string' && (content.startsWith('{') || content.startsWith('['))) {
      try {
        const parsed = JSON.parse(content);
        if (parsed.blocks) {
          return parsed.blocks
            .map(block => {
              if (block.type === 'paragraph') return block.data.text;
              if (block.type === 'header') return block.data.text;
              if (block.type === 'list') return block.data.items.join(', ');
              return '';
            })
            .filter(Boolean)
            .join(' ');
        }
        return JSON.stringify(parsed);
      } catch (e) {
        return content;
      }
    }
    return content;
  };

  // Get content based on insight type
  const getInsightContent = () => {
    if (insightData.single_painpoint) {
      return getReadableContent(insightData.single_painpoint);
    } else if (insightData.single_gain) {
      return getReadableContent(insightData.single_gain);
    } else if (insightData.single_job_to_be_done) {
      return getReadableContent(insightData.single_job_to_be_done);
    } else if (insightData.description) {
      return getReadableContent(insightData.description);
    }
    return 'No description available';
  };

  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <div 
          className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out ${
            isOpen ? 'opacity-100' : 'opacity-0'
          }`} 
          onClick={handleClose} 
        />
        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
          <div 
            className={`w-screen max-w-md pointer-events-auto transform transition-transform duration-300 ease-in-out ${animationClass}`}
          >
            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto">
              {/* Header */}
              <div className="px-4 py-4 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-lg font-medium">{insightData.insight_title || 'Insight Details'}</h2>
                <button 
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={handleClose}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              
              {/* Content */}
              <div className="flex-1 overflow-y-auto">
                {/* Title Section */}
                <div className="px-4 py-5">
                  <h3 className="text-lg font-medium mb-4 flex items-center">

                    Title
                  </h3>
                  <div className="text-sm text-gray-900">
                    {getInsightContent()}
                  </div>
                </div>

                {/* Details Section */}
                <div className="px-4 py-5 border-t border-gray-200">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium">Details</h3>
                  </div>

                  <div className="space-y-4">
                    {/* Type */}
                    <div className="flex items-center">
                      <ChartBarIcon className="h-4 w-4 mr-2 text-gray-400" />
                      <span className="text-sm text-gray-600 w-24">Type</span>
                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium
                        ${insightData.insight_type === 'pain' ? 'bg-red-50 text-red-700' : 
                        insightData.insight_type === 'success' ? 'bg-green-50 text-green-700' : 
                        insightData.insight_type === 'job' ? 'bg-purple-50 text-purple-700' :
                        'bg-blue-50 text-blue-700'}`}>
                        {insightData.insight_type === 'pain' ? 'Pain' : 
                         insightData.insight_type === 'success' ? 'Gain' : 
                         insightData.insight_type === 'job' ? 'Job To Be Done' : 
                         'Insight'}
                      </span>
                    </div>

                    {/* Priority */}
                    {insightData.piority && (
                      <div className="flex items-center">
                        <TagIcon className="h-4 w-4 mr-2 text-gray-400" />
                        <span className="text-sm text-gray-600 w-24">Priority</span>
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium
                          ${insightData.piority === 'high' ? 'bg-red-50 text-red-700' : 
                          insightData.piority === 'medium' ? 'bg-yellow-50 text-yellow-700' : 
                          'bg-green-50 text-green-700'}`}>
                          {insightData.piority.charAt(0).toUpperCase() + insightData.piority.slice(1)}
                        </span>
                      </div>
                    )}

                    {/* User Created */}
                    <div className="flex items-center">
                      <UserIcon className="h-4 w-4 mr-2 text-gray-400" />
                      <span className="text-sm text-gray-600 w-24">Created by</span>
                      <div className="flex items-center">
                        {insightData.user_created ? (
                          <img
                            src={`/api/users/${insightData.user_created}/avatar`}
                            alt="User avatar"
                            className="h-6 w-6 rounded-full mr-2"
                          />
                        ) : (
                          <div className="h-6 w-6 rounded-full bg-gray-200 mr-2 flex items-center justify-center">
                            <UserIcon className="h-4 w-4 text-gray-400" />
                          </div>
                        )}
                        <span className="text-sm text-gray-900">
                          {insightData.user_created || 'Unknown User'}
                        </span>
                      </div>
                    </div>

                    {/* Created Date */}
                    <div className="flex items-center">
                      <ClockIcon className="h-4 w-4 mr-2 text-gray-400" />
                      <span className="text-sm text-gray-600 w-24">Created</span>
                      <span className="text-sm text-gray-900">
                        {insightData.date_created ? 
                          new Date(insightData.date_created).toLocaleString('pl-PL', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          }) : 
                          'No date'}
                      </span>
                    </div>

                    {/* Link to Insight */}
                    <div className="flex items-center">
                      <LinkIcon className="h-4 w-4 mr-2 text-gray-400" />
                      <span className="text-sm text-gray-600 w-24">Link</span>
                      <a 
                        href={`/insights/${insightData.id}`} 
                        className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View insight details
                      </a>
                    </div>

                    {/* Source - Add this before the custom fields section */}
                    {insightData.source && (
                      <div className="flex items-center">
                        <BuildingLibraryIcon className="h-4 w-4 mr-2 text-gray-400" />
                        <span className="text-sm text-gray-600 w-24">Source</span>
                        <span className="text-sm text-gray-900">
                          {insightData.source}
                        </span>
                      </div>
                    )}

                    {/* Custom Fields */}
                    {Object.entries(insightData)
                      .filter(([key, value]) => 
                        typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
                      .filter(([key]) => 
                        !['id', 'description', 'recommendation', 'piority', 'priority', 
                          'insight_type', 'count', 'date_created', 'project', 'source'].includes(key))
                      .map(([key, value]) => (
                        <div key={key} className="flex items-center">
                          <TagIcon className="h-4 w-4 mr-2 text-gray-400" />
                          <span className="text-sm text-gray-600 w-24">
                            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </span>
                          <span className="text-sm text-gray-900">
                            {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 