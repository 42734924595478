import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Snackbar from '../Atoms/snackbar';
import { format, parseISO } from 'date-fns';
import { 
  DIRECTUS_PERSONAS_ENDPOINT, 
  DIRECTUS_USER_JOURNEYS_ENDPOINT,
  DIRECTUS_HEATMAPS_ENDPOINT,
  DIRECTUS_INSTANCE,
  DIRECTUS_PROJECTS_ENDPOINT
} from '../../api';

// Import our new citation components
import CitationTool from '../Editor/CitationTool';
import CitationDrawer from '../Editor/CitationDrawer';
import MotivationsGrid from './MotivationsGrid';
import InsightGrid from './InsightGrid';

import {
  CloudArrowUpIcon,
  ArrowLeftIcon,
  EllipsisVerticalIcon,
  FaceSmileIcon,
  PhotoIcon,
  FolderArrowDownIcon,
  TrashIcon,
  ArrowPathIcon,
  PresentationChartBarIcon,
  EyeIcon,
  MapPinIcon,
  MapIcon,
} from '@heroicons/react/24/outline';

const DIRECTUS_LINKED_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/linked_user_journeys`;
const DIRECTUS_LINKED_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/linked_heatmaps`;

function PersonasDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personas, setPersonas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const token = localStorage.getItem('directus_token');
  const autosaveTimeoutRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userJourneys, setUserJourneys] = useState([]);
  const [heatmaps, setHeatmaps] = useState([]);
  const [projects, setProjects] = useState([]);
  const [linkedJourneys, setLinkedJourneys] = useState([]);
  
  // Add citation state variables
  const [citationDrawerOpen, setCitationDrawerOpen] = useState(false);
  const [activeCitation, setActiveCitation] = useState(null);

  const editorRefs = useRef({
    biography: null,
    motivations: null,
    needs: null,
    frustrations: null,
  });

  const goBack = () => {
    navigate('/personas');
  };

  const fetchPersonas = async () => {
    try {
      console.log(`Fetching persona ${id}...`);
      const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, {
        headers: { 
          Authorization: `Bearer ${token}`
        },
        params: {
          fields: [
            '*', 
            'project.id',
            'project.project_name',
            'linked_user_journeys.*',
            'linked_user_journeys.id',
            'linked_user_journeys.userjourney_id.*',
            'linked_user_journeys.userjourney_id.id',
            'linked_user_journeys.userjourney_id.user_journey_name'
          ]
        }
      });
      
      console.log("PERSONA DATA:", response.data.data);
      console.log("LINKED JOURNEYS:", response.data.data.linked_user_journeys);
      
      setPersonas(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching personas:', error);
      setError('Failed to fetch personas');
      setLoading(false);
    }
  };

  const fetchUserJourneys = async (timestamp = Date.now()) => {
    try {
      const response = await axios.get(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          _t: timestamp
        }
      });
      setUserJourneys(response.data.data);
    } catch (error) {
      console.error('Error fetching user journeys:', error);
    }
  };

  const fetchHeatmaps = async (timestamp = Date.now()) => {
    try {
      const response = await axios.get(`${DIRECTUS_HEATMAPS_ENDPOINT}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHeatmaps(response.data.data);
    } catch (error) {
      console.error('Error fetching heatmaps:', error);
    }
  };

  const fetchProjects = async (timestamp = Date.now()) => {
    try {
      const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            user_created: {
              _eq: "$CURRENT_USER"
            }
          }
        }
      });
      setProjects(response.data.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchLinkedJourneys = async () => {
    try {
      const junctionResponse = await axios.get(`${DIRECTUS_INSTANCE}/items/userjourney_personas`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { personas_id: id },
          fields: ['id', 'userjourney_id.*']
        }
      });
      
      console.log("JUNCTION TABLE RESPONSE:", junctionResponse.data.data);
      
      const linkedJourneys = junctionResponse.data.data.map(relation => ({
        id: relation.id,
        userjourney_id: relation.userjourney_id
      }));
      
      console.log("LINKED JOURNEYS:", linkedJourneys);
      return linkedJourneys;
    } catch (error) {
      console.error('Error fetching linked journeys:', error);
      return [];
    }
  };

  // Add citation event listener
  useEffect(() => {
    const handleCitationClick = (event) => {
      setActiveCitation({
        citationNumber: event.detail.citationNumber,
        source: event.detail.source,
        citation: "Citation text from source file" // In a real implementation, this would be fetched
      });
      setCitationDrawerOpen(true);
    };

    document.addEventListener('openCitationDrawer', handleCitationClick);

    return () => {
      document.removeEventListener('openCitationDrawer', handleCitationClick);
    };
  }, []);

  const initializeEditors = useCallback(() => {
    ['biography'].forEach((field) => {
      if (!editorRefs.current[field]) {
        const editorData = typeof personas[field] === 'string' ? JSON.parse(personas[field]) : personas[field];
        editorRefs.current[field] = new EditorJS({
          holder: `${field}-editor`,
          data: editorData,
          minHeight: 0,
          tools: {
            header: {
              class: Header,
              inlineToolbar: ['link', 'citation'],
              config: {
                placeholder: 'Enter a header',
                levels: [2, 3],
                defaultLevel: 2
              }
            },
            paragraph: {
              class: Paragraph,
              inlineToolbar: ['citation']
            },
            list: {
              class: List,
              inlineToolbar: ['citation']
            },
            citation: {
              class: CitationTool,
            }
          },
          onReady: () => {
            console.log(`${field} editor is ready`);
          },
          onChange: () => {
            if (autosaveTimeoutRef.current) {
              clearTimeout(autosaveTimeoutRef.current);
            }
            autosaveTimeoutRef.current = setTimeout(() => handleEditorChange(field), 2000);
          }
        });
      }
    });
  }, [personas]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('directus_token'); // Re-fetch token in the effect
      if (!token) {
        setError('Authentication token missing. Please log in again.');
        setLoading(false);
        return;
      }
      
      try {
        // Fetch all necessary data
        await fetchPersonas();
        await fetchUserJourneys();
        await fetchHeatmaps();
        await fetchProjects();
        await fetchLinkedJourneys().then(setLinkedJourneys);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (personas) {
      initializeEditors();
    }
  }, [personas, initializeEditors]);

  useEffect(() => {
    if (id) {
      fetchLinkedJourneys().then(setLinkedJourneys);
    }
  }, [id]);

  const handleEditorChange = async (field) => {
    if (editorRefs.current[field]) {
      try {
        const outputData = await editorRefs.current[field].save();
        const updatedPersonas = {
          ...personas,
          [field]: JSON.stringify(outputData),
          date_updated: new Date().toISOString()
        };
        setPersonas(updatedPersonas);
        await savePersonaData(updatedPersonas);
      } catch (error) {
        console.error(`Error saving editor content for ${field}:`, error);
      }
    }
  };

  const savePersonaData = async (dataToSave) => {
    try {
      const response = await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, dataToSave, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      console.log("Received response:", response.data);

      if (response.status === 200) {
        setSnackbarMessage('Persona has been saved properly');
        setSnackbarType('success');
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 3000);
      } else {
        console.error('Unexpected status code:', response.status);
        alert('Failed to update persona: Unexpected status code');
      }
    } catch (error) {
      console.error('Error updating persona:', error);
      alert(`Failed to update persona: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const deletePersona = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/personas');
    } catch (error) {
      console.error('Error deleting persona:', error);
      setSnackbarMessage('Failed to delete persona');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
    setShowDeleteModal(false);
  };

  const handleHeatmapChange = async (event) => {
    const value = event.target.value;
    if (value === 'new') {
      navigate('/heatmaps/create');
    } else if (value) {
      try {
        if (personas.linked_heatmaps?.some(link => link.heatmap_id?.id === value)) {
          setSnackbarMessage('This Heatmap is already linked');
          setSnackbarType('warning');
          setShowSnackbar(true);
          return;
        }
        
        const updatedPersonas = {
          linked_heatmaps: {
            create: [{ heatmap_id: value }]
          }
        };
        
        console.log("Updating persona with heatmap relation:", updatedPersonas);
        
        const response = await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, updatedPersonas, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });
        
        console.log("Heatmap relation update response:", response.data);
        
        await fetchPersonas();
        await fetchHeatmaps();
        
        setSnackbarMessage('Heatmap linked successfully');
        setSnackbarType('success');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error linking heatmap:', error);
        
        let errorMsg = 'Failed to link heatmap';
        if (error.response?.data?.errors) {
          const errorDetails = error.response.data.errors.map(e => e.message || JSON.stringify(e)).join(', ');
          errorMsg += `: ${errorDetails}`;
        } else if (error.message) {
          errorMsg += `: ${error.message}`;
        }
        
        setSnackbarMessage(errorMsg);
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    }
  };

  const handleProjectChange = async (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects/create-project');
    } else if (value) {
      try {
        const updatedPersonas = {
          ...personas,
          project: value,
          date_updated: new Date().toISOString()
        };
        setPersonas(updatedPersonas);
        await savePersonaData(updatedPersonas);
        await fetchPersonas();
        setSnackbarMessage('Project assigned successfully');
        setSnackbarType('success');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error assigning project:', error);
        setSnackbarMessage('Failed to assign project');
        setSnackbarType('error');
        setShowSnackbar(true);
      }
    }
  };

  const unlinkHeatmap = async (heatmapId) => {
    try {
      const updatedPersonas = {
        linked_heatmaps: {
          delete: personas.linked_heatmaps
            .filter(heatmap => heatmap.heatmap_id?.id === heatmapId)
            .map(heatmap => heatmap.id)
        }
      };
      
      console.log("Removing heatmap relation:", updatedPersonas);
      
      await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, updatedPersonas, {
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` 
        }
      });
      
      await fetchPersonas();
      
      setSnackbarMessage('Heatmap unlinked successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error unlinking heatmap:', error);
      console.log("Error response:", error.response);
      console.log("Full error data:", error.response?.data);
      
      setSnackbarMessage('Failed to unlink Heatmap');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const cleanupDuplicateRelations = async () => {
    try {
      const relationsByJourneyId = {};
      
      personas.linked_user_journeys.forEach(rel => {
        if (rel.userjourney_id) {
          if (!relationsByJourneyId[rel.userjourney_id]) {
            relationsByJourneyId[rel.userjourney_id] = [];
          }
          relationsByJourneyId[rel.userjourney_id].push(rel);
        }
      });
      
      const toDelete = [];
      
      Object.values(relationsByJourneyId).forEach(relations => {
        const [keep, ...duplicates] = relations;
        duplicates.forEach(dup => toDelete.push(dup.id));
      });
      
      personas.linked_user_journeys.forEach(rel => {
        if (rel.userjourney_id === null) {
          toDelete.push(rel.id);
        }
      });
      
      if (toDelete.length > 0) {
        console.log("Cleaning up duplicate/null relations:", toDelete);
        
        const updatedPersonas = {
          linked_user_journeys: {
            delete: toDelete
          }
        };
        
        await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, updatedPersonas, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` 
          }
        });
        
        await fetchPersonas();
        
        setSnackbarMessage('Cleanup successful');
        setSnackbarType('success');
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error('Error cleaning up relations:', error);
    }
  };

  useEffect(() => {
    if (personas?.linked_user_journeys?.length > 0) {
      const hasNulls = personas.linked_user_journeys.some(rel => rel.userjourney_id === null);
      
      const journeyIds = personas.linked_user_journeys
        .filter(rel => rel.userjourney_id) 
        .map(rel => rel.userjourney_id);
      
      const hasDuplicates = new Set(journeyIds).size !== journeyIds.length;
      
      if (hasNulls || hasDuplicates) {
        cleanupDuplicateRelations();
      }
    }
  }, [personas]);

  const unlinkUserJourney = async (relationId) => {
    try {
      await axios.delete(`${DIRECTUS_INSTANCE}/items/userjourney_personas/${relationId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const updatedJourneys = await fetchLinkedJourneys();
      setLinkedJourneys(updatedJourneys);
      
      setSnackbarMessage('User Journey unlinked successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error unlinking user journey:', error);
      setSnackbarMessage('Failed to unlink User Journey');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  if (error) return <div>{error}</div>;
  if (!personas) return <div>Loading...</div>;

  function formatDateUpdated(date) {
    if (!date) return "No update date provided";

    const now = new Date();
    const updatedDate = parseISO(date);

    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else if (diffInSeconds < 604800) {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    } else {
      return format(updatedDate, "dd MMM yyyy");
    }
  }

  const avatarUrl = personas && personas.avatar
    ? `https://panel.reislo.com/assets/${personas.avatar}?width=300&height=300&fit=cover&format=webp`
    : "https://thispersondoesnotexist.com/";

  // Process text to convert [9:12†source] to [1] format 
  const processEditorContent = (content) => {
    if (!content) return content;
    
    // This would need to be implemented based on your specific format
    // For now, we're just returning the original content
    return content;
  };

  return (
    <div className="pb-40 lg:pl-24">
      {showSnackbar && <Snackbar message={snackbarMessage} actionName={actionName} type={snackbarType} onClose={() => setShowSnackbar(false)} />}
      
      {/* Citation Drawer */}
      {activeCitation && (
        <CitationDrawer 
          open={citationDrawerOpen} 
          setOpen={setCitationDrawerOpen}
          citationNumber={activeCitation.citationNumber}
          source={activeCitation.source}
          citation={activeCitation.citation}
        />
      )}
      
      <div className="mx-auto max-w-75xl">
        <div className="flex gap-4 justify-between py-4 items-center mt-4">
          <div className="cursor-pointer" onClick={goBack}>
            <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center ">
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-secondary-500 hover:text-primary-500" role="status">
                <span className="visually-hidden"></span>
              </div>
            ) : (
              <CloudArrowUpIcon className="h-6 w-6 text-secondary-500 hover:text-primary-500" aria-hidden="true" />
            )} 
            <span className="text-sm text-neutral-600 ml-2">Saved {formatDateUpdated(personas.date_updated)}</span>
          </div>
          <div className="flex gap-2 items-center">
            <button 
              className="text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
            >
              <FolderArrowDownIcon className="h-5 w-5" />
              Export
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                We're working on this feature
              </div>
            </button>
            <button onClick={deletePersona} className="text-left px-3 py-2 text-sm rounded-md hover:bg-red-50 text-red-600 flex items-center gap-2">
              <TrashIcon className="h-5 w-5" />
              Delete
            </button>
          </div>
        </div>
      </div>

      <div className="mx-auto pt-4 mt-0 bg-white rounded-lg">
        <div className="flex max-w-5xl mx-auto gap-8">
          <div className="flex-1">
            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="flex items-center gap-8">
                <div className="relative group">
                  <img
                    className="rounded-full w-32"
                    src={avatarUrl}
                    alt="Avatar"
                    onError={(e) => { e.target.onerror = null; e.target.src = "https://thispersondoesnotexist.com/"; }}
                  />
                  <div className="absolute inset-0 flex rounded-full cursor-pointer items-center justify-center bg-primary-500 bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
                    <ArrowPathIcon className="h-12 w-12 text-white" aria-hidden="true" />
                  </div>
                </div>
                <div className="flex flex-col text-left">
                  <h2 className="text-2xl">{personas.name}</h2>
                  <h4 className="text-lg opacity-60">{personas.age} years old</h4>
                </div>
                <div className="flex gap-2 ml-auto">
                  <button 
                    className="text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                  >
                    <FaceSmileIcon className="h-5 w-5" />
                    Empathy Map
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                      We're working on this feature
                    </div>
                  </button>
                  <button 
                    className="text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                  >
                    <PhotoIcon className="h-5 w-5" />
                    Moodboard
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                      We're working on this feature
                    </div>
                  </button>
                  <button 
                    className="text-left px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex items-center gap-2 relative group"
                  >
                    <MapIcon className="h-5 w-5" />
                    User Journey
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
                      We're working on this feature
                    </div>
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-y-2 gap-x-4 mt-8">
                <div className="pb-4">
                  <label className="text-sm opacity-60">Education</label>
                  <h4 className="text-base">{personas.education}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Occupation</label>
                  <h4 className="text-base">{personas.occupation}</h4>
                </div>
                {/*<div className="pb-4">
                  <label className="text-sm opacity-60">Income</label>
                  <h4 className="text-base">${personas.income} yearly</h4>
                </div>*/}
                <div className="pb-4">
                  <label className="text-sm opacity-60">Family Life Cycle</label>
                  <h4 className="text-base">{personas.family_life_cycle}</h4>
                </div>
                {/*<div className="pb-4">
                  <label className="text-sm opacity-60">Family Size</label>
                  <h4 className="text-base">{personas.family_size}</h4>
                </div>*/}
                <div className="pb-4">
                  <label className="text-sm opacity-60">Living</label>
                  <h4 className="text-base">{personas.country}, {personas.city}</h4>
                </div>
                {/* <div className="pb-4">
                  <label className="text-sm opacity-60">Type</label>
                  <h4 className="text-base">{personas.type}</h4>
                </div>*/}
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-6 mb-6">
              <div className="editable-content">
                <div id="biography-editor"></div>
              </div>
            </div>

            <InsightGrid 
              title="Motivations"
              insights={personas.motivations}
              color="#fbbf24"
              onInsightsChange={async (updatedBlocks) => {
                const updatedPersonas = {
                  ...personas,
                  motivations: {
                    blocks: [
                      {
                        type: 'header',
                        data: {
                          text: 'Motivations',
                          level: 2
                        }
                      },
                      {
                        type: 'paragraph',
                        data: {
                          text: ''
                        }
                      },
                      ...updatedBlocks
                    ]
                  }
                };
                setPersonas(updatedPersonas);
                await savePersonaData(updatedPersonas);
              }}
            />

            <InsightGrid 
              title="Needs"
              insights={personas.needs}
              color="#86efac"
              onInsightsChange={async (updatedBlocks) => {
                const updatedPersonas = {
                  ...personas,
                  needs: {
                    blocks: [
                      {
                        type: 'header',
                        data: {
                          text: 'Needs',
                          level: 2
                        }
                      },
                      {
                        type: 'paragraph',
                        data: {
                          text: ''
                        }
                      },
                      ...updatedBlocks
                    ]
                  }
                };
                setPersonas(updatedPersonas);
                await savePersonaData(updatedPersonas);
              }}
            />

            <InsightGrid 
              title="Frustrations"
              insights={personas.frustrations}
              color="#fecaca"
              onInsightsChange={async (updatedBlocks) => {
                const updatedPersonas = {
                  ...personas,
                  frustrations: {
                    blocks: [
                      {
                        type: 'header',
                        data: {
                          text: 'Frustrations',
                          level: 2
                        }
                      },
                      {
                        type: 'paragraph',
                        data: {
                          text: ''
                        }
                      },
                      ...updatedBlocks
                    ]
                  }
                };
                setPersonas(updatedPersonas);
                await savePersonaData(updatedPersonas);
              }}
            />

            <div className="editable-content hidden mb-8 p-4 rounded-xl">
              <h3 className="text-2xl mb-2">MOSAIC Segmentation</h3>
            </div>
            <div className="editable-content mb-8 p-4 rounded-xl hidden">
              <h3 className="text-2xl mb-2">VALS Segmentation</h3>
            </div>
            <div className="editable-content mt-16 bg-grey-100 opacity-60 hidden">
              <p><strong>Prompt (Admin only):</strong> </p>
              <pre className="text-sm whitespace-pre-wrap">{JSON.stringify(JSON.parse(personas.prompt), null, 2)}</pre>
            </div>
          </div>


        </div>
      </div>

      <div className="bg-white border border-secondary-100 rounded-lg p-4">
              <h3 className="text-lg font-medium mb-3">Project Assignment</h3>
              <div className="space-y-2">
                <select
                  className="w-full text-left px-3 py-2 text-sm rounded-md border border-secondary-100"
                  onChange={handleProjectChange}
                  value={personas.project?.id || ""}
                >
                  <option value="" disabled>Select a project</option>
                  {projects.map(project => (
                    <option key={project.id} value={project.id}>
                      {project.project_name}
                    </option>
                  ))}
                  <option value="new-project">Create New Project</option>
                </select>
              </div>
            </div>

            <div className="bg-white border border-secondary-100 rounded-lg p-4 mt-6">
              <h3 className="text-lg font-medium mb-3">Linked User Journeys</h3>
              <div className="space-y-2">
                {linkedJourneys.length > 0 ? 
                  linkedJourneys
                    .filter(journey => journey.userjourney_id?.id && journey.userjourney_id?.user_journey_name)
                    .map(journey => (
                      <div key={journey.id} className="px-3 py-2 text-sm rounded-md hover:bg-secondary-50 flex justify-between items-center">
                        <span className="truncate">{journey.userjourney_id.user_journey_name}</span>
                        <div className="flex gap-2">
                          <button 
                            onClick={() => navigate(`/user-journeys/${journey.userjourney_id.id}`)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            <EyeIcon className="h-4 w-4" />
                          </button>
                          <button 
                            onClick={(e) => {
                              e.stopPropagation();
                              unlinkUserJourney(journey.id);
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    )) : (
                      <p className="text-sm text-secondary-500 px-3">No linked user journeys</p>
                    )
                }
              </div>
            </div>

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-medium mb-4">Delete Persona</h3>
            <p className="mb-6">Are you sure you want to delete this persona? This action cannot be undone.</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PersonasDetails;
