import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronLeftIcon, 
  ChevronRightIcon, 
  ChatBubbleLeftRightIcon, 
  PlusIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/20/solid';
import { 
  TrashIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';
import ContentHeader from '../Atoms/contentHeader';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import { deleteChatSession } from '../../utils/assistantApi';

const ITEMS_PER_PAGE = 10;

function ChatSessionsTable({ onSessionSelect, onSessionsLoaded, emptyState }) {
  const [chatSessions, setChatSessions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const primaryClick = useCallback(() => navigate('/chat/new'), [navigate]);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const fetchChatSessions = async (token, userId, page) => {
    const limit = ITEMS_PER_PAGE;
    const offset = (page - 1) * limit;
    
    try {
      const response = await axios.get(`${DIRECTUS_INSTANCE}/items/chat_sessions`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { user_created: { _eq: userId } },
          sort: ['-date_updated', '-date_created'],
          limit,
          offset,
          fields: ['id', 'title', 'date_created', 'date_updated', 'user_created.first_name', 'user_created.last_name', 'user_created.email']
        }
      });
      
      return {
        data: response.data.data,
        meta: {
          total_count: response.data.meta?.total_count || 0,
          filter_count: response.data.meta?.filter_count || 0
        }
      };
    } catch (error) {
      console.error('Error fetching chat sessions:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const data = await fetchChatSessions(token, userId, currentPage);
        const formattedSessions = data.data.map(session => ({
          id: session.id,
          title: session.title,
          userCreated: session.user_created?.first_name && session.user_created?.last_name
            ? `${session.user_created.first_name} ${session.user_created.last_name}`
            : session.user_created?.email || '',
          dateCreated: new Date(session.date_created),
          dateUpdated: session.date_updated ? new Date(session.date_updated) : null,
        }));

        // Sort sessions by date_updated (if exists) or date_created
        formattedSessions.sort((a, b) => {
          const dateA = a.dateUpdated || a.dateCreated;
          const dateB = b.dateUpdated || b.dateCreated;
          return dateB.getTime() - dateA.getTime();
        });

        setChatSessions(formattedSessions);

        const totalCount = data.meta.total_count;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
        setLoading(false);
      }
    };

    if (token && userId) {
      fetchData();
    }
  }, [currentPage, token, userId]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleRowClick = (sessionId) => {
    if (onSessionSelect) {
      onSessionSelect(sessionId);
    }
  };

  const handleDeleteSession = async (e, sessionId) => {
    e.stopPropagation(); // Prevent row click
    setOpenDropdownId(null); // Close dropdown
    
    if (window.confirm('Are you sure you want to delete this chat session?')) {
      try {
        await deleteChatSession(sessionId);
        // Refresh the list
        const data = await fetchChatSessions(token, userId, currentPage);
        const formattedSessions = data.data.map(session => ({
          id: session.id,
          title: session.title,
          userCreated: session.user_created?.first_name && session.user_created?.last_name
            ? `${session.user_created.first_name} ${session.user_created.last_name}`
            : session.user_created?.email || '',
          dateCreated: new Date(session.date_created),
          dateUpdated: session.date_updated ? new Date(session.date_updated) : null,
        }));

        setChatSessions(formattedSessions);
        
        // Update total pages
        const totalCount = data.meta.total_count;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        
        // If we're on a page that no longer exists after deletion, go to the previous page
        if (currentPage > 1 && formattedSessions.length === 0) {
          setCurrentPage(currentPage - 1);
        }
        
        // Call onSessionsLoaded callback if available
        if (onSessionsLoaded) {
          onSessionsLoaded(formattedSessions);
        }
      } catch (error) {
        console.error('Error deleting chat session:', error);
      }
    }
  };

  const handleDownloadTranscript = (e, sessionId) => {
    e.stopPropagation(); // Prevent row click
    setOpenDropdownId(null); // Close dropdown
    
    // This is just a placeholder for the upcoming feature
    alert('Download transcript feature coming soon!');
  };

  const toggleDropdown = (e, sessionId) => {
    e.stopPropagation(); // Prevent row click
    setOpenDropdownId(openDropdownId === sessionId ? null : sessionId);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setOpenDropdownId(null);
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-white  rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
          </div>
        ) : chatSessions.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12 text-center px-4">
            <div className="mb-4">
              <ChatBubbleLeftRightIcon className="h-12 w-12 text-gray-300" />
            </div>
            <h3 className="text-lg font-medium text-gray-900">No chat sessions found</h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new chat session with the AI assistant.
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={primaryClick}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Start New Chat
              </button>
            </div>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200 mt-8">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created By
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Last Updated
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {chatSessions.map((session) => (
                <tr
                  key={session.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleRowClick(session.id)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center rounded-full bg-primary-100 text-primary-800">
                        <ChatBubbleLeftRightIcon className="h-5 w-5" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{session.title}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{session.userCreated}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{formatDate(session.dateCreated)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{formatDate(session.dateUpdated)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                    <button
                      onClick={(e) => toggleDropdown(e, session.id)}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <EllipsisVerticalIcon className="h-5 w-5" />
                    </button>
                    
                    {openDropdownId === session.id && (
                      <div 
                        className="absolute right-10 top-4 z-10 bg-white shadow-lg rounded-md py-1 w-48"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button 
                          onClick={(e) => handleDeleteSession(e, session.id)}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                          <TrashIcon className="h-4 w-4 mr-2 text-red-600" />
                          Delete
                        </button>
                        <button 
                          onClick={(e) => handleDownloadTranscript(e, session.id)}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                          <ArrowDownTrayIcon className="h-4 w-4 mr-2 text-gray-600" />
                          Download transcript (coming soon)
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!loading && chatSessions.length > 0 && (
          <nav className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, chatSessions.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      aria-current={currentPage === i + 1 ? 'page' : undefined}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === i + 1 ? 'z-10 bg-primary-50 border-primary-500 text-primary-600' : 'text-gray-500 hover:bg-gray-50'}`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => handlePageChange(currentPage + 1)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default ChatSessionsTable; 