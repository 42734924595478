import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { DIRECTUS_INSTANCE } from '../api';
import { DEFAULT_ASSISTANT_ID, SETTINGS } from '../components/Assistant/assistantConfig';

// Konfiguracja API dla asystenta
const API_URL = 'https://api.openai.com/v1';

// Pobieranie danych sesji czatu
const fetchChatSession = async (sessionId) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.get(`${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat session:', error);
    throw error;
  }
};

// Pobieranie ID asystenta
export const getAssistantId = async () => {
  return DEFAULT_ASSISTANT_ID;
};

// Pobieranie ID vector store
export const getVectorStoreId = async () => {
  return SETTINGS.vector_store_id;
};

// Tworzenie nowej sesji czatu
export const createChatSession = async (title, threadId, assistantId = DEFAULT_ASSISTANT_ID, vectorStoreId = SETTINGS.vector_store_id) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.post(`${DIRECTUS_INSTANCE}/items/chat_sessions`, {
      title,
      thread_id: threadId,
      assistant_id: assistantId,
      vector_store_id: vectorStoreId
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating chat session:', error);
    throw error;
  }
};

// Aktualizacja sesji czatu
export const updateChatSession = async (sessionId, data) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.patch(`${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating chat session:', error);
    throw error;
  }
};

// Tworzenie wiadomości czatu
export const createChatMessage = async (sessionId, role, content) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.post(`${DIRECTUS_INSTANCE}/items/chat_messages`, {
      session_id: sessionId,
      role,
      content
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating chat message:', error);
    throw error;
  }
};

// Pobieranie sesji czatu
export const getChatSessions = async () => {
  try {
    const token = localStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');
    
    const response = await axios.get(`${DIRECTUS_INSTANCE}/items/chat_sessions`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        filter: { user_created: { id: { _eq: userId } } },
        sort: '-date_created',
        fields: '*'
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat sessions:', error);
    return [];
  }
};

// Tworzenie nowego wątku w OpenAI
export const createThread = async () => {
  try {
    console.log("Calling createThread API...");
    const API_KEY = process.env.REACT_APP_GPT_API_KEY;
    if (!API_KEY) {
      console.error("OpenAI API key not found in environment variables");
      throw new Error("API key missing");
    }
    
    console.log("Making OpenAI API request to create thread...");
    const response = await axios.post(
      `${API_URL}/threads`,
      {}, // Puste body dla utworzenia wątku
      {
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );
    
    console.log("Thread created successfully:", response.data.id);
    return response.data.id;
  } catch (error) {
    console.error('Error creating thread:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw error;
  }
};

// Dodawanie wiadomości do wątku
export const addMessageToThread = async (threadId, message) => {
  try {
    const API_KEY = process.env.REACT_APP_GPT_API_KEY;
    const response = await axios.post(`${API_URL}/threads/${threadId}/messages`, message, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
        'OpenAI-Beta': 'assistants=v2'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error adding message to thread:', error);
    throw error;
  }
};

// Uruchamianie asystenta
export const runAssistant = async (threadId, assistantId, parameters = {}) => {
  try {
    const API_KEY = process.env.REACT_APP_GPT_API_KEY;
    console.log("Running assistant with parameters:", {
      threadId,
      assistantId,
      parametersKeys: Object.keys(parameters)
    });
    
    // Sprawdź, czy mamy prawidłowe dane wejściowe
    if (!threadId || !assistantId) {
      throw new Error(`Missing required parameters: threadId=${threadId}, assistantId=${assistantId}`);
    }
    
    // Przygotuj podstawowe dane dla run
    const runData = {
      assistant_id: assistantId
    };
    
    // Dodaj dodatkowe parametry, ale zmodyfikuj format file_search jeśli istnieje
    if (parameters.file_search) {
      // W najnowszej wersji API OpenAI parametr file_search nie jest już przekazywany 
      // w ten sam sposób - assistants=v2 ma inne wymagania
      // Usuwamy parametr file_search i używamy tools
      console.log("Removing file_search parameter - not needed with assistants=v2");
    }
    
    // Dodaj inne parametry, jeśli są
    for (const key in parameters) {
      if (key !== 'file_search') {
        runData[key] = parameters[key];
      }
    }
    
    console.log("Run payload:", JSON.stringify(runData));
    
    const response = await axios.post(
      `${API_URL}/threads/${threadId}/runs`,
      runData,
      {
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
          'OpenAI-Beta': 'assistants=v2'
        }
      }
    );
    
    console.log("Run created successfully:", response.data.id);
    return response.data;
  } catch (error) {
    console.error('Error running assistant:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw error;
  }
};

// Sprawdzanie statusu przebiegu
export const checkRunStatus = async (threadId, runId) => {
  try {
    const API_KEY = process.env.REACT_APP_GPT_API_KEY;
    const response = await axios.get(`${API_URL}/threads/${threadId}/runs/${runId}`, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
        'OpenAI-Beta': 'assistants=v2'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error checking run status:', error);
    throw error;
  }
};

// Pobieranie wiadomości z wątku
export const listMessages = async (threadId) => {
  try {
    const API_KEY = process.env.REACT_APP_GPT_API_KEY;
    const response = await axios.get(`${API_URL}/threads/${threadId}/messages`, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
        'OpenAI-Beta': 'assistants=v2'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error listing messages:', error);
    throw error;
  }
};

// Zapisywanie wiadomości czatu w Directus
export const saveChatMessage = async (sessionId, messageData) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.post(`${DIRECTUS_INSTANCE}/items/chat_messages`, {
      session_id: sessionId,
      ...messageData
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error saving chat message:', error);
    throw error;
  }
};

// Pobieranie wiadomości czatu z Directus
export const getChatMessages = async (sessionId) => {
  try {
    const token = localStorage.getItem('directus_token');
    const response = await axios.get(`${DIRECTUS_INSTANCE}/items/chat_messages`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        filter: { session_id: { _eq: sessionId } },
        sort: 'date_created',
        fields: '*'
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    return [];
  }
};

// Usuwanie sesji czatu
export const deleteChatSession = async (sessionId) => {
  try {
    const token = localStorage.getItem('directus_token');
    await axios.delete(`${DIRECTUS_INSTANCE}/items/chat_sessions/${sessionId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return true;
  } catch (error) {
    console.error('Error deleting chat session:', error);
    throw error;
  }
}; 