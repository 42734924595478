import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useData } from '../../api/DataContext';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT, DIRECTUS_INSIGHTS_ENDPOINT,DIRECTUS_INSTANCE, DIRECTUS_USER_JOURNEY_PHASES_ENDPOINT, DIRECTUS_JOURNEY_PHASE_PAINPOINTS_ENDPOINT, DIRECTUS_JOURNEY_PHASE_GAINS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import JourneyItemDrawer from './JourneyItemDrawer';
import PhaseDrawer from './PhaseDrawer';
import { InsightViewDrawer } from './InsightViewDrawer';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  useDroppable,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { InsightSelector } from './InsightSelector';
import { Bars3Icon, ExclamationCircleIcon, CheckCircleIcon, ChatBubbleLeftRightIcon, ArrowRightIcon, UserIcon, PlusIcon, MapIcon, LightBulbIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { CSS } from '@dnd-kit/utilities';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Menu } from '@headlessui/react';

// Przenieś funkcję parseEditorContent na poziom modułu (poza komponenty)
const parseEditorContent = (content) => {
  if (!content) return '';
  
  try {
    // Sprawdź czy to JSON
    const parsed = typeof content === 'string' ? JSON.parse(content) : content;
    
    // Sprawdź czy ma format EditorJS z blokami
    if (parsed && parsed.blocks) {
      return parsed.blocks
        .map(block => {
          if (block.type === 'paragraph') {
            return block.data.text;
          }
          if (block.type === 'header') {
            return block.data.text;
          }
          return '';
        })
        .filter(text => text) // usuń puste teksty
        .join(' '); // połącz wszystkie bloki z odstępami
    }
    
    // Jeśli to zwykły string, po prostu go zwróć
    return typeof parsed === 'string' ? parsed : content;
  } catch (e) {
    // Jeśli nie udało się sparsować jako JSON, zwróć oryginalny tekst
    return content;
  }
};

function PhaseDropIndicator({ position, isActive }) {
  if (!isActive) return null;
  
  return (
    <div 
      className={`absolute ${position === 'left' ? 'left-0' : 'right-0'} top-0 bottom-0 w-1 bg-primary-600 animate-pulse z-50`}
      style={{ 
        height: '100%',
        opacity: 0.8,
        boxShadow: '0 0 8px rgba(79, 70, 229, 0.6)'
      }}
    />
  );
}

function SortablePhase({ id, phase, index, onPhaseClick, dropIndicator }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      onPhaseClick(index);
    }
  };

  const showLeftIndicator = dropIndicator && 
    dropIndicator.phase === index && 
    dropIndicator.position === 'left';
  
  const showRightIndicator = dropIndicator && 
    dropIndicator.phase === index && 
    dropIndicator.position === 'right';

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`w-80 border-l border-grey-100 bg-white pl-3 pt-2 text-sm
        hover:bg-gray-50 transition-all duration-200 relative
        ${showLeftIndicator || showRightIndicator ? 'ring-2 ring-primary-300 ring-opacity-50' : ''}`}
    >
      <PhaseDropIndicator position="left" isActive={showLeftIndicator} />
      <PhaseDropIndicator position="right" isActive={showRightIndicator} />
      
      <div {...attributes} {...listeners} className="absolute inset-0 cursor-move" />
      <div 
        onClick={handleClick}
        className="px-2 pt-1.5 cursor-pointer relative z-10"
      >
        {phase.phase_name}
      </div>
    </div>
  );
}

function SortableItem({ id, item, type, handleInsightClick, phaseIndex }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const [isLoading, setIsLoading] = useState(true);
  const [hasContent, setHasContent] = useState(false);

  // Check if content is ready on mount and when item changes
  useEffect(() => {
    // Short artificial delay to ensure smooth transitions
    const timer = setTimeout(() => {
      const content = parseEditorContent(item.insight_data?.description);
      setHasContent(content && content !== 'No description available');
      setIsLoading(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [item]);

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
    height: '160px',
  };

  const getTypeStyles = () => {
    switch (type) {
      case 'painpoint':
        return {
          bg: 'bg-red-50',
          border: 'border border-red-200',
          tag: 'text-red-700 bg-red-100'
        };
      case 'gain':
        return {
          bg: 'bg-green-50',
          border: 'border border-green-200',
          tag: 'text-green-700 bg-green-100'
        };
      case 'job_to_be_done':
        return {
          bg: 'bg-purple-50',
          border: 'border border-purple-200',
          tag: 'text-purple-700 bg-purple-100'
        };
      default:
        return {
          bg: 'bg-gray-50',
          border: 'border border-gray-200',
          tag: 'text-gray-700 bg-gray-100'
        };
    }
  };

  const styles = getTypeStyles();

  // Add the click handler to open drawer with details
  const handleItemClick = (e) => {
    // Prevent click from triggering drag events
    e.stopPropagation();
    // Only handle click if we're not in the middle of a drag operation
    if (handleInsightClick && !e.defaultPrevented) {
      handleInsightClick(item, phaseIndex);
    }
  };

  // Get priority if available
  const getPriority = () => {
    return item.insight_data?.piority || item.priority || '';
  };

  // Get the display count (from actual data if available)
  const getDisplayCount = () => {
    return item.count || item.insight_data?.count || Math.floor(Math.random() * 5) + 1;
  };
  
  // Get project name if available
  const getProjectName = () => {
    return item.insight_data?.project?.project_name || '';
  };
  
  // Get insight type from data
  const getInsightType = () => {
    return item.insight_data?.insight_type || (type === 'gain' ? 'gain' : 'pain');
  };

  // Shimmer loader component
  const TextShimmer = () => (
    <div className="animate-pulse space-y-2">
      <div className="h-2 bg-gray-200 rounded w-3/4"></div>
      <div className="h-2 bg-gray-200 rounded w-5/6"></div>
      <div className="h-2 bg-gray-200 rounded w-2/3"></div>
    </div>
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`relative rounded-md shadow-sm ${styles.border} hover:shadow-md p-3 cursor-pointer flex w-full flex-col ${styles.bg} overflow-hidden`}
      onClick={handleItemClick}
    >
      <div className="flex flex-1 flex-col justify-between h-full">
        <div>
          <div className="mb-1.5 flex items-center justify-between">
            <span className={`inline-flex text-xs font-medium px-1.5 py-0.5 rounded ${styles.tag}`}>
              {type === 'painpoint' ? 'Pain point' : type === 'gain' ? 'Gain' : 'Job To Be Done'}
            </span>
            {getPriority() && (
              <span className="text-xs bg-gray-100 text-gray-700 px-1.5 py-0.5 rounded font-medium capitalize">
                {getPriority()}
              </span>
            )}
          </div>
          <div className="h-[60px] overflow-hidden">
            {isLoading ? (
              <TextShimmer />
            ) : (
              <p className="text-sm text-gray-800 line-clamp-3 font-medium">
                {hasContent ? parseEditorContent(item.insight_data?.description) : 'No description available'}
              </p>
            )}
          </div>
        </div>
        <div className="mt-auto pt-2 flex items-center justify-between">
          <div className="flex items-center gap-1">
            {isLoading ? (
              <div className="h-5 w-10 bg-gray-200 rounded animate-pulse"></div>
            ) : (
              <span className={`group relative text-xs leading-tight font-semibold px-1.5 py-0.5 rounded flex items-center gap-1 ${styles.counter}`}>
                <ArrowRightIcon className="h-3 w-3" />
                {getDisplayCount()}
              </span>
            )}
          </div>
          <div {...listeners} className="ml-auto cursor-grab">
            <Bars3Icon className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>
    </div>
  );
}

function DroppableContainer({ children, id, phaseIndex }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOver, setNodeRef } = useDroppable({ 
    id: `container-${id}-${phaseIndex}`,
    data: {
      phaseIndex,
      type: id.startsWith('painpoint') ? 'painpoint' : 'gain'
    }
  });
  
  // Get the items and "Add" button from children
  const childrenArray = React.Children.toArray(children);
  const sortableContext = childrenArray.find(child => child.type === SortableContext);
  const addButton = childrenArray.find(child => child.type !== SortableContext);
  
  // If we have a SortableContext, count its children items
  const items = React.Children.toArray(sortableContext?.props.children || []);
  const itemCount = items.length;
  const hasMoreItems = itemCount > 5;
  
  // Show only first 5 items when not expanded
  const visibleItems = isExpanded ? items : items.slice(0, 5);
  
  return (
    <div
      ref={setNodeRef}
      className={`flex flex-col gap-1 w-80 p-1.5 transition-all duration-200 border-l border-t pb-16 border-grey-100 bg-white
        ${isOver ? 'border border-blue-400 bg-blue-50' : ''}`}
      style={{ minHeight: '150px' }}
    >
      {sortableContext && (
        <SortableContext {...sortableContext.props}>
          {visibleItems}
        </SortableContext>
      )}
      
      {hasMoreItems && !isExpanded && (
        <button
          onClick={() => setIsExpanded(true)}
          className="mt-2 p-2 text-sm text-gray-900 bg-gray-100 rounded flex items-center justify-center hover:bg-gray-200 transition-all duration-200"
        >
          Show all ({itemCount})
        </button>
      )}
      
      {hasMoreItems && isExpanded && (
        <button
          onClick={() => setIsExpanded(false)}
          className="mt-2 p-2 text-sm text-gray-900 bg-gray-100 rounded flex items-center justify-center hover:bg-gray-200 transition-all duration-200"
        >
          Show less
        </button>
      )}
      
      {addButton}
    </div>
  );
}

const NoPersonasMessage = ({ onAddClick }) => (
  <div className="flex flex-col items-center justify-center p-4 text-center">
    <UserIcon className="h-8 w-8 text-gray-400 mb-2" />
    <p className="text-sm text-gray-500 mb-2">No personas assigned to this journey</p>
    <button
      onClick={onAddClick}
      className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700"
    >
      <PlusIcon className="h-4 w-4 mr-1" />
      Add Personas
    </button>
  </div>
);

const PersonaSelector = ({ isOpen, onClose, onSelect, currentPersonas }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [personas, setPersonas] = useState([]);
  const token = localStorage.getItem('directus_token');

  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await axios.get(DIRECTUS_PERSONAS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['id', 'name', 'avatar.id', 'type', 'occupation']
          }
        });
        setPersonas(response.data.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };
    if (isOpen) {
      fetchPersonas();
    }
  }, [isOpen, token]);

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl w-full bg-white rounded-xl shadow-2xl p-6">
          <div className="flex items-center justify-between mb-6">
            <Dialog.Title className="text-xl font-semibold text-gray-900">
              Select Personas
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="space-y-2 max-h-[60vh] overflow-y-auto pr-2 custom-scrollbar">
            {personas.map(persona => (
              <label
                key={persona.id}
                className={`flex items-center p-3 rounded-lg cursor-pointer transition-all
                  ${selectedIds.includes(persona.id) ? 'bg-primary-50 border border-primary-200' : 'hover:bg-gray-50 border border-transparent'}`}
              >
                <div className="flex items-center flex-1 min-w-0">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={persona.avatar?.id 
                        ? `https://panel.reislo.com/assets/${persona.avatar.id}?width=80&height=80&fit=cover&format=webp`
                        : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                      }
                      alt=""
                    />
                  </div>
                  <div className="ml-4 flex-1 min-w-0">
                    <div className="text-sm font-medium text-gray-900 truncate">{persona.name}</div>
                    <div className="text-sm text-gray-500 truncate">{persona.occupation || persona.type}</div>
                  </div>
                  <input
                    type="checkbox"
                    checked={selectedIds.includes(persona.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedIds([...selectedIds, persona.id]);
                      } else {
                        setSelectedIds(selectedIds.filter(id => id !== persona.id));
                      }
                    }}
                    className="h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                  />
                </div>
              </label>
            ))}
          </div>

          <div className="mt-6 flex items-center justify-end gap-3 pt-4 border-t border-gray-200">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>
            <button
              onClick={() => onSelect(selectedIds)}
              className="px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Add Selected ({selectedIds.length})
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

function CustomerJourneyTimeline() {
  const { id } = useParams();
  const { journeys, setJourneys, loading, setLoading, error, setError } = useData();
  const userID = localStorage.getItem('user_id');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  const [activePhaseIndex, setActivePhaseIndex] = useState(null);
  const [editData, setEditData] = useState(null);
  const [isPhaseDrawerOpen, setIsPhaseDrawerOpen] = useState(false);
  const [activePhase, setActivePhase] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isInsightSelectorOpen, setIsInsightSelectorOpen] = useState(false);
  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(null);
  const [selectorType, setSelectorType] = useState(null);
  const navigate = useNavigate();
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [isInsightViewDrawerOpen, setIsInsightViewDrawerOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const [isDragLoading, setIsDragLoading] = useState(false);
  const queryClient = useQueryClient();
  const [token] = useState(localStorage.getItem('directus_token'));
  const [linkedPersonas, setLinkedPersonas] = useState([]);
  const [currentJourney, setCurrentJourney] = useState(null);
  const [phases, setPhases] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [journeyName, setJourneyName] = useState('');
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [journeyDescription, setJourneyDescription] = useState('');
  const [showPhaseDrawer, setShowPhaseDrawer] = useState(false);
  const [isPersonaSelectorOpen, setIsPersonaSelectorOpen] = useState(false);
  const [availablePersonas, setAvailablePersonas] = useState([]);
  const [dropIndicator, setDropIndicator] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    phases: true,
    emotionalCurve: true,
    painpoints: true,
    gains: true,
    jobs_to_be_done: true
  });

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const saveJourney = async (updatedJourney) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      // Przygotuj dane faz z zachowaniem ich kolejności
      const phases = updatedJourney.phase.map((phase, index) => {
        // Zachowaj istniejący ID fazy, jeśli istnieje
        const phaseObj = {
          ...(phase.id ? { id: phase.id } : {}),
          phase_name: phase.phase_name,
          emotional_curve: phase.emotional_curve,
          order_value: index + 1, // Kolejność oparta na indeksie
        };
        
        // Dodaj painpoints, gains i jobs_to_be_done tylko jeśli istnieją
        if (phase.painpoints && phase.painpoints.length > 0) {
          phaseObj.painpoints = phase.painpoints.map(p => ({
            ...(p.id ? { id: p.id } : {}),
            insight_id: typeof p.insight_id === 'string' ? p.insight_id : p.insight_id?.id
          }));
        }
        
        if (phase.gains && phase.gains.length > 0) {
          phaseObj.gains = phase.gains.map(g => ({
            ...(g.id ? { id: g.id } : {}),
            insight_id: typeof g.insight_id === 'string' ? g.insight_id : g.insight_id?.id
          }));
        }
        
        if (phase.jobs_to_be_done && phase.jobs_to_be_done.length > 0) {
          phaseObj.jobs_to_be_done = phase.jobs_to_be_done.map(j => ({
            ...(j.id ? { id: j.id } : {}),
            insight_id: typeof j.insight_id === 'string' ? j.insight_id : j.insight_id?.id
          }));
        }
        
        return phaseObj;
      });

      // Wysyłamy zaktualizowane dane do API
      await axios.patch(
        `${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${updatedJourney.id}`,
        {
          user_journey_name: updatedJourney.user_journey_name,
          user_journey_description: updatedJourney.user_journey_description,
          phase: phases
        },
        config
      );
      
      console.log("Journey successfully saved with updated phases order");
      return updatedJourney;
    } catch (error) {
      console.error('Save Error:', error);
      console.error('Error response data:', error.response?.data);
      throw new Error('Failed to save changes');
    }
  };

  const fetchJourney = async () => {
    try {
      const response = await axios.get(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          fields: [
            '*',
            'linked_project.*',
            'linked_project.project_name',
            'owner.*',
            'linked_personas.*',
            'linked_personas.personas_id.*',
            'linked_personas.personas_id.id',
            'linked_personas.personas_id.name',
            'linked_personas.personas_id.avatar.id',
            'linked_personas.personas_id.type',
            'phases.*'
          ]
        }
      });
      
      console.log("JOURNEY DETAILS:", response.data?.data);
      console.log("LINKED PERSONAS DETAILS:", JSON.stringify(response.data?.data?.linked_personas, null, 2));
      
      setCurrentJourney(response.data.data);
      
      // Collect all insight IDs
      const insightIds = new Set();
      response.data.data.phase.forEach(phase => {
        phase.painpoints?.forEach(p => p.insight_id && insightIds.add(p.insight_id));
        phase.gains?.forEach(g => g.insight_id && insightIds.add(g.insight_id));
      });

      if (insightIds.size > 0) {
        const insightsResponse = await axios.get(
          `${DIRECTUS_INSIGHTS_ENDPOINT}?filter[id][_in]=${Array.from(insightIds).join(',')}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        const insightsMap = new Map(
          insightsResponse.data.data.map(insight => [insight.id, insight])
        );

        response.data.data.phase = response.data.data.phase.map(phase => ({
          ...phase,
          painpoints: phase.painpoints?.map(p => ({
            ...p,
            insight_data: insightsMap.get(p.insight_id)
          })) || [],
          gains: phase.gains?.map(g => ({
            ...g,
            insight_data: insightsMap.get(g.insight_id)
          })) || []
        }));
      }

      return response.data.data;
    } catch (error) {
      console.error('Error fetching journey:', error);
      throw new Error('Failed to fetch journey details');
    }
  };

  const fetchPersonasData = async () => {
    try {
      if (!currentJourney?.linked_personas?.length) {
        return [];
      }
      
      console.log("LINKED PERSONAS PRZED FILTREM:", currentJourney.linked_personas);
      
      const personaIds = currentJourney.linked_personas
        .filter(rel => {
          const hasValidId = rel.personas_id?.id;
          console.log(`Relacja ${rel.id}: personas_id=${rel.personas_id?.id}`);
          return hasValidId;
        })
        .map(rel => rel.personas_id.id);
      
      console.log("FILTROWANE PERSONA IDS:", personaIds);
      
      if (personaIds.length === 0) return [];
      
      const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { id: { _in: personaIds.join(',') } },
          fields: ['id', 'name', 'avatar.id', 'type', 'occupation']
        }
      });
      
      console.log("ODPOWIEDŹ PERSONAS:", response.data.data);
      
      return response.data.data || [];
    } catch (error) {
      console.error("Error fetching personas data:", error);
      return [];
    }
  };

  const { 
    data: currentJourneyData, 
    isLoading,
    isError,
    error: queryError
  } = useQuery({
    queryKey: ['journey', id],
    queryFn: fetchJourney,
  });

  const journeyMutation = useMutation({
    mutationFn: saveJourney,
    onMutate: async (newJourney) => {
      await queryClient.cancelQueries(['journey', id]);
      const previousJourney = queryClient.getQueryData(['journey', id]);
      queryClient.setQueryData(['journey', id], newJourney);
      return { previousJourney };
    },
    onError: (err, newJourney, context) => {
      queryClient.setQueryData(['journey', id], context.previousJourney);
      console.error('Save failed:', err);
    },
    onSettled: () => {
      setIsDragLoading(false);
    },
  });

  const handleInsightClick = useCallback((insightData, phaseId) => {
    setSelectedInsight(insightData);
    setSelectedPhaseId(phaseId);
    setIsInsightViewDrawerOpen(true);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const openDrawer = (type, phaseIndex, existingData = null) => {
    setDrawerType(type);
    setActivePhaseIndex(phaseIndex);
    setEditData(existingData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerType(null);
    setActivePhaseIndex(null);
    setEditData(null);
  };

  const handleSaveItem = async (phaseIndex, itemType, itemData) => {
    const updatedJourney = { ...currentJourney };
    if (itemType === 'Painpoint') {
      if (editData) {
        const painpointIndex = updatedJourney.phase[phaseIndex].painpoints.findIndex(
          p => p.single_painpoint === editData.content
        );
        if (painpointIndex !== -1) {
          updatedJourney.phase[phaseIndex].painpoints[painpointIndex] = {
            single_painpoint: itemData.content,
            name: itemData.name
          };
        }
      } else {
        updatedJourney.phase[phaseIndex].painpoints.push({
          single_painpoint: itemData.content,
          name: itemData.name
        });
      }
    } else if (itemType === 'Gain') {
      if (!updatedJourney.phase[phaseIndex].gains) {
        updatedJourney.phase[phaseIndex].gains = [];
      }
      if (editData) {
        const gainIndex = updatedJourney.phase[phaseIndex].gains.findIndex(
          g => g.single_gain === editData.content
        );
        if (gainIndex !== -1) {
          updatedJourney.phase[phaseIndex].gains[gainIndex] = {
            single_gain: itemData.content,
            name: itemData.name
          };
        }
      } else {
        updatedJourney.phase[phaseIndex].gains.push({
          single_gain: itemData.content,
          name: itemData.name
        });
      }
    }
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addPainPoint = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase[phaseIndex].painpoints.push({ single_painpoint: "New Pain Point" });
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addGain = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    if (!updatedJourney.phase[phaseIndex].gains) {
      updatedJourney.phase[phaseIndex].gains = [];
    }
    updatedJourney.phase[phaseIndex].gains.push({ single_gain: "New Gain" });
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const addNewPhase = async () => {
    const updatedJourney = { ...currentJourney };
    const newPhase = {
      phase_name: "New Phase",
      emotional_curve: 0,
      painpoints: [],
      gains: [],
      order_value: updatedJourney.phase.length + 1
    };
    
    updatedJourney.phase.push(newPhase);
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const truncateText = (text, limit) => {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const handlePhaseUpdate = async (phaseData) => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase[activePhase] = {
      ...updatedJourney.phase[activePhase],
      ...phaseData
    };
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const handlePhaseDelete = async () => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase.splice(activePhase, 1);
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
    setDropIndicator(null);
  };

  const handleDragOver = (event) => {
    const { active, over } = event;
    
    if (!active || !over) {
      setDropIndicator(null);
      return;
    }

    const activeId = active.id;
    const overId = over.id;
    
    // Obsługa przeciągania faz
    if (activeId.toString().startsWith('phase-') && overId.toString().startsWith('phase-')) {
      const activeIndex = parseInt(activeId.toString().split('-')[1]);
      const overIndex = parseInt(overId.toString().split('-')[1]);
      
      // Ustaw wskaźnik upuszczenia - po której stronie aktualnej fazy
      if (activeIndex < overIndex) {
        setDropIndicator({ phase: overIndex, position: 'right' });
      } else if (activeIndex > overIndex) {
        setDropIndicator({ phase: overIndex, position: 'left' });
      } else {
        setDropIndicator(null);
      }
    } else {
      setDropIndicator(null);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    
    setActiveId(null);
    setDropIndicator(null);
    
    if (!active || !over) {
      console.log("Drag end with no active/over elements", { active, over });
      return;
    }
    
    console.log("Drag completed", { active: active.id, over: over.id });
    
    if (active.id !== over.id) {
      // Obsługa przeciągania i upuszczania faz
      if (active.id.toString().startsWith('phase-') && over.id.toString().startsWith('phase-')) {
        setIsDragLoading(true);
        
        const activePhaseId = active.id.toString();
        const overPhaseId = over.id.toString();
        
        const activeIndex = parseInt(activePhaseId.split('-')[1]);
        const overIndex = parseInt(overPhaseId.split('-')[1]);
        
        // Aktualizuj lokalny stan do natychmiastowego odzwierciedlenia zmian w UI
        const updatedJourney = { ...currentJourney };
        const newPhases = [...updatedJourney.phase];
        const [movedPhase] = newPhases.splice(activeIndex, 1);
        newPhases.splice(overIndex, 0, movedPhase);
        updatedJourney.phase = newPhases;
        
        setCurrentJourney(updatedJourney);
        
        try {
          // Użyj mutacji do zapisania zmian
          await journeyMutation.mutateAsync(updatedJourney);
          
          // Opcjonalnie możemy odświeżyć dane z serwera
          queryClient.invalidateQueries(['journey', id]);
        } catch (error) {
          console.error('Error saving phase order:', error);
          // Przywracanie poprzedniego stanu w przypadku błędu
          queryClient.invalidateQueries(['journey', id]);
        } finally {
          setIsDragLoading(false);
        }
      }
      
      // Obsługa przeciągania i upuszczania painpoints/gains/jobs_to_be_done
      else if (active.id.toString().includes('painpoint-') || 
          active.id.toString().includes('gain-') ||
          active.id.toString().includes('job_to_be_done-')) {
        
        console.log("Processing insight item drag", active.id, "to", over.id);
        
        setIsDragLoading(true);
        
        try {
          // Extract information from the active item ID
          const activeId = active.id.toString();
          const [itemType, sourcePhaseIndex, itemIndex] = activeId.split('-').map((val, idx) => idx > 0 ? parseInt(val) : val);
          
          // Extract the target container information
          const overId = over.id.toString();
          let targetPhaseIndex, targetType, targetIndex;
          
          if (overId.startsWith('container-')) {
            // Dropping on a container
            const containerParts = overId.split('-');
            targetType = containerParts[1]; // painpoint, gain, or job_to_be_done
            targetPhaseIndex = parseInt(containerParts[2]);
            targetIndex = -1; // Append to the end
          } else if (overId.includes('painpoint-') || overId.includes('gain-') || overId.includes('job_to_be_done-')) {
            // Dropping on another item
            const overParts = overId.split('-');
            targetType = overParts[0];
            targetPhaseIndex = parseInt(overParts[1]);
            targetIndex = parseInt(overParts[2]);
          } else {
            console.log("Invalid drop target", overId);
            setIsDragLoading(false);
            return;
          }
          
          console.log("Drag details:", {
            sourceType: itemType,
            sourcePhase: sourcePhaseIndex,
            sourceIndex: itemIndex,
            targetType,
            targetPhase: targetPhaseIndex,
            targetIndex
          });
          
          // Check if the source type matches the target type (prevent cross-type movement)
          if (itemType !== targetType) {
            console.log("Cross-type dragging not allowed", { sourceType: itemType, targetType });
            setIsDragLoading(false);
            return;
          }
          
          // Deep clone to avoid state issues
          const updatedJourney = JSON.parse(JSON.stringify(currentJourney)); 
          
          // Get the item being moved
          let arrayKey;
          
          if (itemType === 'painpoint') {
            arrayKey = 'painpoints';
          } else if (itemType === 'gain') {
            arrayKey = 'gains';
          } else if (itemType === 'job_to_be_done') {
            arrayKey = 'jobs_to_be_done';
          }
          
          // Ensure arrays exist
          if (!updatedJourney.phase[sourcePhaseIndex][arrayKey]) {
            updatedJourney.phase[sourcePhaseIndex][arrayKey] = [];
          }
          
          if (!updatedJourney.phase[targetPhaseIndex][arrayKey]) {
            updatedJourney.phase[targetPhaseIndex][arrayKey] = [];
          }
          
          // Make copies of the source and target arrays
          const sourceArray = [...updatedJourney.phase[sourcePhaseIndex][arrayKey]];
          
          // Get the item we're moving (and remove it from the source array)
          const [movedItem] = sourceArray.splice(itemIndex, 1);
          
          if (!movedItem) {
            console.error("Item not found at specified index", itemIndex);
            setIsDragLoading(false);
            return;
          }
          
          // Update the source array in our journey copy
          updatedJourney.phase[sourcePhaseIndex][arrayKey] = sourceArray;
          
          // If we're moving within the same phase
          if (sourcePhaseIndex === targetPhaseIndex) {
            // We've already removed the item from the source array,
            // now we need to insert it at the target position
            if (targetIndex >= 0) {
              // Calculate adjusted position after removal
              const adjustedTargetIndex = targetIndex > itemIndex ? targetIndex - 1 : targetIndex;
              sourceArray.splice(adjustedTargetIndex, 0, movedItem);
            } else {
              // Append to the end
              sourceArray.push(movedItem);
            }
            
            // The source and target arrays are the same in this case
            updatedJourney.phase[sourcePhaseIndex][arrayKey] = sourceArray;
          } else {
            // We're moving between different phases
            const targetArray = [...updatedJourney.phase[targetPhaseIndex][arrayKey]];
            
            if (targetIndex >= 0) {
              // Insert at the specific position
              targetArray.splice(targetIndex, 0, movedItem);
            } else {
              // Append to the end
              targetArray.push(movedItem);
            }
            
            updatedJourney.phase[targetPhaseIndex][arrayKey] = targetArray;
          }
          
          // Log the changes
          console.log("Updated arrays:", {
            source: updatedJourney.phase[sourcePhaseIndex][arrayKey],
            target: updatedJourney.phase[targetPhaseIndex][arrayKey]
          });
          
          // Update the journey
          setCurrentJourney(updatedJourney);
          
          // Save changes
          await journeyMutation.mutateAsync(updatedJourney);
          
          // Refresh data
          queryClient.invalidateQueries(['journey', id]);
        } catch (error) {
          console.error('Error moving item:', error);
          queryClient.invalidateQueries(['journey', id]);
        } finally {
          setIsDragLoading(false);
        }
      }
    }
  };

  const convertItem = (item, fromType, toType) => {
    if (fromType === 'painpoint' && toType === 'gain') {
      return {
        single_gain: item.single_painpoint,
        name: item.name
      };
    } else if (fromType === 'gain' && toType === 'painpoint') {
      return {
        single_painpoint: item.single_gain,
        name: item.name
      };
    }
    return item;
  };

  const handleInputChange = async (e) => {
    const updatedJourney = { ...currentJourney, user_journey_name: e.target.value };
    await journeyMutation.mutateAsync(updatedJourney);
  };

  const openInsightSelector = (type, phaseIndex) => {
    setSelectorType(type);
    setSelectedPhaseIndex(phaseIndex);
    setIsInsightSelectorOpen(true);
  };

  const handleInsightSelect = async (insight) => {
    try {
      console.log('Selected insight:', insight);
      console.log('Current phase index:', selectedPhaseIndex);

      const updatedJourney = { ...currentJourney };
      const phase = updatedJourney.phase[selectedPhaseIndex];

      // Fetch complete insight data
      const response = await axios.get(`${DIRECTUS_INSIGHTS_ENDPOINT}/${insight.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const fullInsightData = response.data.data;

      // Create the connection with full insight data
      const connectionObject = {
        insight_id: insight.id,
        insight_data: fullInsightData // Add the full insight data
      };

      // Add to appropriate array
      if (selectorType === 'Painpoint') {
        if (!phase.painpoints) phase.painpoints = [];
        phase.painpoints.push(connectionObject);
      } else if (selectorType === 'Gain') {
        if (!phase.gains) phase.gains = [];
        phase.gains.push(connectionObject);
      } else if (selectorType === 'JobToBeDone') {
        if (!phase.jobs_to_be_done) phase.jobs_to_be_done = [];
        phase.jobs_to_be_done.push(connectionObject);
      }

      await journeyMutation.mutateAsync(updatedJourney);
      setIsInsightSelectorOpen(false);

    } catch (error) {
      console.error('Failed to add insight:', error);
    }
  };

  const linkPersona = async (personaId) => {
    try {
      console.log(`Linking persona ${personaId} to journey ${id}`);
      
      // Sprawdź czy relacja już istnieje
      const existingRelation = currentJourney.linked_personas?.find(
        rel => rel.personas_id?.id === personaId
      );
      
      if (existingRelation) {
        console.log("Relacja już istnieje:", existingRelation);
        return; // Jeśli relacja istnieje, nie dodawaj nowej
      }
      
      // Utwórz bezpośrednio rekord w tabeli junction
      await axios.post(`${DIRECTUS_INSTANCE}/items/userjourney_personas`, {
        userjourney_id: id,
        personas_id: personaId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log("RELACJA DODANA BEZPOŚREDNIO!");
      
      await fetchJourney(); // Odśwież dane
    } catch (error) {
      console.error('Error linking persona:', error);
      console.log("ERROR RESPONSE:", error.response?.data);
    }
  };

  const unlinkPersona = async (personaId) => {
    try {
      // Znajdź ID relacji, którą chcemy usunąć
      const relationToDelete = currentJourney.linked_personas
        .find(relation => relation.personas_id?.id === personaId);
      
      if (!relationToDelete) {
        console.log(`Nie znaleziono relacji dla persony ${personaId}`);
        return;
      }
      
      console.log(`Usuwam relację ID ${relationToDelete.id}`);
      
      // Usuń bezpośrednio rekord z tabeli junction
      await axios.delete(`${DIRECTUS_INSTANCE}/items/userjourney_personas/${relationToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log("RELACJA USUNIĘTA BEZPOŚREDNIO!");
      
      await fetchJourney(); // Odśwież dane
    } catch (error) {
      console.error('Error unlinking persona:', error);
      console.log("ERROR RESPONSE:", error.response?.data);
    }
  };

  const cleanupRelations = async () => {
    if (!currentJourney?.linked_personas?.length) return;
    
    try {
      // Grupuj według ID persony
      const relationsByPersonaId = {};
      
      currentJourney.linked_personas.forEach(rel => {
        if (rel.personas_id?.id) {
          if (!relationsByPersonaId[rel.personas_id.id]) {
            relationsByPersonaId[rel.personas_id.id] = [];
          }
          relationsByPersonaId[rel.personas_id.id].push(rel);
        }
      });
      
      // Dla każdej grupy zostaw tylko jedną relację
      const toDelete = [];
      
      Object.values(relationsByPersonaId).forEach(relations => {
        // Zostaw pierwszy wpis, resztę usuń
        const [keep, ...duplicates] = relations;
        duplicates.forEach(dup => toDelete.push(dup.id));
      });
      
      // Usuń nieprawidłowe relacje (bez personas_id)
      currentJourney.linked_personas.forEach(rel => {
        if (!rel.personas_id?.id) {
          toDelete.push(rel.id);
        }
      });
      
      if (toDelete.length > 0) {
        console.log("Cleaning up relations:", toDelete);
        
        const updatedJourney = {
          linked_personas: {
            delete: toDelete
          }
        };
        
        await axios.patch(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`, updatedJourney, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });
        
        await fetchJourney();
      }
    } catch (error) {
      console.error('Error cleaning up relations:', error);
    }
  };

  const repairPersonaRelations = async () => {
    try {
      // Sprawdź czy w ogóle mamy dane podróży
      if (!currentJourney) return;

      // 1. Pozyskaj WSZYSTKIE persony użytkownika
      const personasResponse = await axios.get(DIRECTUS_PERSONAS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: { owner: { _eq: currentJourney.owner } },
          fields: ['id', 'name', 'linked_user_journeys.*', 'linked_user_journeys.userjourney_id.id']
        }
      });

      const allPersonas = personasResponse.data.data || [];
      console.log("WSZYSTKIE PERSONY:", allPersonas);

      // 2. Znajdź persony, które mają relacje z tą podróżą, ale brakuje ich w currentJourney.linked_personas
      const personasWithThisJourney = allPersonas.filter(persona => {
        if (!persona.linked_user_journeys) return false;
        
        return persona.linked_user_journeys.some(rel => 
          rel.userjourney_id?.id === currentJourney.id
        );
      });

      console.log("PERSONY Z TĄ PODRÓŻĄ:", personasWithThisJourney);

      // 3. Sprawdź, czy wszystkie te persony są już w currentJourney.linked_personas
      const existingPersonaIds = currentJourney.linked_personas
        ?.filter(rel => rel.personas_id?.id)
        ?.map(rel => rel.personas_id.id) || [];

      const personasToAdd = personasWithThisJourney.filter(persona => 
        !existingPersonaIds.includes(persona.id)
      );

      if (personasToAdd.length > 0) {
        console.log("PERSONY DO DODANIA:", personasToAdd);
        
        // 4. Dodaj brakujące relacje
        const updatedJourney = {
          linked_personas: [
            ...(currentJourney.linked_personas?.map(rel => ({
              id: rel.id
            })) || []),
            ...personasToAdd.map(persona => ({
              personas_id: persona.id
            }))
          ]
        };
        
        await axios.patch(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${currentJourney.id}`, updatedJourney, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });
        
        console.log("PERSONY NAPRAWIONE");
        
        // 5. Odśwież dane
        await fetchJourney();
      }
    } catch (error) {
      console.error("Error repairing persona relations:", error);
    }
  };

  const handleAddPersonas = async (selectedIds) => {
    try {
      const promises = selectedIds.map(personaId => {
        return axios.post(`${DIRECTUS_INSTANCE}/items/userjourney_personas`, {
          userjourney_id: id,
          personas_id: personaId
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      });
      
      await Promise.all(promises);
      await fetchJourney();
      setIsPersonaSelectorOpen(false);
    } catch (error) {
      console.error('Error adding personas:', error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this journey?')) {
      try {
        await axios.delete(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        navigate('/user-journeys');
      } catch (error) {
        console.error('Error deleting journey:', error);
      }
    }
  };

  useEffect(() => {
    if (currentJourney) {
      // Najpierw wyczyść duplikaty, potem napraw brakujące relacje
      cleanupRelations().then(() => repairPersonaRelations());
    }
  }, [currentJourney?.id]); // Reaguj tylko na zmianę ID podróży

  useEffect(() => {
    if (currentJourney?.linked_personas?.length) {
      fetchPersonasData().then(setLinkedPersonas);
    }
  }, [currentJourney]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!currentJourney?.phase) return <div>No journey data available.</div>;

  const chartData = {
    labels: currentJourney.phase.map(phase => phase.phase_name),
    datasets: [
      {
        label: 'Emotional Curve',
        data: currentJourney.phase.map(phase => phase.emotional_curve),
        fill: false,
        borderColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, '#3ED193');
          gradient.addColorStop(0.25, '#3ED193');
          gradient.addColorStop(0.5, '#EADC66');
          gradient.addColorStop(0.75, '#E7ABAB');
          gradient.addColorStop(1, '#E7ABAB');
          return gradient;
        },
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: -3,
        max: 3,
        grid: {
          display: true
        },
        ticks: {
          stepSize: 1
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const chartWidth = currentJourney.phase.length * 320;

  return (
    <div className="bg-white pt-2 lg:pl-24 relative">
      {isDragLoading && (
        <div className="absolute inset-0 bg-white/50 z-50 flex items-center justify-center">
          <div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Saving...
          </div>
        </div>
      )}

      <div className="border-b border-gray-200 py-4">
        <div className="flex w-full flex-row items-center">
          <div className="relative flex-none">
            <div className="relative flex max-w-full">
              <input
                value={currentJourney.user_journey_name}
                onChange={handleInputChange}
                placeholder="Enter a name..."
                className="text-lg font-medium leading-7 text-gray-900 sm:text-lg sm:tracking-tight w-full shadow-none bg-transparent border-none focus:outline-none"
              />
            </div>
          </div>

          <div className="flex w-full flex-row items-center gap-4">
            <div className="ml-auto mr-0 flex items-center gap-4">
              {/* Personas Section with Add Button */}
              <div className="flex items-center">
                {currentJourney.linked_personas && currentJourney.linked_personas.length > 0 ? (
                  <>
                    <div className="flex -space-x-2 overflow-hidden">
                      {currentJourney.linked_personas.map(relation => (
                        <div key={relation.personas_id?.id || Math.random()} className="relative group">
                          <Link 
                            to={`/personas/${relation.personas_id?.id}`}
                            className="block relative"
                          >
                            <img
                              className="inline-block h-8 w-8 rounded-full ring-2 ring-white cursor-pointer hover:ring-primary-200 transition-all duration-200"
                              src={relation.personas_id?.avatar?.id 
                                ? `https://panel.reislo.com/assets/${relation.personas_id.avatar.id}?width=256&height=256&fit=cover&format=webp`
                                : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                              }
                              alt={relation.personas_id?.name || 'Avatar'}
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <button
                      onClick={() => setIsPersonaSelectorOpen(true)}
                      className="ml-2 inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-200 hover:border-primary-200 transition-all duration-200"
                    >
                      <PlusIcon className="h-4 w-4 text-gray-400 hover:text-primary-600" />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsPersonaSelectorOpen(true)}
                    className="flex items-center gap-2 text-sm text-gray-500 hover:text-primary-600"
                  >
                    <PlusIcon className="h-5 w-5" />
                    Add Personas
                  </button>
                )}
              </div>

              {/* Three Dots Menu */}
              <Menu as="div" className="relative">
                <Menu.Button className="p-2 hover:bg-gray-100 rounded-full">
                  <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setIsEditingName(true)}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700 w-full text-left`}
                      >
                        Edit Journey Name
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleDelete}
                        className={`${
                          active ? 'bg-red-50' : ''
                        } block px-4 py-2 text-sm text-red-600 w-full text-left`}
                      >
                        Delete Journey
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      {/* Add new tab navigation bar here */}
      <div className="border-b border-gray-200">
        <nav className="flex space-x-4 my-4" aria-label="Journey Navigation">
          <button
            className="flex items-center space-x-2 bg-primary-50 text-primary-500 whitespace-nowrap py-2 px-3 text-sm font-normal rounded"
            aria-current="page"
          >
            <MapIcon className="h-4 w-4" />
            <span>Journey</span>
          </button>
          <button
            className="flex items-center space-x-2 border-transparent text-gray-500 hover:text-gray-700 whitespace-nowrap py-2 px-3 text-sm font-normal hover:bg-gray-50 rounded transition-colors"
          >
            <LightBulbIcon className="h-4 w-4" />
            <span>Insights</span>
          </button>
          <button
            className="flex items-center space-x-2 border-transparent text-gray-500 hover:text-gray-700 whitespace-nowrap py-2 px-3 text-sm font-normal hover:bg-gray-50 rounded transition-colors"
          >
            <SparklesIcon className="h-4 w-4" />
            <span>Solutions</span>
          </button>
        </nav>
      </div>

      <div className="customer-journey-map overflow-x-auto min-h-screen">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="flex min-w-max">
            <div className="min-w-40 bg-white pl-3 text-sm border-l pt-3 border-grey-100 flex items-start">
              <span>Phases</span>
            </div>
            <>
              <SortableContext
                items={currentJourney.phase.map((_, index) => `phase-${index}`)}
                strategy={horizontalListSortingStrategy}
              >
                {currentJourney.phase.map((phase, index) => (
                  <SortablePhase
                    key={`phase-${index}`}
                    id={`phase-${index}`}
                    phase={phase}
                    index={index}
                    dropIndicator={dropIndicator}
                    onPhaseClick={(index) => {
                      setActivePhase(index);
                      setIsPhaseDrawerOpen(true);
                    }}
                  />
                ))}
              </SortableContext>
              <div 
                onClick={addNewPhase}
                className="w-40 bg-grey-100 p-3 rounded-lg hover:bg-primary-200 cursor-pointer transition-colors duration-200"
              >
                Add Phase +
              </div>
            </>
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-t border-grey-100 border-l flex items-start border-r">
              <span>Jobs To Be Done</span>
              <button 
                onClick={() => toggleSection('jobs_to_be_done')}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 transition-transform ${expandedSections.jobs_to_be_done ? 'rotate-0' : '-rotate-90'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
            {expandedSections.jobs_to_be_done && currentJourney.phase.map((phase, phaseIndex) => (
              <DroppableContainer 
                key={phaseIndex} 
                id={`job_to_be_done`} 
                phaseIndex={phaseIndex}
              >
                <SortableContext
                  items={(phase.jobs_to_be_done || []).map((_, index) => `job_to_be_done-${phaseIndex}-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {(phase.jobs_to_be_done || []).map((job, index) => (
                    <SortableItem
                      key={`job_to_be_done-${phaseIndex}-${index}`}
                      id={`job_to_be_done-${phaseIndex}-${index}`}
                      item={job}
                      type="job_to_be_done"
                      handleInsightClick={handleInsightClick}
                      phaseIndex={phaseIndex}
                      className="bg-purple-50"
                    />
                  ))}
                </SortableContext>
                <button 
                  onClick={() => openInsightSelector('JobToBeDone', phaseIndex)} 
                  className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
                >
                  + Add Job To Be Done
                </button>
              </DroppableContainer>
            ))}
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-t border-grey-100 border-l flex items-start border-r">
              <span>Emotional Curve</span>
              <button 
                onClick={() => toggleSection('emotionalCurve')}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 transition-transform ${expandedSections.emotionalCurve ? 'rotate-0' : '-rotate-90'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
            {expandedSections.emotionalCurve && (
              <div className="border-l border-t border-grey-100 pl-4 py-4" style={{ height: '300px', width: `${chartWidth}px` }}>
                <Line data={chartData} options={chartOptions} />
              </div>
            )}
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-t border-grey-100 border-l flex items-start border-r">
              <span>Gains</span>
              <button 
                onClick={() => toggleSection('gains')}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 transition-transform ${expandedSections.gains ? 'rotate-0' : '-rotate-90'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
            {expandedSections.gains && currentJourney.phase.map((phase, phaseIndex) => (
              <DroppableContainer 
                key={phaseIndex} 
                id={`gain`} 
                phaseIndex={phaseIndex}
              >
                <SortableContext
                  items={(phase.gains || []).map((_, index) => `gain-${phaseIndex}-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {(phase.gains || []).map((gain, index) => (
                    <SortableItem
                      key={`gain-${phaseIndex}-${index}`}
                      id={`gain-${phaseIndex}-${index}`}
                      item={gain}
                      type="gain"
                      handleInsightClick={handleInsightClick}
                      phaseIndex={phaseIndex}
                      className="bg-green-50"
                    />
                  ))}
                </SortableContext>
                <button 
                  onClick={() => openInsightSelector('Gain', phaseIndex)} 
                  className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
                >
                  + Add Gain
                </button>
              </DroppableContainer>
            ))}
          </div>

          <div className="flex min-w-max">
            <div className="min-w-40 bg-white p-3 text-sm border-t border-grey-100 border-l flex items-start border-r">
              <span>Painpoints</span>
              <button 
                onClick={() => toggleSection('painpoints')}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 transition-transform ${expandedSections.painpoints ? 'rotate-0' : '-rotate-90'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
            {expandedSections.painpoints && currentJourney.phase.map((phase, phaseIndex) => (
              <DroppableContainer 
                key={phaseIndex} 
                id={`painpoint`} 
                phaseIndex={phaseIndex}
              >
                <SortableContext
                  items={(phase.painpoints || []).map((_, index) => `painpoint-${phaseIndex}-${index}`)}
                  strategy={verticalListSortingStrategy}
                >
                  {(phase.painpoints || []).map((painPoint, index) => (
                    <SortableItem
                      key={`painpoint-${phaseIndex}-${index}`}
                      id={`painpoint-${phaseIndex}-${index}`}
                      item={painPoint}
                      type="painpoint"
                      handleInsightClick={handleInsightClick}
                      phaseIndex={phaseIndex}
                    />
                  ))}
                </SortableContext>
                <button 
                  onClick={() => openInsightSelector('Painpoint', phaseIndex)} 
                  className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
                >
                  + Add Painpoint
                </button>
              </DroppableContainer>
            ))}
          </div>

          <InsightSelector
            isOpen={isInsightSelectorOpen}
            onClose={() => setIsInsightSelectorOpen(false)}
            onSelect={handleInsightSelect}
            type={selectorType}
            currentInsights={currentJourney.phase[selectedPhaseIndex]?.[
              selectorType === 'Painpoint' 
                ? 'painpoints' 
                : selectorType === 'JobToBeDone'
                  ? 'jobs_to_be_done'
                  : 'gains'
            ]?.map(item => item.insight_id) || []}
          />

          <DragOverlay>
            {activeId && (
              <div className={`
                ${activeId.startsWith('phase-') 
                  ? 'w-80 bg-primary-50 pl-3 pt-3' 
                  : activeId.startsWith('painpoint')
                    ? 'white border-red-100'
                    : activeId.startsWith('job_to_be_done')
                      ? 'white border-purple-100'
                      : 'bg-white border-green-100'
                } text-sm px-3 py-2 rounded-lg shadow-lg opacity-80`}
              >
                {(() => {
                  if (activeId.startsWith('phase-')) {
                    const index = parseInt(activeId.split('-')[1]);
                    return currentJourney.phase[index]?.phase_name || 'Phase';
                  }
                  
                  const [type, phaseIndex, itemIndex] = activeId.split('-');
                  const phase = currentJourney.phase[phaseIndex];
                  
                  if (type === 'painpoint' && phase?.painpoints?.[itemIndex]) {
                    return truncateText(phase.painpoints[itemIndex].single_painpoint, 40);
                  } else if (type === 'gain' && phase?.gains?.[itemIndex]) {
                    return truncateText(phase.gains[itemIndex].single_gain, 40);
                  } else if (type === 'job_to_be_done' && phase?.jobs_to_be_done?.[itemIndex]) {
                    // Pobierz zawartość
                    const content = phase.jobs_to_be_done[itemIndex].single_job_to_be_done || 
                      phase.jobs_to_be_done[itemIndex].insight_data?.description;
                    
                    // Sparsuj i skróć
                    return truncateText(parseEditorContent(content), 40);
                  }
                  return '';
                })()}
              </div>
            )}
          </DragOverlay>
        </DndContext>
      </div>

      <JourneyItemDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        onSave={handleSaveItem}
        itemType={drawerType}
        phaseIndex={activePhaseIndex}
        editData={editData}
      />

      <PhaseDrawer
        isOpen={isPhaseDrawerOpen}
        onClose={() => {
          setIsPhaseDrawerOpen(false);
          setActivePhase(null);
        }}
        onSave={handlePhaseUpdate}
        onDelete={handlePhaseDelete}
        phaseData={activePhase !== null ? currentJourney.phase[activePhase] : null}
      />

      <InsightViewDrawer
        isOpen={isInsightViewDrawerOpen}
        onClose={() => {
          setIsInsightViewDrawerOpen(false);
          setSelectedInsight(null);
        }}
        insight={selectedInsight}
        phaseId={selectedPhaseId}
      />

      <PersonaSelector
        isOpen={isPersonaSelectorOpen}
        onClose={() => setIsPersonaSelectorOpen(false)}
        onSelect={handleAddPersonas}
        currentPersonas={availablePersonas}
      />
    </div>
  );
}

export default CustomerJourneyTimeline;