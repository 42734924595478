import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { DIRECTUS_ONBOARDING_ENDPOINT } from '../../api';
import { useNavigate } from 'react-router-dom';
import reisloLogo from '../../img/logo-flat-color-1.svg';

const directusAPI = axios.create({
  baseURL: `${DIRECTUS_ONBOARDING_ENDPOINT}`,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

const positions = [
  { 
    value: 'ux_ui_designer', 
    label: 'UX/UI Designer',
    icon: 'path/to/icon.svg'
  },
  { value: 'product_owner', label: 'Product Owner' },
  { value: 'project_manager', label: 'Project Manager' },
  { value: 'marketing_manager', label: 'Marketing Manager' },
  { value: 'marketing_specialist', label: 'Marketing Specialist' },
  { value: 'other', label: 'Other' },
];

const teamSizes = [
  { value: '1-10', label: '1-10' },
  { value: '10-25', label: '10-25' },
  { value: '25-50', label: '25-50' },
  { value: '50+', label: '50+' },
];

const goals = [
  { value: 'personas', label: 'Create personas' },
  { value: 'user_journeys', label: 'Create customer/user journeys' },
  { value: 'journey_management', label: 'Manage customer/user journeys' },
  { value: 'ux_analysis', label: 'UX analysis of websites or apps' },
  { value: 'ux_assistant', label: 'UX assistant features' },
  { value: 'data_insights', label: 'Collect data-driven insights' },
  { value: 'other', label: 'Other' },
];

const userCounts = [
  { value: '0-100', label: '0-100' },
  { value: '100-1000', label: '100-1,000' },
  { value: '1000-10000', label: '1,000-10,000' },
  { value: '10000+', label: '10,000+' },
];

const tools = [
  { value: 'hubspot', label: 'Hubspot', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/hubspot.svg' },
  { value: 'salesforce', label: 'Salesforce', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/salesforce.svg' },
  { value: 'snowflake', label: 'Snowflake', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/snowflake.svg' },
  { value: 'linkedin', label: 'LinkedIn Sales Navigator', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/linkedin-actual.png' },
  { value: 'apollo', label: 'Apollo', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/apollo.svg' },
  { value: 'stripe', label: 'Stripe', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/stripe.svg' },
  { value: 'google_sheets', label: 'Google Sheets', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/google-sheets.svg' },
  { value: 'openai', label: 'OpenAI', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/chat-gpt.svg' },
  { value: 'anthropic', label: 'Anthropic', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/anthropic.svg' },
  { value: 'outreach', label: 'Outreach', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/outreach.svg' },
  { value: 'instantly', label: 'Instantly', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/instantly.svg' },
  { value: 'close', label: 'Close', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/close.svg' },
  { value: 'zapier', label: 'Zapier', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/zapier.svg' },
  { value: 'phantombuster', label: 'PhantomBuster', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/phantombuster.svg' },
  { value: 'salesloft', label: 'Salesloft', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/salesloft-1.svg' },
  { value: 'hunter', label: 'Hunter', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/hunter.svg' },
  { value: 'zerobounce', label: 'ZeroBounce', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/zerobounce.svg' },
  { value: 'internal_apis', label: 'Internal APIs', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/globe.svg' },
  { value: 'smartlead', label: 'SmartLead', icon: 'https://clay-base-prod-static.s3.amazonaws.com/icons/svg/smartlead.svg' }
];


const Onboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [name, setName] = useState(() => localStorage.getItem('username') || '');
  const [organizationName, setOrganizationName] = useState('');
  const [position, setPosition] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [userCount, setUserCount] = useState('');
  const [error, setError] = useState('');
  const [tokenDebug, setTokenDebug] = useState('');
  const [userIdDebug, setUserIdDebug] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedName = localStorage.getItem('username');
    if (storedName) {
      setName(storedName);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');
    
    console.log('TOKEN DEBUG:', token ? `Token istnieje: ${token.substring(0, 15)}...` : 'Token nie istnieje');
    console.log('USER ID DEBUG:', userId || 'User ID nie istnieje');
    
    setTokenDebug(token || 'brak');
    setUserIdDebug(userId || 'brak');
    
    console.log('Wszystkie klucze w localStorage:', Object.keys(localStorage));
    
    if (!token || !userId) {
      console.log('Uwaga: Brak tokenów uwierzytelniania, ale kontynuujemy onboarding');
    }
  }, []);

  const steps = [
    { title: 'Organization Setup', description: 'Let\'s create your customized workspace' },
    { title: 'Your Position', description: 'Help us tailor content to your professional role' },
    { title: 'Team Size', description: 'Scale our solutions to fit your organization perfectly' },
    { title: 'Your Tools', description: 'Tell us which tools you\'re currently using' },
    { title: 'Your Goals', description: 'Align our features with your strategic objectives' },
    { title: 'User Base', description: 'Optimize performance for your audience size' },
  ];

  const validateStep = () => {
    setError('');
    switch (step) {
      case 0:
        if (!organizationName.trim()) {
          setError('Please enter your organization name');
          return false;
        }
        break;
      case 1:
        if (!position) {
          setError('Please select your position');
          return false;
        }
        break;
      case 2:
        if (!teamSize) {
          setError('Please select your team size');
          return false;
        }
        break;
      case 3:
        if (selectedTools.length === 0) {
          setError('Please select at least one tool');
          return false;
        }
        break;
      case 4:
        if (selectedGoals.length === 0) {
          setError('Please select at least one goal');
          return false;
        }
        break;
      case 5:
        if (!userCount) {
          setError('Please select your user count');
          return false;
        }
        break;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (step === steps.length - 1) {
        handleComplete();
      } else {
        setStep(step + 1);
      }
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
      setError('');
    }
  };

  const handleSkip = () => {
    console.log('Onboarding skipped');
  };

  const handleComplete = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('directus_token');
      const userId = localStorage.getItem('user_id');

      console.log('handleComplete TOKEN:', token ? `Istnieje (${token.substring(0, 10)}...)` : 'Brak tokenu');
      console.log('handleComplete USER ID:', userId || 'Brak ID użytkownika');

      if (!token || !userId) {
        console.error('Brak tokenów uwierzytelniania podczas kończenia onboardingu');
        
        if (organizationName && position && teamSize && selectedTools.length > 0 && 
            selectedGoals.length > 0 && userCount) {
          console.log('Mamy wszystkie dane onboardingowe, kontynuujemy mimo braku tokenów');
          return;
        }
        
        setError('Nie znaleziono informacji o sesji. Proszę kontynuować.');
        return;
      }
      
      console.log('Preparing onboarding data...');
      
      // Debug OpenAI API key
      console.log('OpenAI API Key exists:', !!process.env.REACT_APP_GPT_API_KEY);
      console.log('OpenAI API Key length:', process.env.REACT_APP_GPT_API_KEY?.length || 0);
      
      const onboardingData = {
        first_step: position,
        second_step: teamSize,
        third_step: selectedTools.join(', '),
        fourth_step: selectedGoals.join(', '),
        fifth_step: userCount,
        user: userId,
        organisation: {
          name: organizationName,
          team_size: teamSize,
          monthly_users: userCount
        }
      };

      // Create OpenAI Assistant
      console.log('Creating OpenAI Assistant...');
      const assistantResponse = await axios.post(
        'https://api.openai.com/v1/assistants',
        {
          name: `${organizationName} Assistant`,
          description: `AI Assistant for ${organizationName}`,
          instructions: `You are an AI assistant for ${organizationName}. You are a UX expert that helps with creating user journeys, personas, and conducting UX analysis.`,
          model: "gpt-4o",
          tools: [
            {
              type: "file_search"
            }
          ]
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );

      const assistantId = assistantResponse.data.id;
      console.log('Assistant created with ID:', assistantId);

      // Create Vector Store
      console.log('Creating Vector Store...');
      const vectorStoreResponse = await axios.post(
        'https://api.openai.com/v1/vector_stores',
        {
          name: `${organizationName}_ux_knowledge_base`,
          chunking_strategy: {
            type: "static",
            static: {
              max_chunk_size_tokens: 800,
              chunk_overlap_tokens: 400
            }
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );

      const vectorStoreId = vectorStoreResponse.data.id;
      console.log('Vector Store created with ID:', vectorStoreId);

      // Link Assistant with Vector Store by updating the Assistant
      console.log('Linking Assistant with Vector Store...');
      await axios.post(
        `https://api.openai.com/v1/assistants/${assistantId}`,
        {
          tools: [
            {
              type: "file_search"
            }
          ],
          tool_resources: {
            file_search: {
              vector_store_ids: [vectorStoreId]
            }
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Assistant linked with Vector Store successfully');

      // Sending request to create onboarding profile in Directus
      console.log('Sending request to create onboarding profile...');
      const onboardingResponse = await directusAPI.post('', onboardingData);
      console.log('Onboarding profile created:', onboardingResponse.data);

      // Create workspace with Assistant and Vector Store IDs
      console.log('Creating workspace...');
      const workspaceData = {
        workspace_name: organizationName,
        team_size: teamSize,
        monthly_users: userCount,
        assistant_id: assistantId,
        vector_store_id: vectorStoreId
      };

      const workspaceResponse = await axios.post(
        'https://panel.reislo.com/items/workspace',
        workspaceData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Workspace created:', workspaceResponse.data);
      const workspaceId = workspaceResponse.data.data.id;

      // Update user profile
      console.log('Updating user profile...');
      const userUpdateData = {
        position: position,
        onboarding_completed: true,
        linked_workspace: workspaceId,
        trial_ends: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString() // 14 days from now
      };

      const userUpdateResponse = await axios.patch(
        `https://panel.reislo.com/users/${userId}`,
        userUpdateData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('User profile updated:', userUpdateResponse.data);
      console.log('Onboarding completed successfully');

      navigate('/');
    } catch (error) {
      console.error('Error in onboarding process:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      setError(`Failed to complete onboarding: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToolSelection = (toolValue) => {
    console.log('Current selectedTools:', selectedTools);
    console.log('Clicking tool:', toolValue);
    
    setSelectedTools(prev => {
      const newSelection = prev.includes(toolValue)
        ? prev.filter(t => t !== toolValue)
        : [...prev, toolValue];
      console.log('New selection will be:', newSelection);
      return newSelection;
    });
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-4">
            <div className="mt-1 flex rounded-md">
              <input
                type="text"
                name="organizationName"
                id="organizationName"
                className="flex-1 rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="Your Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="flex flex-wrap gap-2">
            {positions.map((pos) => (
              <button
                key={pos.value}
                type="button"
                onClick={() => setPosition(pos.value)}
                className={`
                  inline-flex items-center justify-center px-4 py-2
                  border rounded-full transition-all duration-300
                  ${position === pos.value 
                    ? 'border-primary-200 bg-primary-50 text-primary-600' 
                    : 'border-nightshade-100 bg-white text-gray-700 hover:border-primary-100 hover:bg-primary-50/50'
                  }
                `}
              >
                <span className="text-sm font-normal">{pos.label}</span>
              </button>
            ))}
          </div>
        );
      case 2:
        return (
          <div className="flex flex-wrap gap-2">
            {teamSizes.map((size) => (
              <button
                key={size.value}
                type="button"
                onClick={() => setTeamSize(size.value)}
                className={`
                  inline-flex items-center justify-center px-4 py-2
                  border rounded-full transition-all duration-300
                  ${teamSize === size.value 
                    ? 'border-primary-200 bg-primary-50 text-primary-600' 
                    : 'border-nightshade-100 bg-white text-gray-700 hover:border-primary-100 hover:bg-primary-50/50'
                  }
                `}
              >
                <span className="text-sm font-normal">{size.label}</span>
              </button>
            ))}
          </div>
        );
      case 3:
        return (
          <div className="flex flex-wrap gap-2">
            {tools.map((tool) => (
              <button
                key={tool.value}
                type="button"
                onClick={() => {
                  setSelectedTools(prev =>
                    prev.includes(tool.value)
                      ? prev.filter(t => t !== tool.value)
                      : [...prev, tool.value]
                  );
                }}
                className={`
                  inline-flex items-center justify-center px-4 py-2
                  border rounded-full transition-all duration-300
                  ${selectedTools.includes(tool.value)
                    ? 'border-primary-200 bg-primary-50 text-primary-600' 
                    : 'border-nightshade-100 bg-white text-gray-700 hover:border-primary-100 hover:bg-primary-50/50'
                  }
                `}
              >
                <div className="flex items-center space-x-2">
                  <img 
                    src={tool.icon} 
                    className="h-4 w-4 rounded-sm" 
                    alt={`${tool.label} logo`}
                  />
                  <span className="text-sm font-normal">{tool.label}</span>
                </div>
              </button>
            ))}
          </div>
        );
      case 4:
        return (
          <div className="flex flex-wrap">
            {goals.map((goal) => (
              <span
                key={goal.value}
                className={`my-1 mr-2 cursor-pointer items-center justify-center rounded-full px-4 py-2 border border-nightshade-100 bg-white transition-colors duration-300 ease-in-out hover:border-primary-100 hover:bg-primary-50/50 ${
                  selectedGoals.includes(goal.value) 
                    ? 'border-primary-200 bg-primary-50 text-primary-600' 
                    : 'border-nightshade-100 bg-white text-gray-700 hover:border-primary-100 hover:bg-primary-50/50'
                }`}
                onClick={() => {
                  setSelectedGoals(prev =>
                    prev.includes(goal.value)
                      ? prev.filter(g => g !== goal.value)
                      : [...prev, goal.value]
                  );
                }}
                role="checkbox"
                aria-checked={selectedGoals.includes(goal.value)}
                tabIndex={0}
                data-checked={selectedGoals.includes(goal.value) ? '' : undefined}
              >
                <p data-slot="text" className="text-sm font-normal">
                  {goal.label}
                </p>
              </span>
            ))}
          </div>
        );
      case 5:
        return (
          <div className="flex flex-wrap">
            {userCounts.map((count) => (
              <span
                key={count.value}
                className={`my-1 mr-2 cursor-pointer items-center justify-center rounded-full px-4 py-2 border border-nightshade-100 bg-white transition-colors duration-300 ease-in-out hover:border-primary-100 hover:bg-primary-50/50 ${
                  userCount === count.value 
                    ? 'border-primary-200 bg-primary-50 text-primary-600' 
                    : 'border-nightshade-100 bg-white text-gray-700 hover:border-primary-100 hover:bg-primary-50/50'
                }`}
                onClick={() => setUserCount(count.value)}
                role="checkbox"
                aria-checked={userCount === count.value}
                tabIndex={0}
                data-checked={userCount === count.value ? '' : undefined}
              >
                <p data-slot="text" className="text-sm font-normal">
                  {count.label}
                </p>
              </span>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex h-dvh w-full flex-grow flex-col bg-white">
      <div className="absolute flex h-full w-full flex-grow flex-row overflow-clip duration-1000">
        {/* Left Content Section */}
        <div className="justify-left flex w-full grow flex-col place-content-between overflow-y-scroll space-y-4 px-16 py-10">
          <div className="flex flex-col space-y-12">
            {/* Logo */}
            <div className="pb-4">
              <img
                className="h-10 w-auto"
                src={reisloLogo}
                alt="Reislo logo"
              />
            </div>

            {/* Header Content */}
            <div className="space-y-2">
              <div>
                <h2 className="text-3xl font-medium tracking-tight">
                  {step < steps.length ? steps[step].title : 'Complete'}
                </h2>
              </div>
              <p className="text-base font-normal text-content-tertiary">
                {step < steps.length ? steps[step].description : 'Thank you for completing the onboarding'}
              </p>
            </div>

            {/* Main Content Area */}
            <div>
              {renderStep()}
              {error && (
                <p className="text-sm text-red-500 mt-2">{error}</p>
              )}
            </div>
          </div>

          {/* Navigation and Progress Section */}
          <div className="space-y-8">
            {/* Buttons */}
            <div className="flex justify-between items-center">
              {step > 0 ? (
                <button
                  onClick={handlePrev}
                  className="flex items-center space-x-2 text-gray-600 hover:text-gray-900"
                  disabled={isLoading}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  <span>Back</span>
                </button>
              ) : (
                <div></div>
              )}

              <button
                onClick={handleNext}
                disabled={isLoading}
                className={`
                  flex items-center justify-center px-10 py-2 rounded text-white
                  ${isLoading 
                    ? 'bg-primary-400 cursor-not-allowed' 
                    : 'bg-primary-500 hover:bg-primary-600'
                  }
                `}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Creating...
                  </>
                ) : (
                  <>
                    <span>{step === steps.length - 1 ? 'Complete' : 'Next'}</span>
                    {step !== steps.length - 1 && (
                      <ChevronRightIcon className="ml-2 h-5 w-5" />
                    )}
                  </>
                )}
              </button>
            </div>

            {/* Progress Bar */}
            <div className="py-8">
              <div className="relative h-2 w-full overflow-hidden rounded-full bg-gray-200">
                <div 
                  className="progress-bar absolute bottom-0 left-0 top-0 rounded-full transition-all duration-700 ease-in"
                  style={{
                    background: 'linear-gradient(90deg, rgb(254, 240, 164) 0%, rgb(180, 240, 188) 33.33%, rgb(171, 201, 254) 66.67%)',
                    width: `${((step + 1) / steps.length) * 100}%`
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right Banner Section */}
        <div className="relative hidden h-full flex-col transition-colors duration-1000 xl:flex xl:w-5/6 bg-action-blue-100">
          <img 
            src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
            alt="Onboarding Banner" 
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;