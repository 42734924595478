import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_INSIGHTS_ENDPOINT } from '../../api';
import { ArrowLeftIcon, PencilIcon, MapIcon, ChartBarIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { Bar } from 'react-chartjs-2';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import AutoSaveIndicator from '../Atoms/AutoSaveIndicator';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { format, parseISO } from 'date-fns';

const ValueBar = ({ value }) => {
  const normalizedValue = Math.min(Math.max(value, 0), 100);
  
  return (
    <div className="flex items-center gap-2 w-full">
      <div className="relative flex-grow h-[6px] bg-gray-100 rounded">
        <div 
          className="absolute h-full bg-primary-500 rounded transition-all duration-500"
          style={{ width: `${normalizedValue}%` }}
        />
      </div>
      <span className="text-xs text-gray-600 whitespace-nowrap">
        {normalizedValue}%
      </span>
    </div>
  );
};

const PlaceholderCard = ({ type }) => (
  <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
    <ExclamationTriangleIcon className="h-8 w-8 text-gray-400 mb-2" />
    <p className="text-sm text-gray-500">No {type} linked to this insight</p>
  </div>
);

const UserAvatar = ({ user, size = 'h-8 w-8' }) => {
  if (!user) return (
    <div className={`${size} rounded-full bg-gray-200 flex items-center justify-center text-gray-600`}>
      U
    </div>
  );

  return user.avatar ? (
    <img 
      src={`${import.meta.env.VITE_DIRECTUS_URL}/assets/${user.avatar}?width=40&height=40&fit=cover`}
      alt={`${user.first_name || ''} ${user.last_name || ''}`}
      className={`${size} rounded-full object-cover`}
    />
  ) : (
    <div className={`${size} rounded-full bg-gray-200 flex items-center justify-center text-gray-600`}>
      {user.first_name ? user.first_name.charAt(0).toUpperCase() : 'U'}
    </div>
  );
};

function InsightDetails() {
  const [insight, setInsight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [descriptionEditor, setDescriptionEditor] = useState(null);
  const [recommendationEditor, setRecommendationEditor] = useState(null);
  const [comments, setComments] = useState([
    { id: 1, author: 'John Doe', content: 'This is a critical insight', date: new Date().toISOString() },
    { id: 2, author: 'Jane Smith', content: 'We should prioritize this', date: new Date().toISOString() }
  ]);
  const isEditing = true; // Zawsze w trybie edycji
  const [lastSaved, setLastSaved] = useState('');
  const descTimeoutRef = useRef(null);
  const recTimeoutRef = useRef(null);

  // Business Value Chart Data
  const businessValueData = {
    labels: ['Current Value'],
    datasets: [{
      label: 'Business Value',
      data: [insight?.value || 75],
      backgroundColor: 'rgb(99, 102, 241)',
      borderRadius: 8,
    }]
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');

  useEffect(() => {
    const fetchInsight = async () => {
      try {
        const response = await axios.get(`${DIRECTUS_INSIGHTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setInsight(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching insight details');
        setLoading(false);
      }
    };

    fetchInsight();
  }, [id, token]);

  useEffect(() => {
    if (insight) {
      console.log("INICJALIZACJA EDYTORA");
      
      // Bezpieczne usuwanie istniejących edytorów
      if (descriptionEditor && typeof descriptionEditor.destroy === 'function') {
        console.log("Usuwanie edytora opisu");
        descriptionEditor.destroy();
      }
      
      if (recommendationEditor && typeof recommendationEditor.destroy === 'function') {
        console.log("Usuwanie edytora rekomendacji");
        recommendationEditor.destroy();
      }
      
      // Radykalnie przepisana funkcja do przygotowania danych
      const prepareEditorData = (content) => {
        if (!content || typeof content !== 'string') {
          console.log("Brak treści lub nieprawidłowy format");
          return { blocks: [] };
        }
        
        try {
          console.log("Przetwarzanie treści, długość:", content.length);
          
          // Traktowanie istniejących danych JSON
          if (content.trim().startsWith('{')) {
            try {
              const parsed = JSON.parse(content);
              if (parsed.blocks) {
                console.log("Znaleziono istniejące bloki JSON:", parsed.blocks.length);
                
                // Usuwamy duplikaty nawet z istniejących bloków JSON
                const uniqueBlocks = [];
                const seenContents = new Set();
                
                parsed.blocks.forEach(block => {
                  if (block.type === 'paragraph') {
                    const text = block.data.text.toLowerCase();
                    if (!seenContents.has(text)) {
                      seenContents.add(text);
                      uniqueBlocks.push(block);
                    } else {
                      console.log("Usunięto duplikat w JSON:", block.data.text.substring(0, 50));
                    }
                  } else {
                    uniqueBlocks.push(block);
                  }
                });
                
                parsed.blocks = uniqueBlocks;
                return parsed;
              }
            } catch (e) {
              console.log("Błąd podczas parsowania JSON:", e.message);
            }
          }
          
          // Usuwanie znaczników
          let cleanedContent = content;
          cleanedContent = cleanedContent.replace(/【[^】]+】/g, '');
          
          // Wykrywanie i usuwanie całych duplikatów tekstu
          if (cleanedContent.length > 100) {
            const halfLength = Math.floor(cleanedContent.length / 2);
            const firstHalf = cleanedContent.substring(0, halfLength).trim();
            const secondHalf = cleanedContent.substring(halfLength).trim();
            
            // Jeśli druga połowa zaczyna się tak jak pierwsza, mamy duplikat
            if (secondHalf.startsWith(firstHalf.substring(0, 50))) {
              console.log("Wykryto duplikat całej treści - używam tylko pierwszej połowy");
              cleanedContent = firstHalf;
            }
          }
          
          // Dzielenie na segmenty - próbujemy znaleźć naturalne granice
          const segments = cleanedContent
            .split(/\n+/)
            .map(s => s.trim())
            .filter(s => s.length > 0);
          
          if (segments.length === 0) {
            console.log("Brak segmentów po podziale");
            return { 
              blocks: [{
                type: 'paragraph',
                data: { text: cleanedContent }
              }]
            };
          }
          
          // Usuwanie dokładnych duplikatów z segmentów
          const uniqueSegments = [];
          const seen = new Set();
          
          segments.forEach(segment => {
            if (!seen.has(segment.toLowerCase())) {
              seen.add(segment.toLowerCase());
              uniqueSegments.push(segment);
            } else {
              console.log("Usunięto duplikat segmentu:", segment.substring(0, 50));
            }
          });
          
          console.log(`Oryginalne segmenty: ${segments.length}, unikalne: ${uniqueSegments.length}`);
          
          return {
            blocks: uniqueSegments.map(segment => ({
              type: 'paragraph',
              data: { text: segment }
            }))
          };
        } catch (error) {
          console.error('Błąd podczas przetwarzania treści:', error);
          return {
            blocks: [{
              type: 'paragraph',
              data: { text: 'Błąd przetwarzania treści' }
            }]
          };
        }
      };

      // Inicjalizacja edytora opisu
      const descEditor = new EditorJS({
        holder: 'description-editor',
        readOnly: !isEditing,
        data: prepareEditorData(insight.description),
        tools: {
          header: {
            class: Header,
            inlineToolbar: true
          },
          list: {
            class: List,
            inlineToolbar: true
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true
          }
        },
        minHeight: 0,
        onChange: () => {
          setIsSaving(true);
          // Poprawione zarządzanie timeoutem (podobnie jak w komponencie Personas)
          if (descTimeoutRef.current) {
            clearTimeout(descTimeoutRef.current);
          }
          descTimeoutRef.current = setTimeout(() => {
            if (descEditor) {
              saveInsightData(descEditor, 'description');
            }
          }, 2000);
        }
      });
      
      setTimeout(() => {
        const editorElements = document.querySelectorAll('.codex-editor__redactor');
        editorElements.forEach(el => {
          el.style.paddingBottom = '0px';
        });
      }, 100);
      
      setDescriptionEditor(descEditor);

      // Inicjalizacja edytora rekomendacji
      const recEditor = new EditorJS({
        holder: 'recommendation-editor',
        readOnly: !isEditing,
        data: prepareEditorData(insight.recommendation),
        tools: {
          header: {
            class: Header,
            inlineToolbar: true
          },
          list: {
            class: List,
            inlineToolbar: true
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true
          }
        },
        minHeight: 0,
        onChange: () => {
          setIsSaving(true);
          // Poprawione zarządzanie timeoutem
          if (recTimeoutRef.current) {
            clearTimeout(recTimeoutRef.current);
          }
          recTimeoutRef.current = setTimeout(() => {
            if (recEditor) {
              saveInsightData(recEditor, 'recommendation');
            }
          }, 2000);
        }
      });
      
      setTimeout(() => {
        const editorElements = document.querySelectorAll('.codex-editor__redactor');
        editorElements.forEach(el => {
          el.style.paddingBottom = '0px';
        });
      }, 100);
      
      setRecommendationEditor(recEditor);
    }

    // Czyszczenie przy odmontowaniu
    return () => {
      // Czyszczenie timeoutów
      if (descTimeoutRef.current) {
        clearTimeout(descTimeoutRef.current);
      }
      if (recTimeoutRef.current) {
        clearTimeout(recTimeoutRef.current);
      }
      
      // Bezpieczne usuwanie edytorów
      if (descriptionEditor && typeof descriptionEditor.destroy === 'function') {
        descriptionEditor.destroy();
      }
      if (recommendationEditor && typeof recommendationEditor.destroy === 'function') {
        recommendationEditor.destroy();
      }
    };
  }, [insight, isEditing]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatDateUpdated = (date) => {
    if (!date) return 'Not saved yet';
    const now = new Date();
    const updatedDate = parseISO(date);
    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;
    if (diffInSeconds < 60) return `${Math.floor(diffInSeconds)} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    return format(updatedDate, 'dd MMM yyyy');
  };

  const getInsightTypeStyle = (type) => {
    const styles = {
      pain: 'text-red-700 bg-red-50 ring-red-600/20',
      gain: 'text-green-700 bg-green-50 ring-green-600/20',
    };
    return styles[type] || 'text-gray-700 bg-gray-50 ring-gray-600/20';
  };

  const getPriorityStyle = (priority) => {
    const styles = {
      high: 'text-red-700 bg-red-50',
      medium: 'text-yellow-700 bg-yellow-50',
      low: 'text-green-700 bg-green-50',
    };
    return styles[priority] || 'text-gray-700 bg-gray-50';
  };

  const saveInsightData = async (editorData, field) => {
    setIsSaving(true);
    try {
      const jsonData = await editorData.save();
      console.log('Saving data for field:', field, jsonData);
      
      // Sprawdźmy, czy to, co zapisujemy, nie ma duplikatów
      if (jsonData.blocks && jsonData.blocks.length > 0) {
        const uniqueBlocks = [];
        const seenContent = new Set();
        
        jsonData.blocks.forEach(block => {
          // Dla bloków typu paragraph sprawdzamy duplikaty
          if (block.type === 'paragraph') {
            const content = block.data.text.toLowerCase();
            if (!seenContent.has(content)) {
              seenContent.add(content);
              uniqueBlocks.push(block);
            } else {
              console.log('Skipping duplicate block:', block.data.text);
            }
          } else {
            // Dla innych typów bloków (header, list, itp.) zawsze dodajemy
            uniqueBlocks.push(block);
          }
        });
        
        jsonData.blocks = uniqueBlocks;
      }
      
      const updatedInsight = {
        ...insight,
        [field]: JSON.stringify(jsonData)
      };
      
      await axios.patch(`${DIRECTUS_INSIGHTS_ENDPOINT}/${id}`, updatedInsight, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setInsight(updatedInsight);
      setLastSaved(new Date().toISOString());
      setTimeout(() => setIsSaving(false), 500);
    } catch (err) {
      console.error('Error saving insight data:', err);
      setIsSaving(false);
    }
  };

  if (loading) return (
    <div className="animate-pulse p-8">
      <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    </div>
  );

  if (error) return <div className="text-red-500 p-8">{error}</div>;

  if (!insight) return <div className="p-8">No insight found</div>;

  return (
    <div className="lg:pl-24">
      {/* Header */}
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 pb-4 pt-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/insights')}
                className="mr-4 text-gray-400 hover:text-gray-500"
              >
                <ArrowLeftIcon className="h-6 w-6" />
              </button>
              <div>
                <h1 className="text-2xl font-semibold text-gray-900">
                  {insight?.title || 'Untitled Insight'}
                </h1>
                <p className="mt-1 text-sm text-gray-500">View and manage insight information</p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* Status indicator */}
              <div className="flex gap-2 items-center justify-center">
                {isSaving ? (
                  <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-green-500" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  <CloudArrowUpIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                )}
                <span className="text-sm text-neutral-600 text-center">
                  {isSaving ? 'Saving...' : `Saved ${formatDateUpdated(lastSaved)}`}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Main content grid */}
        <div className="mx-auto py-16">
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-12">
            {/* Main content - 8 cols */}
            <div className="lg:col-span-8">
              <div className="space-y-10">
                {/* Description Section */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Description</h3>
                  <div id="description-editor" className="mt-2 prose max-w-none " />
                </div>

                {/* Recommendation Section */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Recommendation</h3>
                  <div id="recommendation-editor" className="mt-2 prose max-w-none" />
                </div>

                {/* User Journey Section */}
                <div>
                  <div className="flex items-center gap-2">
                    <MapIcon className="h-5 w-5 text-gray-400" />
                    <h3 className="text-lg font-medium text-gray-900">User Journey</h3>
                  </div>
                  <div className="mt-4">
                    {insight?.userjourney ? (
                      <Link 
                        to={`/journeys/${insight.userjourney}`} 
                        className="block p-4 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-gray-600">{insight.userjourney_title || 'View User Journey Details'}</p>
                            <p className="text-sm text-gray-500 mt-1">Click to view the full user journey</p>
                          </div>
                          <ArrowLeftIcon className="h-5 w-5 text-gray-400 transform rotate-180" />
                        </div>
                      </Link>
                    ) : (
                      <PlaceholderCard type="user journey" />
                    )}
                  </div>
                </div>

                {/* Heatmap Section */}
                <div>
                  <div className="flex items-center gap-2">
                    <ChartBarIcon className="h-5 w-5 text-gray-400" />
                    <h3 className="text-lg font-medium text-gray-900">Heatmap</h3>
                  </div>
                  <div className="mt-4">
                    {insight?.heatmap ? (
                      <Link 
                        to={`/predictions/${insight.heatmap}`} 
                        className="block p-4 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="text-gray-600">{insight.heatmap_title || 'View Heatmap Analysis'}</p>
                            <p className="text-sm text-gray-500 mt-1">Click to view the full heatmap analysis</p>
                          </div>
                          <ArrowLeftIcon className="h-5 w-5 text-gray-400 transform rotate-180" />
                        </div>
                      </Link>
                    ) : (
                      <PlaceholderCard type="heatmap" />
                    )}
                  </div>
                </div>

                {/* Comments Section */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Comments</h3>
                  {comments.map((comment) => (
                    <div key={comment.id} className="flex space-x-3 py-4">
                      <div className="flex-shrink-0">
                        {comment.avatar ? (
                          <img 
                            src={`${import.meta.env.VITE_DIRECTUS_URL}/assets/${comment.avatar}?width=40&height=40&fit=cover`}
                            alt={`${comment.author}'s avatar`}
                            className="h-8 w-8 rounded-full object-cover"
                          />
                        ) : (
                          <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-600">
                            {comment.author?.charAt(0).toUpperCase() || 'U'}
                          </div>
                        )}
                      </div>
                      <div className="flex-grow">
                        <div className="flex items-center justify-between">
                          <h3 className="text-sm font-medium text-gray-900">{comment.author}</h3>
                          <p className="text-xs text-gray-500">
                            {format(parseISO(comment.date), 'MMM d, yyyy, h:mm a')}
                          </p>
                        </div>
                        <div className="mt-1 text-sm text-gray-700">
                          <p>{comment.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Sidebar - 4 cols */}
            <div className="lg:col-span-4">
              <div className="space-y-6">
                {/* Business Value */}
                <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-6">
                  <h3 className="text-sm font-medium text-gray-900">Business Value</h3>
                  <div className="mt-4">
                    <ValueBar value={insight?.value || 75} />
                  </div>
                </div>

                {/* Existing Info Card */}
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                    {/* Type */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Type</dt>
                      <dd className="mt-1">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${getInsightTypeStyle(insight?.insight_type)}`}>
                          {insight?.insight_type || 'behavioral'}
                        </span>
                      </dd>
                    </div>

                    {/* Score */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Score</dt>
                      <dd className="mt-1">
                        <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {insight?.score_value || 0}
                        </span>
                      </dd>
                    </div>

                    {/* Priority */}
                    <div className="flex-auto px-6 py-4">
                      <dt className="text-sm font-medium text-gray-500">Priority</dt>
                      <dd className="mt-1">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${getPriorityStyle(insight?.piority)}`}>
                          {insight?.piority || 'medium'}
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>

                {/* Insight Author */}
                <div className="flex items-center mt-2">
                  <UserAvatar user={insight?.user_created} />
                  <span className="ml-2 text-sm text-gray-600">
                    {insight?.user_created?.first_name} {insight?.user_created?.last_name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsightDetails;