import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContentHeader from '../Atoms/contentHeader';
import Loader from './loader';
import { useDropzone } from 'react-dropzone';
import { drawGridOnImage } from './utils';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/24/solid';
import { Menu } from '@headlessui/react';

const DIRECTUS_PROJECTS_ENDPOINT = 'https://panel.reislo.com/items/projects';

const directusAPI = axios.create();

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

function HeatmapsAnalysis() {
  const [url, setUrl] = useState('');
  const [imageSrc, setImageSrc] = useState({ desktop: '', mobile: '' });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [inputMethod, setInputMethod] = useState(null);
  const [apiDelay, setApiDelay] = useState(10);
  const [heatmapId, setHeatmapId] = useState(null);
  const [analysisType, setAnalysisType] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [projectError, setProjectError] = useState('');
  const [sourceType, setSourceType] = useState('url');
  const [personas, setPersonas] = useState([]);
  const [selectedPersonaIds, setSelectedPersonaIds] = useState([]);
  const [isLoadingPersonas, setIsLoadingPersonas] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      const token = localStorage.getItem('directus_token');
      const userId = localStorage.getItem('user_id');
      
      if (!token) {
        setProjectError('Failed to fetch projects: No token found');
        return;
      }
      
      setIsLoadingProjects(true);
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: { filter: { owner: userId } }
        });
        setProjects(response.data.data);
      } catch (error) {
        setProjectError(`Failed to fetch projects: ${error.response ? error.response.statusText : error.message}`);
      } finally {
        setIsLoadingProjects(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchPersonas = async () => {
      if (!selectedProjectId) {
        setPersonas([]);
        setSelectedPersonaIds([]);
        return;
      }

      setIsLoadingPersonas(true);
      try {
        const response = await directusAPI.get('https://panel.reislo.com/items/personas', {
          params: {
            filter: { project: { _eq: selectedProjectId } },
            fields: ['id', 'name', 'type', 'avatar.*']
          }
        });
        setPersonas(response.data.data);
      } catch (error) {
        console.error('Failed to fetch personas:', error);
      } finally {
        setIsLoadingPersonas(false);
      }
    };

    fetchPersonas();
  }, [selectedProjectId]);

  const analysisTypes = [
    { value: 'homepage', label: 'Homepage' },
    { value: 'categories', label: 'Categories' },
    { value: 'cart', label: 'Cart' },
    { value: 'checkout', label: 'Checkout' },
    { value: 'wishlist', label: 'Wishlist' },
    { value: 'product_page', label: 'Product Page' },
    { value: 'landing_page', label: 'Landing Page' },
    { value: 'about_us', label: 'About Us' },
    { value: 'contact', label: 'Contact' },
    { value: 'search', label: 'Search' },
    { value: 'other', label: 'Other' }
  ];

  const normalizeUrl = (inputUrl) => {
    if (!inputUrl.match(/^[a-zA-Z]+:\/\//)) {
      return 'https://' + inputUrl;
    } else if (inputUrl.startsWith('http://')) {
      return inputUrl.replace('http://', 'https://');
    }
    return inputUrl;
  };

  const extractDomain = (url) => {
    const domain = url.replace(/^https?:\/\//, '').replace(/\/$/, '').split('/')[0];
    return domain.replace(/\.(com|org|net|io|etc)$/, '');
  };

  const handleSearchChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    
    if (!selectedProjectId) {
      setUploadError('Please select a project before continuing');
      return;
    }
    
    setInputMethod('search');
    const normalizedUrl = normalizeUrl(url);
    await captureScreenshots(normalizedUrl);
  };

  const captureScreenshots = async (normalizedUrl) => {
    try {
      setIsUploading(true);
      const [desktopScreenshot, mobileScreenshot] = await Promise.all([
        captureScreenshot(normalizedUrl, 'desktop'),
        captureScreenshot(normalizedUrl, 'mobile')
      ]);

      const title = extractDomain(url);
      const newHeatmapId = await uploadOriginalAndGridImages(desktopScreenshot, mobileScreenshot, title);
      setHeatmapId(newHeatmapId);
      setSubmitStatus('success');
      setShowTooltip(true);
    } catch (error) {
      console.error('Failed to capture screenshots:', error);
      setSubmitStatus('error');
    } finally {
      setIsUploading(false);
    }
  };

  const captureScreenshot = async (url, device) => {
    const apiFlashEndpoint = `https://api.apiflash.com/v1/urltoimage?access_key=c01c69456f234dd094bc4415e61018eb&url=${encodeURIComponent(url)}&format=webp&full_page=true&delay=${apiDelay}&scroll_page=true&response_type=image&no_cookie_banners=true&no_ads=true&no_tracking=true&element_overlap=true&wait_until=page_loaded${device === 'mobile' ? '&width=375&height=667' : ''}`;

    const response = await axios.get(apiFlashEndpoint, { responseType: 'arraybuffer' });
    if (response.status === 200) {
      const screenshot = new Blob([response.data], { type: 'image/webp' });
      const screenshotUrl = URL.createObjectURL(screenshot);
      setImageSrc(prev => ({ ...prev, [device]: screenshotUrl }));
      console.log(`${device} screenshot captured successfully:`, screenshotUrl);
      return screenshot;
    } else {
      throw new Error(`Failed to capture ${device} screenshot`);
    }
  };

  const uploadOriginalAndGridImages = async (desktopFile, mobileFile, title) => {
    try {
      const desktopOriginalId = await uploadImageToDirectus(desktopFile, 'desktop');
      const mobileOriginalId = await uploadImageToDirectus(mobileFile, 'mobile');

      const desktopGridImage = await createGridImage(desktopFile);
      const mobileGridImage = await createGridImage(mobileFile);

      const desktopGridId = await uploadImageToDirectus(desktopGridImage, 'desktop', 'grid');
      const mobileGridId = await uploadImageToDirectus(mobileGridImage, 'mobile', 'grid');

      const heatmapId = await saveHeatmapToCollection(title, desktopOriginalId, mobileOriginalId, desktopGridId, mobileGridId);
      console.log('Heatmap saved successfully with both desktop and mobile images.');
      return heatmapId;
    } catch (error) {
      console.error('Failed to upload images or save heatmap:', error);
      throw error;
    }
  };

  const uploadImageToDirectus = async (file, device, type = 'screenshot') => {
    const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
    const filename = `${type}_${device}_${timestamp}.webp`;
    const formData = new FormData();
    formData.append('file', file, filename);

    try {
      const uploadResponse = await directusAPI.post('https://panel.reislo.com/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(`Directus upload response for ${device} ${type}:`, uploadResponse.data);
      return uploadResponse.data.data.id;
    } catch (error) {
      console.error(`Error uploading ${device} ${type} file:`, error);
      throw error;
    }
  };

  const createGridImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const img = new Image();
        img.src = loadEvent.target.result;

        img.onload = function () {
          const gridDataUrl = drawGridOnImage(img, 100);
          fetch(gridDataUrl)
            .then((res) => res.blob())
            .then(resolve)
            .catch(reject);
        };

        img.onerror = (error) => {
          console.error('Error loading image for grid processing:', error);
          reject(error);
        };
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const saveHeatmapToCollection = async (title, desktopOriginalId, mobileOriginalId, desktopGridId, mobileGridId) => {
    try {
      const response = await directusAPI.post('https://panel.reislo.com/items/heatmaps', {
        title,
        screenshot: desktopOriginalId,
        screenshot_mobile: mobileOriginalId,
        coordinates_grid_screenshot: desktopGridId,
        coordinates_grid_screenshot_mobile: mobileGridId,
        project: selectedProjectId || null,
        analysis_type: analysisType,
        type: analysisType,
        linked_personas: selectedPersonaIds.map(id => ({
          personas_id: id
        }))
      });
      console.log('Saved to collection:', response.data);
      return response.data.data.id;
    } catch (error) {
      console.error('Failed to save to collection:', error);
      throw error;
    }
  };

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    
    if (!selectedProjectId) {
      setUploadError('Please select a project before uploading images');
      return;
    }

    setInputMethod('dropzone');
    setIsUploading(true);
    setUploadError(null);

    try {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const img = new Image();
        img.src = loadEvent.target.result;

        img.onload = function () {
          const processedURL = drawGridOnImage(img, 100);
          setImageSrc({ desktop: processedURL, mobile: processedURL });
        };

        img.onerror = (error) => {
          console.error('Error loading image for grid processing:', error);
          setUploadError('Error processing the grid image.');
        };
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setUploadError('Error reading the uploaded file.');
      };

      reader.readAsDataURL(file);

      const title = file.name.replace(/\.[^/.]+$/, "");
      const newHeatmapId = await uploadOriginalAndGridImages(file, file, title);
      setHeatmapId(newHeatmapId);
      console.log('Heatmap created successfully with the uploaded screenshot!');
      setSubmitStatus('success');
      setShowTooltip(true);
    } catch (error) {
      setUploadError('Failed to upload the screenshot or create the heatmap item.');
      console.error('Failed to upload the screenshot or create the heatmap item.', error);
      setSubmitStatus('error');
    } finally {
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*',
  });

  const handleTooltipResponse = (isOk) => {
    if (isOk) {
      navigate(`/predictions/${heatmapId}`);
    } else {
      setApiDelay(prev => prev + 5);
      setImageSrc({ desktop: '', mobile: '' });
      setInputMethod(null);
      if (inputMethod === 'search') {
        captureScreenshots(normalizeUrl(url));
      }
    }
    setShowTooltip(false);
  };

  const handleRemoveImage = () => {
    setImageSrc({ desktop: '', mobile: '' });
    setShowTooltip(false);
    setInputMethod(null);
  };

  const handleAnalysisTypeChange = (e) => {
    setAnalysisType(e.target.value);
  };

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects');
    } else {
      setSelectedProjectId(value);
    }
  };

  return (
    <div className="py-16">
      <div className="max-w-5xl mx-auto bg-white px-10 pb-12 border border-neutral-200 rounded-lg pt-10">
        <ContentHeader 
          title="User Experience Analysis" 
          description="Import a screenshot of the page you want to evaluate, or enter a URL to automatically capture the page. This will help analyze user behavior and interaction patterns." 
        />
        <div className="grid grid-cols-1  mt-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Analysis Type
            </label>
            <select
              value={analysisType}
              onChange={handleAnalysisTypeChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            >
              <option value="">Select Analysis Type</option>
              {analysisTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            {analysisType === 'other' && (
              <input
                type="text"
                placeholder="Specify analysis type"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                onChange={(e) => setAnalysisType(e.target.value)}
              />
            )}
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Project
            </label>
            <select
              value={selectedProjectId}
              onChange={handleProjectChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            >
              <option value="">Select Project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.project_name}
                </option>
              ))}
              <option value="new-project">Create New Project</option>
            </select>
          </div>
        </div>
        <div className="mt-8">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Linked Personas
          </label>
          <Menu as="div" className="relative">
            <Menu.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-500 focus:ring-1 focus:ring-primary-500 sm:text-sm">
              <span className="block truncate">
                {selectedPersonaIds.length > 0 
                  ? `${selectedPersonaIds.length} personas selected`
                  : 'Select personas'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Menu.Button>

            <Menu.Items className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {personas.map((persona) => (
                <Menu.Item key={persona.id}>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? 'bg-primary-50' : ''
                      } relative cursor-pointer select-none py-2 pl-3 pr-9`}
                      onClick={() => {
                        setSelectedPersonaIds(prev => {
                          const isSelected = prev.includes(persona.id);
                          if (isSelected) {
                            return prev.filter(id => id !== persona.id);
                          } else {
                            return [...prev, persona.id];
                          }
                        });
                      }}
                    >
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedPersonaIds.includes(persona.id)}
                          className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                          onChange={() => {}}
                        />
                        <span className="ml-3 block truncate">
                          {persona.name} - {persona.type}
                        </span>
                      </div>
                      {selectedPersonaIds.includes(persona.id) && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          
          <div className="mt-2 flex flex-wrap gap-2">
            {selectedPersonaIds.map(id => {
              const persona = personas.find(p => p.id === id);
              return persona ? (
                <span
                  key={persona.id}
                  className="inline-flex items-center rounded-full bg-primary-50 px-2 py-1 text-xs font-medium text-primary-700"
                >
                  {persona.name}
                  <button
                    type="button"
                    onClick={() => setSelectedPersonaIds(prev => prev.filter(pId => pId !== persona.id))}
                    className="ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:bg-primary-500 focus:text-white focus:outline-none"
                  >
                    <span className="sr-only">Remove {persona.name}</span>
                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                      <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                  </button>
                </span>
              ) : null;
            })}
          </div>
        </div>
        <div className="mt-8">
          <h3 className="text-lg font-medium text-gray-900">Select source</h3>
          <p className="text-sm text-gray-500 mb-4">Please choose from screenshot upload jpg or add URL and we'll do screenshot for you</p>
          
          <div className="flex gap-4 mb-6">
            <button
              onClick={() => setSourceType('url')}
              className={`px-4 py-2 rounded-full ${
                sourceType === 'url'
                  ? 'bg-primary-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              From URL
            </button>
            <button
              onClick={() => setSourceType('file')}
              className={`px-4 py-2 rounded-full ${
                sourceType === 'file'
                  ? 'bg-primary-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              From File
            </button>
          </div>

          {sourceType === 'url' ? (
            <form onSubmit={handleSearchSubmit} className="mx-auto mt-2">
              <div className="relative">
                <input 
                  type="search" 
                  id="default-search" 
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Type Your Page URL to Analyse" 
                  required 
                  value={url} 
                  onChange={handleSearchChange} 
                />
                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2">
                  Search
                </button>
              </div>
            </form>
          ) : (
            <div {...getRootProps({ className: 'dropzone mt-4 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center' })}>
              <input {...getInputProps()} />
              <p>Drag & drop a screenshot here, or click to select one</p>
            </div>
          )}
        </div>
        {isUploading && (
          <div className="loader-container">
            <Loader isLoading={isUploading} submitStatus={submitStatus} />
          </div>
        )}
        {(imageSrc.desktop || imageSrc.mobile) && (
          <div className="mt-16 relative">

            <h2 className="mt-16 ">Desktop</h2>
            {imageSrc.desktop && <img src={imageSrc.desktop} alt="Desktop Screenshot" style={{ maxWidth: '100%' }} />}

            <h2 className="mt-16 ">Mobile</h2>
            {imageSrc.mobile && <img src={imageSrc.mobile} alt="Mobile Screenshot" className="w-full max-w-lg mx-auto " />}
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 "
            >
              <XCircleIcon className="w-12 text-neutral-700 hover:text-neutral-900 hover:cursor-pointer" />
            </button>
          </div>
        )}
        {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
        {showTooltip && (
          <div className="fixed bg-opacity-95 max-w-xl mx-auto rounded-xl bottom-6 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between text-center items-center">
            <span></span>
            <span className="text-center text-lg">Are the screenshots okay?</span>
            <div className="flex gap-4">
              <button
                onClick={() => handleTooltipResponse(false)}
                className="bg-transparent text-white p-2 rounded"
              >
                No, retry
              </button>
              <button
                onClick={() => handleTooltipResponse(true)}
                className="bg-primary-500 text-white p-2 px-4 rounded mr-2"
              >
                Yes, continue
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeatmapsAnalysis;