import React, { useEffect, useRef } from 'react';
import { graphviz } from 'd3-graphviz';

const GraphvizRenderer = ({ dotSource }) => {
  const graphContainer = useRef(null);

  useEffect(() => {
    if (graphContainer.current && dotSource) {
      try {
        // Clear previous graph
        while (graphContainer.current.firstChild) {
          graphContainer.current.removeChild(graphContainer.current.firstChild);
        }
        
        // Render graphviz diagram
        graphviz(graphContainer.current)
          .width(600)
          .height(400)
          .fit(true)
          .zoom(false)
          .renderDot(dotSource);
      } catch (error) {
        console.error('Error rendering graphviz diagram:', error);
      }
    }
  }, [dotSource]);

  return (
    <div className="w-full overflow-auto bg-white p-4 rounded-md shadow-sm mb-4">
      <div className="graphviz-container" ref={graphContainer}></div>
    </div>
  );
};

export default GraphvizRenderer; 