// Ten plik zawiera funkcje symulujące odpowiedzi API dla testów
export const getMockResponse = async (message) => {
  // Poczekaj 1-2 sekundy, aby symulować opóźnienie API
  await new Promise(resolve => setTimeout(resolve, 1000 + Math.random() * 1000));
  
  // Standardowa odpowiedź dla testów
  return `To jest testowa odpowiedź asystenta. Otrzymałem wiadomość: "${message}"

Niestety, w tym momencie mam problem z połączeniem z API OpenAI, ale nadal mogę pomóc z podstawowymi pytaniami.

Funkcje dostępne w tej wersji testowej:
- Podstawowe odpowiedzi na pytania
- Formatowanie tekstu markdown
- Przykładowe rekomendacje

**Czy potrzebujesz pomocy w konkretnym obszarze?**`;
}; 