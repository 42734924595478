import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

// Accordion component for FAQs
const FAQAccordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="border border-gray-200 rounded-md overflow-hidden mb-3">
      <button
        className="w-full flex items-center justify-between p-4 text-left bg-white hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="text-lg font-medium text-gray-800">{question}</h4>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-500 flex-shrink-0" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-500 flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <div className="p-4 pt-0 bg-white border-t border-gray-200">
          <p className="pt-4 text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

const drawerContent = {
  biography: {
    title: "About the Personal Biography",
    content: (
      <div className="space-y-8">
        <div className="border-l-4 border-primary-500 pl-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">What is a Personal Biography?</h3>
          <p className="pt-4 text-gray-600">
            The personal biography provides a realistic representation of your target user's daily life and habits, including the small details that make them feel real and relatable.
          </p>
        </div>
        
        <div className="bg-gradient-to-r from-gray-50 to-white p-5 rounded-lg shadow-sm">
          <h3 className="text-xl text-md font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Psychological Importance</h3>
          <p className="pt-4 text-gray-600">
            Detailed personal biographies with concrete daily routines transform abstract users into relatable individuals, enhancing design empathy. Research shows that specific lifestyle details help teams connect emotionally with personas, making them more memorable and actionable. These vivid details prevent self-referential design by grounding personas in authentic human experiences.
          </p>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Example</h3>
          <div className="bg-gray-50 p-4 border-l-4 border-secondary-400">
            <p className="pt-4 text-gray-600 mt-1">
              <strong>Busy tech manager:</strong> Sarah wakes up at 5:30 AM to exercise before her kids are awake. She listens to business podcasts during her morning commute in her Tesla Model Y. Her Spotify playlists are filled with 90s alternative rock for focus time. She stops at Blue Bottle Coffee each morning for an oat milk latte before heading into the office.
            </p>
          </div>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4 border-b border-gray-200 pb-2">FAQs</h3>
          <div className="space-y-2">
            <FAQAccordion 
              question="What daily details should I include in the biography?"
              answer="Focus on specific lifestyle details that bring the persona to life: typical wake-up time, preferred music or podcasts, vehicle they drive, coffee shop preferences, morning and evening routines, favorite apps, and daily habits. Research shows these concrete details make personas more relatable and help design teams better understand how products fit into users' actual lives."
            />
            <FAQAccordion 
              question="Should I include fictional details?"
              answer="Yes, but base them on research. As Pruitt and Grudin noted in their seminal Microsoft research, fictional details help create a complete picture but must reflect realistic characteristics. The 'precision paradox' suggests that specific yet research-based details make personas more memorable and useful for design teams."
            />
            <FAQAccordion 
              question="How do I balance demographic and psychographic information?"
              answer="Lene Nielsen's research on persona models emphasizes that psychographic information (attitudes, interests, values) better predicts behavior than demographics alone. Her four-perspective approach suggests balancing role, goal, skill and requirement information for comprehensive personas."
            />
            <FAQAccordion 
              question="Can I update this information later?"
              answer="Personas should evolve through what Pruitt and Adlin call the 'persona lifecycle.' Research from the HCI community suggests personas should be living documents, updated as user research reveals new insights. Long-term studies by Microsoft found that successful product teams revisit personas quarterly."
            />
            <FAQAccordion 
              question="How many personas should I create?"
              answer="Research on cognitive load in design teams (Miaskiewicz & Kozar, 2011) suggests focusing on 3-5 primary personas. Creating too many dilutes focus, while too few oversimplifies user diversity. The concept of 'primary' and 'secondary' personas helps prioritize design decisions when needs conflict."
            />
            <FAQAccordion 
              question="How do I ensure my personas aren't stereotypes?"
              answer="'The Essential Persona Lifecycle' emphasizes that personas should be archetypes, not stereotypes. Rigorous research methods, diverse data sources, and team reviews help prevent bias. Studies by Turner and Turner (2011) show that participatory persona creation with diverse stakeholders reduces stereotyping."
            />
          </div>
        </div>
      </div>
    ),
  },
  motivations: {
    title: "About Motivations",
    content: (
      <div className="space-y-8">
        <div className="border-l-4 border-primary-500 pl-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">What are Persona Motivations?</h3>
          <p className="pt-4 text-gray-600">
            Motivations describe what drives your persona's behavior and decisions - the "why" behind their actions and choices.
          </p>
        </div>
        
        <div className="bg-gradient-to-r from-gray-50 to-white p-5 rounded-lg shadow-sm">
          <h3 className="text-xl text-md font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Psychological Importance</h3>
          <p className="pt-4 text-gray-600">
            Goal-directed design research demonstrates that understanding motivations predicts behavior more accurately than demographics. The concept of "Jobs to be Done" (Christensen, 2016) reinforces that users "hire" products to fulfill emotional and functional motivations. By understanding these underlying drivers, designers create products that align with users' internal motivations rather than just surface-level actions.
          </p>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Example</h3>
          <div className="bg-gray-50 p-4  border-l-4 border-secondary-400">
            <p className="pt-4 text-gray-600 mt-1">
              <strong>Career advancement:</strong> Seeks professional recognition and tools that showcase expertise.
            </p>
          </div>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4 border-b border-gray-200 pb-2">FAQs</h3>
          <div className="space-y-2">
            <FAQAccordion 
              question="How do I identify real user motivations?"
              answer="According to research methods outlined in 'Mental Models' by Indi Young, motivations are best uncovered through contextual inquiry and laddering interview techniques. These methods dig beneath surface statements to uncover true motivations. The 'five whys' technique from Toyota's methodology helps excavate deeper motivations beneath initial responses."
            />
            <FAQAccordion 
              question="Should I include both personal and professional motivations?"
              answer="Research by Hassenzahl in 'Experience Design' demonstrates that users rarely compartmentalize their motivations completely. His model of pragmatic and hedonic user needs suggests including both instrumental goals and identity/emotional motivations for a complete picture of user behavior."
            />
            <FAQAccordion 
              question="How many motivations should I list?"
              answer="Cognitive research on decision-making from Kahneman ('Thinking, Fast and Slow') suggests humans prioritize a limited set of motivations when making decisions. Focus on 3-5 strong motivations that most directly influence the persona's interaction with your product rather than an exhaustive list."
            />
            <FAQAccordion 
              question="Can motivations change over time?"
              answer="Longitudinal research on user behavior by Nielsen Norman Group confirms that motivations evolve over time. Their studies show that life events, market changes, and new experiences shift user motivations. Updating personas periodically ensures they reflect current motivational drivers."
            />
            <FAQAccordion 
              question="How do motivations differ from needs?"
              answer="In 'The Elements of User Experience,' Jesse James Garrett distinguishes between motivations (why people act) and needs (what they require). Motivations are psychological drivers that explain behavior, while needs are concrete requirements that must be satisfied. Both work together to predict user behavior."
            />
            <FAQAccordion 
              question="Should I prioritize motivations?"
              answer="Maslow's hierarchy of needs, applied to user experience by Walter (2011), suggests that motivations follow a hierarchy. Research from the Stanford Persuasive Technology Lab demonstrates that users prioritize different motivations in different contexts, making motivation prioritization essential for design scenarios."
            />
          </div>
        </div>
      </div>
    ),
  },
  needs: {
    title: "About Needs",
    content: (
      <div className="space-y-8">
        <div className="border-l-4 border-primary-500 pl-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">What are Persona Needs?</h3>
          <p className="pt-4 text-gray-600">
            Needs represent what specific outcomes or functionalities your persona requires - the concrete "what" they seek from your product or service.
          </p>
        </div>
        
        <div className="bg-gradient-to-r from-gray-50 to-white p-5 rounded-lg shadow-sm">
          <h3 className="text-xl text-md font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Psychological Importance</h3>
          <p className="pt-4 text-gray-600">
            Research on cognitive ergonomics emphasizes understanding practical needs for effective interface design. The concept of "user-centered design" developed through research at the Xerox PARC lab demonstrates that designing for specific user needs creates more successful products than adding features based on technical possibilities. This needs-based approach leads to focused, usable products that solve real problems.
          </p>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Example</h3>
          <div className="bg-gray-50 p-4  border-l-4 border-secondary-400">
            <p className="pt-4 text-gray-600 mt-1">
              <strong>Mobile data access:</strong> Requires customer information on-the-go with minimal training needed.
            </p>
          </div>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4 border-b border-gray-200 pb-2">FAQs</h3>
          <div className="space-y-2">
            <FAQAccordion 
              question="What's the difference between needs and wants?"
              answer="Don Norman's 'The Design of Everyday Things' distinguishes between user needs (essential requirements) and wants (desirable features). Research on Kano modeling classifies needs as 'must-have' vs. 'delighter' features, confirming that unmet basic needs cause dissatisfaction regardless of other features, while fulfilled advanced wants create disproportionate satisfaction."
            />
            <FAQAccordion 
              question="How detailed should I be when describing needs?"
              answer="Research from 'Contextual Design' (Beyer & Holtzblatt) recommends focusing on outcome needs rather than implementation specifics. Their work demonstrates that too-specific needs can constrain innovation, while outcome-focused needs provide clear direction without prescribing solutions."
            />
            <FAQAccordion 
              question="How do I prioritize conflicting needs between personas?"
              answer="'The Persona Lifecycle' suggests using primary persona methodology to resolve conflicts. Research by Jared Spool on designing for multiple audiences confirms that addressing primary persona needs first, then accommodating secondary personas where possible, creates the most successful outcomes. Interfaces often require different modes for different user types."
            />
            <FAQAccordion 
              question="Should I include needs that my product doesn't currently address?"
              answer="Research on innovation from Clayton Christensen emphasizes documenting unmet needs as opportunities. Studies on product evolution show that understanding the complete need landscape helps identify gaps in current offerings and indicates future development directions. User-centered rather than product-centered thinking yields more innovative solutions."
            />
            <FAQAccordion 
              question="How do I validate that I've identified the correct needs?"
              answer="HCI research by Dumas and Redish demonstrates that user testing is the most reliable validation method. Their work shows observational methods often reveal needs users themselves didn't articulate. A/B testing, prototype evaluation, and tracking feature usage all help validate that identified needs are accurate."
            />
            <FAQAccordion 
              question="How do needs evolve across the customer journey?"
              answer="Research on the customer journey by Rawson, Duncan and Jones (Harvard Business Review) shows that needs vary at different stages of product interaction. Their longitudinal studies demonstrate the importance of mapping needs to specific journey points rather than treating them as static requirements."
            />
          </div>
        </div>
      </div>
    ),
  },
  frustrations: {
    title: "About Frustrations",
    content: (
      <div className="space-y-8">
        <div className="border-l-4 border-primary-500 pl-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">What are Persona Frustrations?</h3>
          <p className="pt-4 text-gray-600">
            Frustrations (pain points) are the challenges and problems your persona experiences that your solution might address or resolve.
          </p>
        </div>
        
        <div className="bg-gradient-to-r from-gray-50 to-white p-5 rounded-lg shadow-sm">
          <h3 className="text-xl text-md font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Psychological Importance</h3>
          <p className="pt-4 text-gray-600">
            Cognitive psychology research shows that users develop mental models based on previous experiences, and mismatches between these models and interfaces create frustration. The concept of "mental model alignment" from HCI research demonstrates that understanding pain points helps create intuitive experiences. Products that solve real problems rather than simply adding features generate greater user satisfaction and loyalty.
          </p>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-3 border-b border-gray-200 pb-2">Example</h3>
          <div className="bg-gray-50 p-4  border-l-4 border-secondary-400">
            <p className="pt-4 text-gray-600 mt-1">
              <strong>System fragmentation:</strong> Dislikes multiple logins and manually transferring data between systems.
            </p>
          </div>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4 border-b border-gray-200 pb-2">FAQs</h3>
          <div className="space-y-2">
            <FAQAccordion 
              question="How do I discover real user frustrations?"
              answer="Research methodologies outlined in 'Observing the User Experience' (Goodman et al.) demonstrate that customer support logs, contextual interviews, and direct observation are most effective for uncovering frustrations. Their studies show users often can't articulate frustrations in surveys but reveal them through behavior and emotional expressions."
            />
            <FAQAccordion 
              question="Should I include minor frustrations or focus only on major ones?"
              answer="Research by the Nielsen Norman Group on user satisfaction demonstrates that minor frustrations have cumulative effects. Their concept of 'death by a thousand paper cuts' shows that while major frustrations drive product abandonment, addressing minor frustrations significantly improves retention and satisfaction."
            />
            <FAQAccordion 
              question="What if different personas have contradictory frustrations?"
              answer="Research from 'The Essential Persona Lifecycle' shows that contradictory frustrations often highlight divergent user needs requiring different interface paths. Studies by Forrester Research on personalization demonstrate that user segments sometimes need distinct experiences rather than one-size-fits-all solutions."
            />
            <FAQAccordion 
              question="How do I prioritize which frustrations to address?"
              answer="Research from the field of product management suggests using a frustration impact matrix that scores pain points on frequency, severity, and business impact. Kano modeling research confirms that removing basic frustrations has higher impact than addressing fringe cases, though niche frustrations may represent valuable differentiation opportunities."
            />
            <FAQAccordion 
              question="Can competitor products be sources of frustration?"
              answer="Research from 'Blue Ocean Strategy' (Kim & Mauborgne) demonstrates that understanding frustrations with competitor products reveals innovation opportunities. Their concept of 'value innovation' shows that addressing frustrations ignored by competitors creates market differentiation and customer loyalty."
            />
            <FAQAccordion 
              question="How do cultural differences affect frustrations?"
              answer="Cross-cultural UX research by Aaron Marcus and Emilie Gould shows that cultural dimensions significantly impact what users find frustrating. Their application of Hofstede's cultural dimensions to UX design demonstrates that frustration patterns vary across cultures and should be analyzed within cultural contexts."
            />
          </div>
        </div>
      </div>
    ),
  },
};

export default drawerContent; 