import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from './loader';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../api';
import Stepper from '../Stepper/stepper';
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import drawerContent from './personaInfoDrawers';

const PersonasForm = ({ handleSubmit }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projectDetails, setProjectDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({});
  const token = localStorage.getItem('directus_token'); // Retrieve the stored token
  const userId = localStorage.getItem('user_id'); // Assuming user_id is stored in local storage
  const navigate = useNavigate(); // Using useNavigate hook from react-router-dom

  const [currentStep, setCurrentStep] = useState(1);

  const [fieldErrors, setFieldErrors] = useState({});

  const [activeInfoDrawer, setActiveInfoDrawer] = useState(null);

  const title = "Create Personas";
  const description = "Select your desired type - be it personas, buyer personas, or proto personas, and fill out the form below with precision. Accurate and detailed information ensures that your personas capture the essence of your target audience. The better your persona represents your user, the more effectively you can meet their unique needs and preferences, and create a more personalized user experience.";

  const steps = [
    { 
      id: 'Step 1',
      title: 'Project Selection',
      status: currentStep === 1 ? 'current' : currentStep > 1 ? 'complete' : 'upcoming'
    },
    { 
      id: 'Step 2',
      title: 'Personal Information',
      status: currentStep === 2 ? 'current' : currentStep > 2 ? 'complete' : 'upcoming'
    },
    { 
      id: 'Step 3',
      title: 'Detailed Profile',
      status: currentStep === 3 ? 'current' : currentStep > 3 ? 'complete' : 'upcoming'
    }
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      if (!token) {
        setError('Failed to fetch projects: No token found');
        return;
      }
      setLoading(true);
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: { filter: { owner: userId } }
        });
        setProjects(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(`Failed to fetch projects: ${error.response ? error.response.statusText : error.message}`);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userId, token]);

  useEffect(() => {
    if (selectedProjectId) {
      const fetchProjectDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${selectedProjectId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setProjectDetails(response.data.data);
          setLoading(false);
        } catch (error) {
          setError(`Failed to fetch project details: ${error.response ? error.response.statusText : error.message}`);
          setLoading(false);
        }
      };

      fetchProjectDetails();
    }
  }, [selectedProjectId, token]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects/create-project'); // Navigate to project creation page
    } else {
      setSelectedProjectId(value);
    }
    setFormData({ ...formData, project: value }); // Correctly capture project selection
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Input changed: ${name} = ${value}`);
    
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (fieldErrors[name]) {
      console.log(`Clearing error for ${name}`);
      setFieldErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    
    const isValid = validateStep3();
    console.log('Form validation result:', isValid);
    console.log('Current fieldErrors:', fieldErrors);

    if (!isValid) {
      console.log('Validation failed - stopping submission');
      return;
    }

    setLoading(true);
    try {
      await handleSubmit(formData);
      setSubmitStatus('success');
    } catch (err) {
      setError(err.message);
      setSubmitStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const validateStep1 = () => {
    const errors = {};
    if (!selectedProjectId) errors.project = 'This field is required';
    if (!formData.type) errors.type = 'This field is required';
    
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep2 = () => {
    const errors = {};
    const requiredFields = [
      { key: 'name', label: 'Name' },
      { key: 'gender', label: 'Gender' },
      { key: 'age', label: 'Age' },
      { key: 'education', label: 'Education' },
      { key: 'occupation', label: 'Occupation' },
      { key: 'country', label: 'Country' },
      { key: 'city', label: 'City' },
      { key: 'familycycle', label: 'Family Life Cycle' },
      /*{ key: 'familysize', label: 'Family Size' },*/
      /*{ key: 'income', label: 'Income' }*/
    ];
    
    requiredFields.forEach(({ key }) => {
      if (!formData[key] || formData[key].trim() === '') {
        errors[key] = 'This field is required';
      }
    });

    // Additional validation rules
    if (formData.age && (formData.age < 10 || formData.age > 120)) {
      errors.age = 'Age must be between 10 and 120';
    }

    if (formData.familysize && (formData.familysize < 1 || formData.familysize > 20)) {
      errors.familysize = 'Family size must be between 1 and 20';
    }

    if (formData.income && formData.income < 0) {
      errors.income = 'Income cannot be negative';
    }

    if (formData.education === 'Select Education') {
      errors.education = 'Please select an education level';
    }

    if (formData.familycycle === 'Select Family Life Cycle') {
      errors.familycycle = 'Please select a family life cycle';
    }
    
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep3 = () => {
    console.log('Running validateStep3');
    console.log('Current formData:', formData);
    
    const errors = {};
    
    // Check Biography
    if (formData.biography && formData.biography.trim() !== '' && formData.biography.trim().length < 20) {
      console.log('Biography is too short');
      errors.biography = 'Biography should be at least 20 characters long';
    }

    // Check Motivations
    if (formData.motivations && formData.motivations.trim() !== '' && formData.motivations.trim().length < 20) {
      console.log('Motivations is too short');
      errors.motivations = 'Motivations should be at least 20 characters long';
    }

    // Check Needs
    if (formData.needs && formData.needs.trim() !== '' && formData.needs.trim().length < 20) {
      console.log('Needs is too short');
      errors.needs = 'Needs should be at least 20 characters long';
    }

    // Check Frustrations
    if (formData.frustrations && formData.frustrations.trim() !== '' && formData.frustrations.trim().length < 20) {
      console.log('Frustrations is too short');
      errors.frustrations = 'Frustrations should be at least 20 characters long';
    }

    console.log('Validation errors:', errors);
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const nextStep = () => {
    let isValid = true;
    
    switch (currentStep) {
      case 1:
        isValid = validateStep1();
        break;
      case 2:
        isValid = validateStep2();
        break;
      case 3:
        isValid = validateStep3();
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      setCurrentStep(currentStep + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStepClick = (stepNumber) => {
    // Only allow moving to previous steps
    if (stepNumber < currentStep) {
      setCurrentStep(stepNumber);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="grid max-w-5xl mx-auto bg-white p-10 border border-neutral-200 rounded-lg">
            <div className="col-span-12">
              <div className="mb-8">
                <h3 className="text-3xl font-medium">Select Project and Persona Type</h3>
                <p className="opacity-60 text-base">Choose an existing project or create a new one, then specify the type of persona</p>
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Select Project</label>
                <select 
                  required 
                  name="project" 
                  id="project-select" 
                  onChange={handleProjectChange} 
                  value={selectedProjectId}
                  className={`w-full ${fieldErrors.project ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Project</option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>{project.project_name}</option>
                  ))}
                  <option value="new-project">Create New Project</option>
                </select>
                {fieldErrors.project && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.project}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Type</label>
                <select required name="type" id="type-select" onChange={handleInputChange}>
                  <option value="">Select Type</option>
                  <option value="personas">Personas</option>
                  <option value="protopersonas" disabled>Proto Personas (Coming Soon)</option>
                </select>
              </div>
              <button type="button" onClick={nextStep} className="bg-primary-500 hover:bg-primary-700 text-white py-4 px-8 rounded-full mt-4">
                Next Step
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="grid grid-cols-12 gap-6 bg-white mt-16 rounded-lg mb-6 mx-auto max-w-5xl">
            <div className="col-span-12">
              <div className="mb-8">
                <h3 className="text-3xl font-medium">Personal Information</h3>
                <p className="opacity-60 text-base">Fill in the basic demographic information</p>
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Name</label>
                <input 
                  required 
                  name="name" 
                  type="text" 
                  placeholder="Enter your name" 
                  onChange={handleInputChange}
                  value={formData.name || ''}
                  className={`w-full ${fieldErrors.name ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.name && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.name}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Gender</label>
                <select 
                  required 
                  name="gender" 
                  id="gender-select" 
                  onChange={handleInputChange}
                  value={formData.gender || ''}
                  className={`w-full ${fieldErrors.gender ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Non-binary">Non-binary</option>
                </select>
                {fieldErrors.gender && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.gender}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Age</label>
                <input 
                  required 
                  name="age" 
                  type="number" 
                  min="10" 
                  max="120" 
                  placeholder="What's the age" 
                  onChange={handleInputChange}
                  value={formData.age || ''}
                  className={`w-full ${fieldErrors.age ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.age && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.age}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Education:</label>
                <select 
                  required 
                  name="education" 
                  id="education-select" 
                  onChange={handleInputChange}
                  value={formData.education || ''}
                  className={`w-full ${fieldErrors.education ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Education</option>
                  <option value="Early childhood education">Early childhood education</option>
                  <option value="Primary education">Primary education</option>
                  <option value="Upper secondary education">Upper secondary education</option>
                  <option value="Bachelor's or equivalent level">Bachelor's or equivalent level</option>
                  <option value="Master's or equivalent level">Master's or equivalent level</option>
                  <option value="Doctoral or equivalent level">Doctoral or equivalent level</option>
                  <option value="Professor">Professor</option>
                </select>
                {fieldErrors.education && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.education}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Occupation</label>
                <input 
                  required 
                  name="occupation" 
                  type="text" 
                  placeholder="What is their occupation?" 
                  onChange={handleInputChange}
                  value={formData.occupation || ''}
                  className={`w-full ${fieldErrors.occupation ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.occupation && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.occupation}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Country</label>
                <input 
                  required 
                  name="country" 
                  type="text" 
                  placeholder="What country do they live in?" 
                  onChange={handleInputChange}
                  value={formData.country || ''}
                  className={`w-full ${fieldErrors.country ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.country && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.country}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">City</label>
                <input 
                  required 
                  name="city" 
                  type="text" 
                  placeholder="What city do they live in?" 
                  onChange={handleInputChange}
                  value={formData.city || ''}
                  className={`w-full ${fieldErrors.city ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.city && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.city}</p>
                )}
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Family life cycle</label>
                <select 
                  required 
                  name="familycycle" 
                  id="familycycle-select" 
                  onChange={handleInputChange}
                  value={formData.familycycle || ''}
                  className={`w-full ${fieldErrors.familycycle ? 'border-red-500' : 'border-gray-300'}`}
                >
                  <option value="">Select Family Life Cycle</option>
                  <option value="Young, Single">Young, single</option>
                  <option value="Young, married, no children">Young, married, no children</option>
                  <option value="Young, married, the youngest child under 6">Young, married, the youngest child under 6</option>
                  <option value="Young married, youngest child 6 or over">Young married, youngest child 6 or over</option>
                  <option value="Older, married, no children">Older, married, no children</option>
                  <option value="Older, married, children above 18">Older, married, children above 18</option>
                  <option value="Older, single">Older, single</option>
                  <option value="Other">Other</option>
                </select>
                {fieldErrors.familycycle && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.familycycle}</p>
                )}
              </div>
              {/*<div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Family Size</label>
                <input 
                  required 
                  name="familysize" 
                  type="number" 
                  min="1" 
                  max="20" 
                  placeholder="Number of people in the household" 
                  onChange={handleInputChange}
                  value={formData.familysize || ''}
                  className={`w-full ${fieldErrors.familysize ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.familysize && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.familysize}</p>
                )}
              </div> */}
              {/* <div className="mb-3">
                <label className="block mb-2 text-sm font-medium text-slate-700">Annual Income</label>
                <input 
                  required 
                  name="income" 
                  type="number" 
                  min="0" 
                  placeholder="Enter annual income amount" 
                  onChange={handleInputChange}
                  value={formData.income || ''}
                  className={`w-full ${fieldErrors.income ? 'border-red-500' : 'border-gray-300'}`}
                />
                {fieldErrors.income && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.income}</p>
                )}
                <p className="mt-0 p-0 text-sm text-gray-500">
                  Enter the amount in your local currency. This helps create more accurate persona profiles while maintaining flexibility across different regions.
                </p>
              </div>*/}
              <div className="flex justify-between mt-4">
                <button type="button" onClick={prevStep} className="bg-gray-500 hover:bg-gray-700 text-white py-4 px-8 rounded-full">
                  Previous Step
                </button>
                <button type="button" onClick={nextStep} className="bg-primary-500 hover:bg-primary-700 text-white py-4 px-8 rounded-full">
                  Next Step
                </button>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="grid grid-cols-12 gap-6 bg-white mt-16 rounded-lg mx-auto max-w-5xl">
            <div className="col-span-12">
              <div className="mb-8">
                <h3 className="text-3xl font-medium">Detailed Profile (optional)</h3>
                <p className="opacity-60 text-base">While this step is optional, we strongly recommend completing it for best results. Without this information, your persona will be less detailed and primarily based on the general data from previous steps, which may not accurately reflect real user characteristics. Our AI uses these details to create much richer, more accurate persona profiles.</p>
              </div>
              
              <div className="mb-3">
                <div className="flex items-center mb-4">
                  <h2 className="text-xl font-semibold">Personas biography</h2>
                  <button 
                    type="button" 
                    onClick={() => setActiveInfoDrawer('biography')}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    aria-label="Information about profile"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                  </button>
                </div>
                <textarea 
                  name="biography" 
                  rows="6" 
                  onChange={handleInputChange}
                  value={formData.biography || ''}
                  className={`w-full ${fieldErrors.biography ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="Provide details about this person's daily life and habits, including wake-up time, music/podcast preferences, what car they drive, favorite coffee places, morning routines, etc."
                ></textarea>
                {fieldErrors.biography && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.biography}</p>
                )}
              </div>

              <div className="mb-3">
                <div className="flex items-center mb-4">
                  <h2 className="text-xl font-semibold">Motivations</h2>
                  <button 
                    type="button" 
                    onClick={() => setActiveInfoDrawer('motivations')}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    aria-label="Information about motivations"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                  </button>
                </div>
                <textarea 
                  name="motivations" 
                  rows="6" 
                  onChange={handleInputChange}
                  value={formData.motivations || ''}
                  className={`w-full ${fieldErrors.motivations ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="What drives this person? What are their goals and aspirations?"
                ></textarea>
                {fieldErrors.motivations && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.motivations}</p>
                )}
              </div>

              <div className="mb-3">
                <div className="flex items-center mb-4">
                  <h2 className="text-xl font-semibold">Needs</h2>
                  <button 
                    type="button" 
                    onClick={() => setActiveInfoDrawer('needs')}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    aria-label="Information about needs"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                  </button>
                </div>
                <textarea 
                  name="needs" 
                  rows="6" 
                  onChange={handleInputChange}
                  value={formData.needs || ''}
                  className={`w-full ${fieldErrors.needs ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="What are their primary needs and requirements?"
                ></textarea>
                {fieldErrors.needs && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.needs}</p>
                )}
              </div>

              <div className="mb-3">
                <div className="flex items-center mb-4">
                  <h2 className="text-xl font-semibold">Frustrations</h2>
                  <button 
                    type="button" 
                    onClick={() => setActiveInfoDrawer('frustrations')}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                    aria-label="Information about frustrations"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" />
                  </button>
                </div>
                <textarea 
                  name="frustrations" 
                  rows="6" 
                  onChange={handleInputChange}
                  value={formData.frustrations || ''}
                  className={`w-full ${fieldErrors.frustrations ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="What challenges or pain points do they face?"
                ></textarea>
                {fieldErrors.frustrations && (
                  <p className="mt-1 text-sm text-red-500">{fieldErrors.frustrations}</p>
                )}
              </div>

              <div className="flex justify-between mt-4">
                <button 
                  type="button" 
                  onClick={prevStep} 
                  className="bg-gray-500 hover:bg-gray-700 text-white py-4 px-8 rounded-full"
                >
                  Previous Step
                </button>
                <button 
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    const isValid = validateStep3();
                    if (isValid) {
                      submitForm(e);
                    }
                  }} 
                  className="bg-primary-500 hover:bg-primary-700 text-white py-4 px-8 rounded-full"
                >
                  Create Personas
                </button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const InfoDrawer = ({ isOpen, onClose, title, content }) => {
    if (!isOpen) return null;
    
    return (
      <div className="fixed inset-0 z-50 overflow-hidden">
        <div className="absolute inset-0 bg-black/30 backdrop-blur-sm" onClick={onClose} />
        <div className="fixed right-0 top-0 bottom-0 w-full max-w-md bg-white shadow-xl flex flex-col h-full">
          <div className="flex items-center justify-between border-b border-gray-200 p-4">
            <h2 className="text-xl font-semibold">{title}</h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="flex-1 overflow-y-auto p-6">
            {content}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="lg:pl-24 pb-32">
      <div className="sm:flex sm:items-center mb-16">
        <div className="sm:flex-auto mt-8 hidden">
          <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
          <p className="mt-6 text-base text-gray-600">{description}</p>
        </div>
      </div>

      {(isLoading || submitStatus) && (
        <div className="loader-container">
          <Loader isLoading={isLoading} submitStatus={submitStatus} />
        </div>
      )}

      {error && !isLoading && <div className="error-message">{error}</div>}
      
      <div className="max-w-5xl mx-auto mb-8">
        <Stepper steps={steps} onStepClick={handleStepClick} />
      </div>

      <form onSubmit={submitForm}>
        {renderStep()}
      </form>

      <InfoDrawer 
        isOpen={activeInfoDrawer !== null}
        onClose={() => setActiveInfoDrawer(null)}
        title={activeInfoDrawer ? drawerContent[activeInfoDrawer].title : ''}
        content={activeInfoDrawer ? drawerContent[activeInfoDrawer].content : null}
      />
    </div>
  );
};

export default PersonasForm;