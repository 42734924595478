import React, { useState, useEffect } from 'react';

const thinkingLabels = [
  "Myślę",
  "Analizuję dane",
  "Sprawdzam informacje",
  "Przetwarzam zapytanie",
  "Szukam rozwiązania",
  "Weryfikuję źródła",
  "Generuję odpowiedź",
  "Optymalizuję wynik",
  "Przeglądam dokumentację",
  "Łączę fakty",
  "Sprawdzam kontekst",
  "Oceniam możliwości",
  "Kalkuluję opcje",
  "Badam zagadnienie",
  "Analizuję przypadek",
  "Przetwarzam dane",
  "Szukam wzorców",
  "Weryfikuję hipotezę",
  "Sprawdzam spójność",
  "Finalizuję odpowiedź"
];

const Waiter = () => {
  const [currentLabel, setCurrentLabel] = useState(thinkingLabels[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLabel(prevLabel => {
        const currentIndex = thinkingLabels.indexOf(prevLabel);
        const nextIndex = (currentIndex + 1) % thinkingLabels.length;
        return thinkingLabels[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center gap-2 py-2 px-4">
      <span className="text-gray-500">{currentLabel}</span>
      <div className="flex gap-1">
        <span className="w-1 h-1 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></span>
        <span className="w-1 h-1 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '200ms' }}></span>
        <span className="w-1 h-1 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '400ms' }}></span>
      </div>
    </div>
  );
};

export default Waiter; 